/**
 *
 *
 *
 */

import React, { useState } from 'react';
import { Modal2 } from 'Components';
import {
  Divider,
  Typography,
  List,
  Col,
  Row,
  Tabs,
  Select,
  Tag,
  Button,
} from 'antd';
import moment from 'moment';
import * as XLSX from 'xlsx/xlsx.mjs';

// 휴가 상태
const VACATION_STATUS = {
  PENDING: {
    ko: '대기',
    color: 'grey',
    type: 'info',
  },
  ACCEPT: {
    ko: '승인',
    color: 'green',
    type: 'success',
  },
  REJECT: {
    ko: '반려',
    color: 'red',
    type: 'warning',
  },
  CANCEL: {
    ko: '취소',
    color: 'grey',
    type: 'warning',
  },
};
const vacationStatusKeys = Object.keys(VACATION_STATUS);
const { Title } = Typography;
const { Option } = Select;

/**
 * [Component] AtdRecordDetail
 * --
 */
const AtdRecordDetail = ({
  currentDate,
  visible,
  onCancel,
  onOk,
  records,
  vacations,
  // setVacations,
  onChangeVacationStatus,
}) => {
  /* ===== State ===== */
  // const [vacationStatus, setVacationStatus] = useState(null);
  const [sortValue, setSortValue] = useState('name');

  /* ===== Variables ===== */
  const totalCount = records.length;
  const completeCount = records.filter((r) => r.attendance_end).length;
  const inprocessCount = totalCount - completeCount;
  const errorCount = records.filter(
    (r) => r.attendance_status === 'UNPROCESSED'
  ).length;

  /**
   * Tab1
   * --
   */
  const Tab1 = (
    <>
      <Row style={{ textAlign: 'center' }}>
        <Col span={6}>
          <Title level={4}>
            <small style={{ fontWeight: '300' }}>총</small>
            <br />
            {totalCount}건
          </Title>
        </Col>

        <Col span={6}>
          <Title level={4}>
            <small style={{ fontWeight: '300' }}>정상</small>
            <br />
            {completeCount}건
          </Title>
        </Col>

        <Col span={6}>
          <Title level={4}>
            <small style={{ fontWeight: '300' }}>진행중</small>
            <br />
            {inprocessCount}건
          </Title>
        </Col>

        <Col span={6}>
          <Title level={4}>
            <small style={{ fontWeight: '300' }}>이상</small>
            <br />
            {errorCount}건
          </Title>
        </Col>
      </Row>
      <Divider style={{ margin: '5px 0' }} />
      {records.length > 0 && (
        <List
          itemLayout='horizontal'
          dataSource={records}
          renderItem={(item) => {
            const startStr = moment(item.created_at).format('HH:mm');
            const endStr = item.attendance_end
              ? moment(item.attendance_end).format('HH:mm')
              : '[--:--]';
            return (
              <List.Item>
                <List.Item.Meta
                  title={
                    <a href='#'>
                      {item.user_nm} <span style={{ fontWeight: '300' }}></span>
                    </a>
                  }
                  description={`위치:${item.place_nm}`}
                />
                <div>
                  {startStr}~{endStr}
                </div>
              </List.Item>
            );
          }}
        />
      )}
    </>
  );
  /**
   * Tab2
   * --
   */
  const Tab2 = (
    <>
      {vacations.length > 0 && (
        <List
          itemLayout='horizontal'
          dataSource={vacations}
          renderItem={(item) => {
            const createdAt = moment(item.created_at).format(
              'YYYY.MM.DD HH:mm'
            );
            const type =
              item.vacation_type === 'HALF'
                ? item.vacation_start_time === '09:00'
                  ? '오전반차'
                  : '오후반차'
                : '연차';

            return (
              <List.Item>
                <List.Item.Meta
                  title={
                    <a href='#'>
                      {item.user_nm} <span style={{ fontWeight: '300' }}></span>
                    </a>
                  }
                  description={
                    <>
                      상태:{' '}
                      <Tag color={VACATION_STATUS[item.vacation_status].color}>
                        {VACATION_STATUS[item.vacation_status].ko}
                      </Tag>
                      <br />
                      유형: {type}
                      <br />
                      기안일: {createdAt}
                      <br />
                      사유: {item.vacation_content}
                    </>
                  }
                />
                <div>
                  <Select
                    value={VACATION_STATUS[item.vacation_status].ko}
                    style={{
                      width: 85,
                    }}
                    // size="large"
                    onChange={(value) =>
                      onChangeVacationStatus(item.vacation_id, value)
                    }
                  >
                    {vacationStatusKeys.map((key) => (
                      <Option value={key}>{VACATION_STATUS[key].ko}</Option>
                    ))}
                  </Select>
                </div>
              </List.Item>
            );
          }}
        />
      )}
    </>
  );

  /* ===== Functions ===== */
  /**
   * 엑셀 다운로드
   * --
   */
  const handleDownloadXlsx = () => {
    const sortF =
      sortValue === 'place'
        ? (a, b) =>
            a.place_nm < b.place_nm ? -1 : a.place_nm == b.place_nm ? 0 : 1
        : sortValue === 'time'
        ? (a, b) =>
            a.created_at < b.created_at
              ? -1
              : a.created_at == b.created_at
              ? 0
              : 1
        : (a, b) =>
            a.user_nm < b.user_nm ? -1 : a.user_nm == b.user_nm ? 0 : 1;

    const xlsxData = records.sort(sortF).map((item, idx) => ({
      ['번호']: idx + 1,
      ['지도자']: item.user_nm,
      ['근무지']: `${item.place_nm}`,
      ['지역']: `-`,
      ['날짜']: moment(item.created_at).format('YYYY-MM-DD'),
      ['시작시간']: moment(item.created_at).format('HH:mm:ss'),
      ['종료시간']: item.attendance_end
        ? moment(item.attendance_end).format('HH:mm:ss')
        : '-',
      ['구분']: item.attendance_status === 'GO_TO_WORK' ? '출근' : '퇴근',
    }));

    const ws = XLSX.utils.json_to_sheet(xlsxData);
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(
      wb,
      `${moment().format('YYYY년MM월DD일')}_근태기록_${moment().format(
        'YYYYMMDDHHmmss'
      )}.xlsx`
    );
  };

  /* ===== RENDER ===== */
  return (
    <>
      <Modal2
        visible={visible}
        type='drawer'
        // closable
        width={420}
        onCancel={onCancel}
        onOk={onOk}
        footer={
          <Row>
            <Col span={7}>
              <Select
                defaultValue={sortValue}
                onChange={setSortValue}
                style={{
                  width: '95%',
                }}
              >
                <Option value={'name'}>이름순</Option>
                <Option value={'place'}>근무지순</Option>
                <Option value={'time'}>시간순</Option>
              </Select>
            </Col>
            <Col span={17}>
              <small>
                <Button
                  type='primary'
                  // size="small"
                  style={{ width: '100%' }}
                  onClick={handleDownloadXlsx}
                >
                  엑셀다운로드
                </Button>
              </small>
            </Col>
          </Row>
        }
      >
        <Title level={4}>[{currentDate}] 상세 근무기록</Title>
        {/* <Divider style={{ margin: '5px 0' }} /> */}

        <Tabs defaultActiveKey='1'>
          <Tabs.TabPane tab='근태이력' key='1'>
            {Tab1}
          </Tabs.TabPane>
          <Tabs.TabPane tab='휴가목록' key='2'>
            {Tab2}
          </Tabs.TabPane>
        </Tabs>
      </Modal2>
    </>
  );
};

AtdRecordDetail.defaultProps = {
  currentDate: '--:--',
  visible: false,
  onCancel: () => {},
  onOk: () => {},
  records: [],
  vacations: [],
};
export default AtdRecordDetail;
