import { NoticeApi } from 'Api';
import React, { useCallback, useEffect, useState } from 'react';
import NoticePresenter from './NoticePresenter';

const NoticeContainer = () => {
  /* Router */
  /* State */
  const [communityList, setCommunityList] = useState([]);
  /* Functions */
  const handleCommunityList = useCallback(async () => {
    const result = await NoticeApi.getNotice();
    if (result) {
      setCommunityList(result);
    }
  }, []);

  const handleRegistNoitce = async (notice) => {
    const result = await NoticeApi.registNotice(notice);
    if (result) {
      handleCommunityList();
      return true;
    }
    return false;
  };

  const handleUpdateNotice = async (notice) => {
    const result = await NoticeApi.updateNotice(notice);
    console.log(result);
    if (result) {
      handleCommunityList();
      return true;
    }
    return false;
  };

  const handleDeleteNotice = async (notice_id) => {
    const result = await NoticeApi.deleteNotice(notice_id);
    if (result) {
      handleCommunityList();
      return true;
    }
    return false;
  };

  /* Hooks */
  useEffect(() => {
    handleCommunityList();
  }, [handleCommunityList]);

  /* Render */
  return (
    <NoticePresenter
      communityList={communityList}
      registNotice={handleRegistNoitce}
      updateNotice={handleUpdateNotice}
      deleteNotice={handleDeleteNotice}
    />
  );
};

export default NoticeContainer;
