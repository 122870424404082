import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarCheck,
  faClipboardUser,
  faPlaneDeparture,
} from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import './type-select.css';

const TypeSelect = ({ handleType, close }) => {
  /* Router */
  /* State */
  const [type, setType] = useState();
  /* Functions */
  const handleSetType = (val) => {
    // handleType(val);
    setType(val);
  };

  const next = () => {
    handleType(type);
  };

  /* Hooks */
  /* Render */
  return (
    <div className="select-box">
      <div className="box-wrapper">
        <div
          className={`box ${type === 'ATTENDANCE' && 'active'}`}
          onClick={() => handleSetType('ATTENDANCE')}
        >
          <FontAwesomeIcon icon={faClipboardUser} className="box-icon" />
          <h3 className="box-text">근태 등록</h3>
        </div>
        <div
          className={`box ${type === 'VACATION' && 'active'}`}
          onClick={() => handleSetType('VACATION')}
        >
          <FontAwesomeIcon icon={faPlaneDeparture} className="box-icon" />
          <h3 className="box-text">연차 등록</h3>
        </div>
        <div
          className={`box ${type === 'SCHEDULE' && 'active'}`}
          onClick={() => handleSetType('SCHEDULE')}
        >
          <FontAwesomeIcon icon={faCalendarCheck} className="box-icon" />
          <h3 className="box-text">일정 등록</h3>
        </div>
      </div>
      <div className="schedule-regist-button">
        <button className="cancel-btn" onClick={close}>
          닫기
        </button>
        <button
          className={type ? 'regist-btn' : 'cancel-btn'}
          disabled={!type}
          onClick={next}
        >
          다음
        </button>
      </div>
    </div>
  );
};

export default TypeSelect;
