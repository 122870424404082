import ApiConstant from 'Api/ApiConstant';
import { ApiManager, parameterToPath } from 'Utils';

const $http = new ApiManager();

const ScheduleApi = {
  /**
   * 연차 정보 등록
   * --
   * @param {Object} createVacationInfo 연차 정보
   * @returns
   */
  create: async (newData) => {
    try {
      const url = ApiConstant.CREATE_SCHEDULE;
      const { status, data, message } = await $http.post(url, newData);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },

  /**
   * 개별일정 조회
   * --
   * @returns
   */
  getSchedules: async (user_id) => {
    try {
      const url = parameterToPath(ApiConstant.GET_SCHEDULES, { user_id });
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
};
export default ScheduleApi;
