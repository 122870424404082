/**
 *
 *
 */

import moment from 'moment';
import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
import { TypeManager } from 'Utils';
import { Button, DatePicker, Col, Row, Typography, Select } from 'antd';
import { Content } from 'Components';
import { useNavigate } from 'react-router-dom';

const ATTENDANCE_TYPE = TypeManager.getAttendanceList();
const { Title } = Typography;
const { Option } = Select;

/**
 * [Component]
 * --
 */
const AttendanceModify = ({
  // userInfo,
  // displayDate,
  placeList,
  closeModal,
  createAttendance,
  attendance,
  updateAttendance,
}) => {
  /* ===== Router ===== */
  const navigate = useNavigate();
  /* ===== State ===== */
  // const { date } = useSelector((state) => state.common);
  const [attendanceInfo, setAttendanceInfo] = useState(attendance);

  /* ===== Functions ===== */
  /**
   *
   * --
   */
  const handleAttendanceInfo = (e) => {
    setAttendanceInfo({ ...attendanceInfo, [e.target.name]: e.target.value });
  };

  /**
   *
   * --
   */
  const handleAttendanceType = (e) => {
    // console.log('e: ', e);
    if (e.target.value === 0) {
      return;
    }
    handleAttendanceInfo(e);
  };

  /**
   * 근태정보 수정
   * --
   */
  const handleUpdateAttendance = async () => {
    const result = await updateAttendance(attendanceInfo);
    if (result) {
      closeModal();
      navigate(0);
      return result;
    }

    return false;
  };

  /**
   *
   * --
   */
  const handleAttendancePlace = (e) => {
    if (e.target.value === 0) {
      return;
    }
    handleAttendanceInfo(e);
  };

  /**
   *
   * --
   */
  // const handleAttendanceDate = (e) => {
  //   setAttendanceInfo({ ...attendanceInfo, [e.target.name]: e.target.value });
  // };

  /**
   *
   * --
   */
  // const handleAttendanceStartDate = (e) => {
  //   const { attendanceDetail } = attendanceInfo;
  //   const temp = attendanceDetail.filter(
  //     (item) => item.attendance_type === 'GO_TO_WORK'
  //   );
  //   if (temp.length === 0) {
  //     const newItem = {
  //       attendance_id: attendanceInfo.attendance_id,
  //       attendance_date: e.target.value,
  //       attendance_type: 'GO_TO_WORK',
  //     };
  //     setAttendanceInfo({
  //       ...attendanceInfo,
  //       attendanceDetail: [...attendanceInfo.attendanceDetail, newItem],
  //       [e.target.name]: e.target.value,
  //     });
  //     return;
  //   }
  //   handleAttendanceDate(e);
  // };

  /**
   *
   * --
   */
  // const handleAttendanceEndDate = (e) => {
  //   const { attendanceDetail } = attendanceInfo;
  //   const temp = attendanceDetail.filter(
  //     (item) => item.attendance_type === 'LEAVE_TO_WORK'
  //   );

  //   // alert(e.target.value);

  //   if (temp.length === 0) {
  //     const newItem = {
  //       attendance_id: attendanceInfo.attendance_id,
  //       attendance_date: e.target.value,
  //       attendance_type: 'LEAVE_TO_WORK',
  //     };

  //     setAttendanceInfo({
  //       ...attendanceInfo,
  //       attendanceDetail: [...attendanceInfo.attendanceDetail, newItem],
  //       [e.target.name]: e.target.value,
  //       attendance_status: 'LEAVE_TO_WORK',
  //     });
  //     return;
  //   } else {
  //     setAttendanceInfo({
  //       ...attendanceInfo,
  //       [e.target.name]: e.target.value,
  //     });
  //   }
  //   handleAttendanceDate(e);
  // };

  /* ===== Hooks ===== */
  /*  */
  const attendanceRender = ATTENDANCE_TYPE.map((item) => {
    const { id, title, value } = item;
    return (
      <Option value={value} key={id}>
        {title}
      </Option>
    );
  });

  /*  */
  const placeRender = placeList.map((item) => {
    const { place_id, place_nm } = item;
    return (
      <Option value={place_id} key={place_id}>
        {place_nm}
      </Option>
    );
  });

  /* ===== Render ===== */
  return (
    <div>
      {/* === NEW === */}
      <Content>
        <Row>
          {/* == 근무지명 == */}
          <Col span={4}>
            <Title level={5} style={{ padding: '3.5px 0' }}>
              근무지명
            </Title>
          </Col>
          <Col span={20}>
            <Select
              placeholder='클릭하여 근무지를 선택해주세요'
              style={{ width: '100%' }}
              value={attendanceInfo.place_id}
            >
              {placeRender}
            </Select>
          </Col>
          {/* == 근무지명 == */}

          {/* == 근태구분 == */}
          <Col span={4}>
            <Title level={5} style={{ padding: '3.5px 0' }}>
              근태구분
            </Title>
          </Col>
          <Col span={20}>
            <Select
              name='attendance_status'
              value={attendanceInfo.attendance_status}
              placeholder='구분을 선택해주세요'
              onChange={(value) =>
                handleAttendanceType({
                  target: { name: 'attendance_status', value },
                })
              }
              style={{ width: '100%' }}
            >
              {attendanceRender}
            </Select>
          </Col>
          {/* == 근태구분 == */}

          {/* == 시작시간 == */}
          <Col span={4}>
            <Title level={5} style={{ padding: '3.5px 0' }}>
              시작시간
            </Title>
          </Col>
          <Col span={20}>
            <DatePicker
              showTime
              placeholder='클릭하여 시간을 선택해주세요'
              format='YYYY-MM-DD HH:mm'
              defaultValue={moment(attendanceInfo.attendance_start)}
              style={{ width: '100%' }}
              onChange={(value) =>
                handleAttendancePlace({
                  target: {
                    name: 'attendance_start',
                    value: moment(value).format('YYYY-MM-DDTHH:mm'),
                  },
                })
              }
            />
          </Col>
          {/* == 시작시간 == */}

          {/* == 종료시간 == */}
          <Col span={4}>
            <Title level={5} style={{ padding: '3.5px 0' }}>
              종료시간
            </Title>
          </Col>
          <Col span={20}>
            <DatePicker
              showTime
              placeholder='클릭하여 시간을 선택해주세요'
              format='YYYY-MM-DD HH:mm'
              defaultValue={
                attendanceInfo.attendance_end
                  ? moment(attendanceInfo.attendance_end)
                  : null
              }
              onChange={(value) =>
                handleAttendancePlace({
                  target: {
                    name: 'attendance_end',
                    value: moment(value).format('YYYY-MM-DDTHH:mm'),
                  },
                })
              }
              style={{ width: '100%' }}
            />
          </Col>
          {/* == 종료시간 == */}
        </Row>

        <div
          style={{
            marginTop: 15,
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button onClick={closeModal}>닫기</Button>&nbsp;
          <Button onClick={handleUpdateAttendance} type='primary'>
            수정
          </Button>
        </div>
      </Content>

      {/* === OLD === */}
      {/* <>
        <div className="schedule-regist-contents-inner">
          <div className="schedule-regist-info">
            <div>
              <p>근무지 명</p>
              <select
                name="place_id"
                onChange={handleAttendancePlace}
                value={attendanceInfo.place_id}
              >
                <option value="0">근무지 선택</option>
                {placeRender}
              </select>
            </div>
            <div>
              <p>근태 구분</p>
              <select
                name="attendance_status"
                onChange={handleAttendanceType}
                value={attendanceInfo.attendance_status}
              >
                <option value="0">근태 구분 선택</option>
                {attendanceRender}
              </select>
            </div>
            <div>
              <p>시작시간</p>

              <input
                type="datetime-local"
                name="attendance_start"
                placeholder="내용을 입력해주세요."
                value={moment(attendanceInfo.attendance_start).format(
                  'YYYY-MM-DDTHH:mm'
                )}
                onChange={handleAttendanceStartDate}
              />
            </div>
            <div>
              <p>종료시간</p>
              <input
                type="datetime-local"
                name="attendance_end"
                placeholder="내용을 입력해주세요."
                value={
                  attendanceInfo.attendance_end
                    ? moment(attendanceInfo.attendance_end).format(
                        'YYYY-MM-DDTHH:mm'
                      )
                    : moment(attendanceInfo.attendance_start)
                        .add(moment.duration(1, 'hours'))
                        .format('YYYY-MM-DDTHH:mm')
                }
                onChange={handleAttendanceEndDate}
              />
            </div>
            <div className="place-calendar-detail-contents-inner">
              <div className="place-calendar-detail-button">
                <button className="close-btn" onClick={closeModal}>
                  닫기
                </button>
                <button onClick={handleUpdateAttendance}>수정</button>
              </div>
            </div>{' '}
          </div>
        </div>
      </> */}
    </div>
  );
};

export default AttendanceModify;
