import { Alert } from 'Components';
import React from 'react';
import AppRouter from '../Route';
import './css/index.css';
const AppPresenter = () => {
  return (
    <>
      <Alert />
      <AppRouter />
    </>
  );
};

export default AppPresenter;
