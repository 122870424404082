/**
 *
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { DashboardApi, VacationApi } from 'Api';
import moment from 'moment';
import MessageAlert from 'Utils/MessageAlert';
import AtdRecordDetail from './components/AtdRecordDetail';

// 휴가 상태
const VACATION_STATUS = {
  PENDING: {
    ko: '대기',
    color: 'grey',
    type: 'info',
  },
  ACCEPT: {
    ko: '승인',
    color: 'green',
    type: 'success',
  },
  REJECT: {
    ko: '반려',
    color: 'red',
    type: 'warning',
  },
  CANCEL: {
    ko: '취소',
    color: 'grey',
    type: 'warning',
  },
};
const COLOR_CODE = {
  START_MISS: '#4a59ff',
  END_MISS: '#4a59ff',
  COMPLETE: '#42c28d',
  WAIT: '#7592c7',
};

/**
 * [Component] AtdFullCalendar
 * --
 */
const AtdFullCalendar = ({ setStatics }) => {
  /* ===== STATE ===== */
  /*  
  [
    { title: 'event 1', date: '2022-06-21' },
    { title: 'event 2', date: '2022-06-22' },
  ]
  */
  const [visible, setVisible] = useState(false);
  const [record, setRecord] = useState([]);
  const [vacations, setVacations] = useState([]);
  const [detailRecord, setDetailRecord] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  /* ===== FUNCTIONS ===== */
  const call = useCallback(
    async (startDate = null, endDate = null) => {
      try {
        let result, vacationResult;
        /* 캘린더 이동 시 */
        if (startDate && endDate) {
          result = await DashboardApi.getDashboardCalendar(startDate, endDate);
          vacationResult = await DashboardApi.getDashboardVacations(
            startDate,
            endDate
          );
        } else {
          result = await DashboardApi.getDashboardCalendar();
          vacationResult = await DashboardApi.getDashboardVacations();
        }

        let total = 0;
        let complete = 0;
        let inprogress = 0;
        let err = 0;

        let ar = [];
        for (let item of result.calendar) {
          total = total + Number(item.total_count);
          complete = complete + Number(item.complete_count);
          inprogress =
            inprogress +
            (Number(item.total_count) - Number(item.complete_count));
          err = err + Number(item.error_count);

          const cra = moment(item.created_at).format('YYYY-MM-DD');
          const inprogressCount = item.total_count - item.complete_count;

          // 근태이력 총합 생성
          ar.push(
            ...[
              {
                id: `${cra}`,
                title: `1. 총 ${item.total_count}건`,
                date: cra,
                currentDate: cra,
                color: '#333',
              },

              item.complete_count > 0
                ? {
                    id: `${cra}`,
                    title: `2.정상 ${item.complete_count}건`,
                    date: cra,
                    currentDate: cra,
                    color: COLOR_CODE['COMPLETE'],
                  }
                : {},

              inprogressCount > 0
                ? {
                    id: `${cra}`,
                    title: `3. 진행중 ${inprogressCount}건`,
                    date: cra,
                    currentDate: cra,
                    color: COLOR_CODE['START_MISS'],
                  }
                : {},

              item.error_count > 0
                ? {
                    id: `${cra}`,
                    title: `4. 이상 ${item.error_count}건`,
                    date: cra,
                    currentDate: cra,
                    color: 'orange',
                  }
                : {},
            ]
          );
        }
        // 휴가목록 생성
        for (let item of vacationResult) {
          const cra = moment(item.created_at).format('YYYY-MM-DD');
          ar.push({
            id: `${cra}`,
            title: `5. 휴가 총${item.total_count}건${
              item.wait_count > 0 ? `(미승인 ${item.wait_count}건)` : ''
            }`,
            date: cra,
            currentDate: cra,
            color: 'orange',
          });
        }

        /* Set state */
        setRecord(ar);
        setStatics({
          total,
          complete,
          inprogress,
          err,
        });
      } catch (e) {
        MessageAlert.error('페이지를 새로고침 해주세요');
      }
    },
    [setStatics]
  );

  /**
   * 날짜 선택 시 호출 함수
   * --
   * @param {*} info
   */
  const handleChangeDate = async (info) => {
    info.jsEvent.preventDefault();
    try {
      const currentDate = moment(info.event.id).format('YYYY-MM-DD');
      const result = await DashboardApi.getDashboardCalendarDetail(currentDate);
      const vacationsResult = await DashboardApi.getDashboardVacationsDetail(
        currentDate
      );
      if (!result || !vacationsResult) {
        return MessageAlert.error('페이지를 새로고침 해주세요');
      }
      setSelectedDate(currentDate);
      setDetailRecord(result);
      setVacations(vacationsResult);
    } catch (e) {
      MessageAlert.error(e.message);
    }
    setVisible(true);
  };

  /**
   * 월 변경 함수
   * --
   */
  const handleChangeMonth = async (e) => {
    try {
      const { startStr, endStr } = e;
      const startDate = moment(startStr).format('YYYY-MM-DD');
      const endDate = moment(endStr).format('YYYY-MM-DD');
      await call(startDate, endDate);
    } catch (err) {
      MessageAlert.error(err.message);
    }
  };

  /**
   * 상태변경 함수
   * --
   */
  const handleChangeVacationStatus = async (vacationId, value) => {
    try {
      const fid = vacations.findIndex((v) => v.vacation_id === vacationId);
      const newVacation = {
        vacation_id: vacationId,
        vacation_status: value,
      };
      await VacationApi.updateVacation(newVacation);
      setVacations([
        ...vacations.slice(0, fid),
        {
          ...vacations[fid],
          vacation_status: value,
        },
        ...vacations.slice(fid + 1),
      ]);

      MessageAlert[VACATION_STATUS[value].type](
        `${vacations[fid].user_nm}님의 휴가가 ${VACATION_STATUS[value].ko}처리 되었습니다`
      );
    } catch (error) {
      MessageAlert.error('페이지를 새로고침 해주세요');
    }
  };

  /* ===== HOOKS ===== */
  useEffect(() => {
    const startDate = moment().subtract(31, 'days').format('YYYY-MM-DD');
    const endDate = moment().add(31, 'days').format('YYYY-MM-DD');
    call(startDate, endDate);
  }, []);

  /* ===== RENDER ===== */
  return (
    <>
      <div className='mypage-body'>
        <div className='body-wrapper box'>
          <div className='body-info-container'>
            <div className='calendar-wrapper'>
              <FullCalendar
                titleFormat={{
                  year: 'numeric',
                  month: 'numeric',
                  // day: 'numeric',
                }}
                defaultView='dayGridMonth'
                plugins={[dayGridPlugin]}
                selectable
                events={record}
                // dateClick={console.log}
                datesSet={handleChangeMonth}
                eventClick={handleChangeDate}
              />
            </div>
          </div>
        </div>
      </div>

      <AtdRecordDetail
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        records={detailRecord}
        vacations={vacations}
        setVacations={setVacations}
        onChangeVacationStatus={handleChangeVacationStatus}
        currentDate={selectedDate}
      />
    </>
  );
};

export default AtdFullCalendar;
