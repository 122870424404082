// 로그인 페이지
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UsersSlice from 'Store/reducers/UsersSlice';
// import LOGO from 'Assets/img/busad_logo_white.svg';
import LOGO from 'Assets/img/new_busad_logo_wi.png';
import { setCookie, writeBuffer } from 'Utils';
import commonSlice from 'Store/reducers/CommonSlice';
import moment from 'moment';

const HomePresenter = ({ signin }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* Router */
  /* State */
  const initialState = {
    admin_mail: '',
    admin_pw: '',
  };
  const [adminInfo, setAdminInfo] = useState(initialState);
  /* Functions */
  const increment = useCallback(() => {
    dispatch(UsersSlice.actions.incerement());
  }, [dispatch]);

  /**
   * 관리자 정보 입력 핸들러
   * --
   * @param {Event} e
   */
  const handleAdminInfo = (e) => {
    setAdminInfo({ ...adminInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignin();
  };

  /**
   * 관리자 로그인 요청 핸들러
   * --
   */
  const handleSignin = async () => {
    const result = await signin(adminInfo);
    dispatch(commonSlice.actions.setLoading(true));
    if (result) {
      const { access_token, admin_nm, ...admin_user } = result;
      const _admin_nm = writeBuffer(admin_nm);
      const admin = {
        ...admin_user,
        admin_nm: _admin_nm,
      };
      setTimeout(() => {
        dispatch(commonSlice.actions.setLoading(false));
        setAdminInfo(initialState);
        setCookie('busad_token', access_token);
        setCookie('admin_user', JSON.stringify(admin));
        navigate('/dashboard');
      }, 1000);
      return true;
    }
    setTimeout(() => {
      dispatch(commonSlice.actions.setLoading(false));
      handleAlert(false);
    }, 1000);

    return false;
  };

  const handleAlert = (cond) => {
    const id = moment().unix();
    const alertData = {
      id: id,
      type: 'FAILURE',
      title: '로그인 실패',
      message: '로그인에 실패했습니다. 다시 시도해주세요.',
    };
    dispatch(commonSlice.actions.addAlert(alertData));
    setTimeout(() => {
      dispatch(commonSlice.actions.removeAlert(id));
    }, 3000);
  };

  /* Hooks */
  useEffect(() => {
    increment();
  }, [increment]);

  /* Render */

  return (
    <div className='home-login-container'>
      <div className='home-login-left-wrap'>
        <div className='home-login-textwrap'>
          <div className='home-login-left-top'>
            <img src={LOGO} alt='logo' />
          </div>
          {/* home-login-left-top end*/}
          <div className='home-login-left-bottom'>
            <h1>
              부산광역시
              <br />
              장애인체육회
              <br />
              근태관리 시스템
            </h1>
            <p>BUSAN SPORTS ASSOCIATION FOR THE DISABLED</p>
          </div>
        </div>
      </div>
      {/* home-login-left-wrap end */}
      <form className='home-login-right-wrap' onSubmit={handleSubmit}>
        <div className='home-login-right'>
          <h2>LOGIN</h2>
          <div className='home-login-box'>
            <input
              type='text'
              placeholder='아이디'
              name='admin_mail'
              value={adminInfo.admin_mail}
              onChange={handleAdminInfo}
            />
          </div>
          <div className='home-login-box'>
            <input
              type='password'
              placeholder='비밀번호'
              name='admin_pw'
              value={adminInfo.admin_pw}
              onChange={handleAdminInfo}
            />
          </div>
          <div className='home-login-checkbox'>
            <input type='checkbox' name='logincheckbox' id='logincheckbox' />
            <p>아이디 저장</p>
          </div>
          <div className='home-login-button'>
            <button onClick={() => handleSignin()}>로그인</button>
          </div>
        </div>
      </form>
      {/* home-login-right-wrap end */}
    </div> /* .home-login-container end */
  );
};

export default HomePresenter;
