import React, { useCallback, useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faAngleDown,
  // faCircleUser,
  // faMemo,
  faLocationDot,
  faUser,
  faComments,
  faGear,
  faAddressBook,
  faUserPlus,
  faArrowRightFromBracket,
  faChartColumn,
} from '@fortawesome/free-solid-svg-icons';
// import LOGO from 'Assets/img/busad_logo_blue.svg';
import LOGO from 'Assets/img/new_busad_logo.png';
// import MenuDashboardIcon from 'Assets/img/menu-dashboard-icon.svg';
import './css/main.css';
import {
  deleteCookie,
  getCookie,
  PUBLIC_URL,
  readBuffer,
  TypeManager,
} from 'Utils';
import { useDispatch, useSelector } from 'react-redux';
import commonSlice from 'Store/reducers/CommonSlice';
import { Button } from 'antd';

import { SideMenu } from 'Components';

// const { Title } = Typography;

const MainLayout = () => {
  const dispatch = useDispatch();
  /* Router */
  const { pathname } = useLocation();
  const navigate = useNavigate();

  /* State */
  const { page_title } = useSelector((state) => state.common);
  const [admin, setAdmin] = useState({ admin_nm: '' });
  const s = JSON.parse(getCookie('admin_user'));

  /* Functions */
  const handleTitle = useCallback(() => {
    const { title } = TypeManager.getPageTitle(pathname);
    dispatch(commonSlice.actions.setPageTitle(title));
  }, [pathname, dispatch]);

  const handleLogout = () => {
    deleteCookie('busad_token', { path: '/', domain: PUBLIC_URL });
    deleteCookie('admin_user', { path: '/', domain: PUBLIC_URL });
    navigate('/');
  };

  /* Hooks */

  useEffect(() => {
    handleTitle();
  }, [handleTitle]);

  useEffect(() => {
    if (admin.admin_nm !== '') {
      return;
    }
    if (s) {
      setAdmin({ ...s, admin_nm: readBuffer(s.admin_nm) });
      return true;
    }
    navigate('/');
    return false;
  }, [admin.admin_nm, s, navigate]);

  /* Render */
  return (
    <div className='main-container'>
      <div className='header'>
        <Link
          tag='div'
          to='/dashboard'
          style={{
            width: 300,
            height: '100%',
            padding: 5,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            background: '#fafafa',
            border: '1px solid #dcdcdc',
            borderTop: 'none',
            borderLeft: 'none',
          }}
        >
          <img src={LOGO} alt='logo' width={190} />
        </Link>
        {/* <Link to="/dashboard" className="header-logo">
          <img src={LOGO} alt="logo" />
        </Link> */}
        {/* header-logo end */}
        <div
          className='header-current-menu-wrap'
          // style={{ background: 'red', width: '50%' }}
        >
          <div className='header-current-menu'>
            {/* <Title level={3}>{page_title}</Title> */}
            <h2>{page_title}</h2>
          </div>
          {/* header-current-menu end */}
        </div>
        {/* header-current-menu-wrap end */}
        <div className='header-right-wrap'>
          <div
            className='header-right'
            style={{
              // background: 'skyblue',
              width: 370,
              position: 'absolute',
              right: 0,
            }}
          >
            <div
              className='header-right-user'
              onClick={() => navigate('/config')}
            >
              <p>신규관리자</p>
              <FontAwesomeIcon
                icon={faUserPlus}
                className='header-angle-down-icon'
              />
            </div>
            {/* heder-right-notification end */}
            <div className='header-right-user'>
              <p>{admin.admin_nm}</p>
              <FontAwesomeIcon
                icon={faAddressBook}
                className='header-angle-down-icon'
              />
            </div>
            <div className='header-right-user'>
              <p>
                <Button
                  type='link'
                  danger
                  onClick={handleLogout}
                  style={{ margin: 0, padding: 0 }}
                >
                  로그아웃
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    className='header-angle-down-icon'
                  />
                </Button>
              </p>
            </div>
            {/* = 유저 아이콘 = */}
            {/* <div className="header-right-user-icon">
              <FontAwesomeIcon
                icon={faCircleUser}
                className="header-circle-user-icon"
              />
            </div> */}
            {/* header-right-user-icon */}
          </div>
          {/* header-right end */}
        </div>
        {/* header-right-wrap end */}
      </div>
      {/* header end */}
      <div className='main-content-wrap'>
        <div className='sidemenu-wrap'>
          <SideMenu
            menus={[
              {
                key: `dashboard`,
                icon: <FontAwesomeIcon icon={faChartColumn} className='User' />,
                label: `대시보드`,
                onClick: () => navigate('/dashboard'),
              },
              {
                key: `place`,
                icon: (
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className='LocationDot'
                  />
                ),
                label: `근무지 관리`,
                onClick: () => navigate('/place'),
              },
              {
                key: `users`,
                icon: <FontAwesomeIcon icon={faUser} className='User' />,
                label: `회원 관리`,
                onClick: () => navigate('/users'),
              },
              {
                key: `notice`,
                icon: <FontAwesomeIcon icon={faGear} className='Gear' />,
                label: `공지사항 관리`,
                onClick: () => navigate('/notice'),
              },
              {
                key: `community`,
                icon: (
                  <FontAwesomeIcon icon={faComments} className='Comments' />
                ),
                label: `커뮤니티 관리`,
                onClick: () => navigate('/community'),
              },
            ]}
          />
          {/* <div className="sidemenu">
            <ul>
              <li>
                <Link to="/dashboard">
                  <img
                    src={MenuDashboardIcon}
                    alt="MenuDashboardIcon"
                    className="MenuDashboardIcon"
                  />
                  <p>대시보드</p>
                </Link>
              </li>
              <li>
                <Link to="/place">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className="LocationDot"
                  />
                  <p>근무지 관리</p>
                </Link>
              </li>
              <li>
                <Link to="users">
                  <FontAwesomeIcon icon={faUser} className="User" />
                  <p>회원 관리</p>
                </Link>
              </li>
              <li>
                <Link to="notice">
                  <FontAwesomeIcon icon={faGear} className="Gear" />
                  <p>공지사항 관리</p>
                </Link>
              </li>
              <li>
                <Link to="community">
                  <FontAwesomeIcon icon={faComments} className="Comments" />
                  <p>커뮤니티 관리</p>
                </Link>
              </li>
              <li>
                <Link to="admin">
                  <FontAwesomeIcon icon={faGear} className="Gear" />
                  <p> 관리자 옵션</p>
                </Link>
              </li>
              <li>
                <Link to="#" onClick={handleLogout}>
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    className="ArrowRightFromBracket"
                  />
                  <p>로그아웃</p>
                </Link>
              </li>
            </ul>
          </div> */}
        </div>{' '}
        {/* sidemenu-wrap end */}
        <Outlet />
      </div>{' '}
      {/* main-content-wrap end */}
    </div> /* main-container end */
  );
};

export default MainLayout;
