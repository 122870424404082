import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Input, Button } from 'antd';
import MessageAlert from 'Utils/MessageAlert';

const initialState = {
  place_addr: '부산 남구 전포대로 133(48400)',
  place_latitude: '',
  place_longitude: '',
  place_addr_sido: '',
  place_addr_sigungu: '',
  place_addr_bname: '',
  place_addr_roadname: '',
  place_addr_code: '',
  place_addr_detail: '',
};

/**
 * [Component] MapAddress
 * --
 */
const MapAddress = ({ initial, onChange }) => {
  // const { placeInfo, setPlaceInfo, placeAddr, setPlaceAddr } = props;
  const container = useRef(null); //지도를 담을 영역의 DOM 레퍼런스
  /* ===== ROUTER ===== */
  /* ===== STATE ===== */
  const [, setPlaceAddr] = useState(null);
  const [placeInfo, setPlaceInfo] = useState({ ...initialState, ...initial });
  const [daumPostCode, setDaumPostCode] = useState(null);

  /* ===== VARIABLES ===== */
  const options = {
    //지도를 생성할 때 필요한 기본 옵션
    center: new window.kakao.maps.LatLng(
      initial.place_latitude ? initial.place_latitude : 33.450701,
      initial.place_longitude ? initial.place_longitude : 126.570667
    ), //지도의 중심좌표.
    level: 3, //지도의 레벨(확대, 축소 정도)
  };

  /* ===== FUNCTIONS ===== */
  /**
   * 에러핸들러
   * --
   */
  const handleError = (error) => {};

  /**
   * 에러핸들러
   * --
   */
  const handleChangeText = (key, value) => {
    const newValue = {
      ...placeInfo,
      [key]: value,
    };
    console.log(newValue, 'newvalue');
    setPlaceInfo(newValue);
    onChange(newValue);
  };

  /**
   * 주소 초기화 함수
   * --
   */
  const handleResetAddress = () => {
    if (placeInfo.place_addr) {
      setPlaceAddr(null);
      setPlaceInfo(initialState);
    }
    MessageAlert.info('주소가 초기화 되었습니다.');
  };

  /**
   * 근무지 정보 입력
   * --
   */
  // const handlePlaceInfo = (e) => {
  //   setPlaceInfo({ ...placeInfo, [e.target.name]: e.target.value });
  // };

  /**
   * 팝업오픈 함수
   * --
   */
  const openPostCodePopup = () => {
    if (daumPostCode) {
      setPlaceAddr(null);
      daumPostCode.open();
    }
  };

  /**
   * 초기설정
   * --
   */
  const initiate = (e) => {
    window.daum.postcode.load(() => {
      setDaumPostCode(
        new window.daum.Postcode({
          oncomplete: (data) => {
            // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.
            const { sido, sigungu, zonecode, roadname, address, bname } = data;

            // 지도를 생성합니다
            var map = new window.kakao.maps.Map(container.current, options);

            // 주소-좌표 변환 객체를 생성합니다
            var geocoder = new window.kakao.maps.services.Geocoder();

            // 주소로 좌표를 검색합니다
            geocoder.addressSearch(address, function (result, status) {
              // 정상적으로 검색이 완료됐으면
              if (status === window.kakao.maps.services.Status.OK) {
                var coords = new window.kakao.maps.LatLng(
                  result[0].y,
                  result[0].x
                );
                const newData = {
                  ...placeInfo,
                  place_addr: address,
                  place_addr_bname: bname,
                  place_addr_code: zonecode,
                  place_addr_roadname: roadname,
                  place_addr_sido: sido,
                  place_addr_sigungu: sigungu,
                  place_latitude: result[0].y,
                  place_longitude: result[0].x,
                };

                setPlaceAddr(newData);
                setPlaceInfo(newData);

                // 결과값으로 받은 위치를 마커로 표시합니다
                var marker = new window.kakao.maps.Marker({
                  map: map,
                  position: coords,
                });

                // 인포윈도우로 장소에 대한 설명을 표시합니다
                var infowindow = new window.kakao.maps.InfoWindow({
                  content: `<div style="width:150px;text-align:center;padding:6px 0;">${address}</div>`,
                });
                infowindow.open(map, marker);

                // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
                map.setCenter(coords);
                onChange(newData);
              }
            });
          },
        })
      );
    });
  };

  /* ===== HOOKS ===== */
  /**
   *
   */
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
    script.onload = (e) => initiate(e);
    script.onerror = (error) => handleError(error);
    script.id = 'daum-post-code-api';
    document.body.appendChild(script);
    // eslint-disable-next-line
  }, []);
  /**
   *
   */
  useEffect(() => {
    if (navigator.geolocation) {
      // GeoLocation을 이용해서 접속 위치를 얻어옵니다
      navigator.geolocation.getCurrentPosition(function (position) {
        // 기본값(위도)
        let lat = initial.place_latitude
          ? initial.place_latitude
          : position.coords.latitude;
        // 기본값(경도)
        let lon = initial.place_longitude
          ? initial.place_longitude
          : position.coords.longitude;
        //
        setPlaceAddr({
          place_latitude: lat,
          place_longitude: lon,
        });
        // let locPosition = new window.kakao.maps.LatLng(lat, lon);
        // let map = new window.kakao.maps.Map(container.current, options);
        // let marker = new window.kakao.maps.Marker({
        //   map: map,
        //   position: locPosition,
        // });

        // let iwContent =
        //   '<div style="width:150px;text-align:center;padding:6px 0;">근무지 위치</div>'; // 인포윈도우에 표시할 내용

        // // 인포윈도우를 생성합니다
        // let infowindow = new window.kakao.maps.InfoWindow({
        //   content: iwContent,
        // });

        // // 인포윈도우를 마커위에 표시합니다
        // infowindow.open(map, marker);
        // map.setCenter(locPosition);
      });
    }
    return () => {};
  }, [initial.place_latitude, initial.place_longitude]);

  useEffect(() => {
    options.level = 1;
    const map = new window.kakao.maps.Map(container.current, options);
    const geocoder = new window.kakao.maps.services.Geocoder();
    const marker = new window.kakao.maps.Marker();
    const coords = new window.kakao.maps.LatLng(
      placeInfo?.place_latitude,
      placeInfo?.place_longitude
    );
    const searchDetailAddrFromCoords = (coords, callback) => {
      const geocoder = new window.kakao.maps.services.Geocoder();
      geocoder.coord2Address(coords.getLng(), coords.getLat(), callback);
    };
    map.addListener('click', function (mouseEvent) {
      console.log(mouseEvent.LatLng);
      searchDetailAddrFromCoords(mouseEvent.latLng, function (result, status) {
        if (status === window.kakao.maps.services.Status.OK) {
          const { address, road_address } = result[0];
          const newData = {
            place_addr: road_address?.address_name,
            place_addr_bname: address?.region_3depth_name,
            place_addr_code: road_address?.zone_no,
            place_addr_roadname: road_address?.road_name,
            place_addr_sido: address?.region_1depth_name,
            place_addr_sigungu: address?.region_2depth_name,
            place_latitude: mouseEvent.latLng.Ma,
            place_longitude: mouseEvent.latLng.La,
          };
          setPlaceAddr(newData);
          setPlaceInfo(newData);
        }
      });
    });
    geocoder.coord2Address(
      coords.getLng(),
      coords.getLat(),
      function (result, status) {
        if (status === window.kakao.maps.services.Status.OK) {
          if (
            placeInfo?.place_latitude !== coords.getLat() ||
            placeInfo?.place_latitude !== coords.getLng()
          ) {
            const { address, road_address } = result[0];
            const newData = {
              ...placeInfo,
              place_addr: road_address?.address_name,
              place_addr_bname: address?.region_3depth_name,
              place_addr_code: road_address?.zone_no,
              place_addr_roadname: road_address?.road_name,
              place_addr_sido: address?.region_1depth_name,
              place_addr_sigungu: address?.region_2depth_name,
              place_latitude: coords.getLat(),
              place_longitude: coords.getLng(),
            };
            setPlaceAddr(newData);
            setPlaceInfo(newData);
          }
          marker.setPosition(coords);
          marker.setMap(map);
          var infowindow = new window.kakao.maps.InfoWindow({
            content: `<div style="width:150px;text-align:center;padding:6px 0;">${placeInfo?.place_addr}</div>`,
          });
          infowindow.open(map, marker);
          map.setCenter(coords);
        } else {
          console.error('주소 정보를 가져오는데 실패했습니다.', status);
        }
      }
    );
  }, [placeInfo?.place_longitude, placeInfo?.place_latitude]);
  /* ===== RENDER ===== */
  return (
    <>
      {/* === 주소 === */}
      <div style={{ position: 'relative' }}>
        <Input
          name='search'
          readOnly
          value={placeInfo.place_addr}
          onClick={openPostCodePopup}
          placeholder='클릭하여 주소 검색'
          style={{ margin: 0 }}
        />
        <Button
          size='small'
          style={{
            position: 'absolute',
            top: 10,
            right: 4,
            fontSize: '0.85em',
          }}
          onClick={handleResetAddress}
        >
          초기화
        </Button>
      </div>
      {/* === 상세주소 === */}
      <div style={{ marginBottom: 7 }}>
        <Input
          placeholder='상세주소를 입력해 주세요.'
          value={placeInfo.place_addr_detail}
          onChange={(e) =>
            handleChangeText('place_addr_detail', e.target.value)
          }
          style={{ margin: 0, width: '75%' }}
          disabled={!placeInfo.place_addr}
        />
        <Input
          placeholder='우편번호'
          value={placeInfo.place_addr_code}
          style={{ margin: 0, width: '24%', marginLeft: '1%' }}
          disabled
        />
      </div>
      {/* === 지도 === */}
      <div>
        <div
          className='map'
          style={{
            width: '100%',
            height: '300px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'lightgray',
          }}
          ref={container}
        >
          <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: '3rem' }} />
        </div>
        <div
          style={{
            background: '#f1f1f1',
            padding: '0 7px',
            textAlign: 'center',
          }}
        >
          <p>
            위/경도:{' '}
            {placeInfo.place_latitude ? placeInfo.place_latitude : '--'},{' '}
            {placeInfo.place_longitude ? placeInfo.place_longitude : '--'}
          </p>
        </div>
      </div>
    </>
  );
};

/* DF */
MapAddress.defaultProps = {
  initial: {},
  onChange: () => {},
};

export default MapAddress;
