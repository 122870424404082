/**
 *
 *
 */

import React, { useEffect, useState } from 'react';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx/xlsx.mjs';
// import { v4 } from 'uuid';
import MessageAlert from 'Utils/MessageAlert';

/**
 * [Component] XlsxParser
 * --
 */
const XlsxParser = ({
  onChangeXlsx,
  onChangeLoading,
  customFields,
  originalPlaceList,
}) => {
  /* ===== STATE ===== */
  const [uploadFile, setUploadFile] = useState(null);
  const [, setPlaceList] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [option, setOption] = useState('new');

  console.log('originalPlaceList : ', originalPlaceList);

  /* ===== FUNCTIONS ===== */
  /**
   * on change
   * --
   */
  const handleChange = (e) => {
    const { originFileObj } = e.file;
    setPlaceList([]);
    setUploadFile(originFileObj);
  };

  /**
   *파일 업로드시 고객 정보 파싱
   */
  const handleSetFileUpload = (data) =>
    new Promise((resolve, reject) => {
      if (data.length >= 10000) {
        setPlaceList([]);
        setUploadFile(null);
        reject('데이터의 양이 너무 많습니다.');
        return;
      }
      const { no, title, addr, tel } = customFields;

      console.log('data: ', data);
      const result = data.map((item) => {
        // eslint-disable-next-line
        // const isAdded = item['신규여부'] === F ? true : false;
        const fid = originalPlaceList.findIndex(
          (o) => o.place_nm === item[title]
        );
        console.log('fid: ', fid);
        const newItem = {
          place_seq: item[no],
          place_nm: item[title],
          place_addr: item[addr],
          place_tel: item[tel],
          place_isAdded: fid < 0 ? true : false,
          // place_isAdded: isAdded,
          // place_time: item['시간'],
        };
        return newItem;
      });

      const result2 = result.filter(
        (item) =>
          /* 제외조건 */
          item['place_seq'] !== null &&
          item['place_seq'] !== undefined &&
          item['place_seq'] !== '' &&
          item['place_nm'] !== null &&
          item['place_nm'] !== undefined &&
          item['place_nm'] !== '' &&
          item['place_addr'] !== null &&
          item['place_addr'] !== undefined &&
          item['place_addr'] !== ''
        /* 포함조건 */
        // ((option === 'new' && item['place_isAdded'] === true) ||
        //   (option === 'old' && item['place_isAdded'] === false))
        // item['place_isAdded'] === true
      );

      console.log('추가할 근무지 목록: ', result);
      console.log('추가할 근무지 목록: ', result2);

      setLoading(false);
      if (result2.length !== 0) {
        resolve(result2);
      } else {
        reject('추가할 근무지가 없습니다. 업로드옵션을 확인해주세요');
      }
    });

  /**
   * 파일 업로드
   * --
   */
  const handleFile = async (file) => {
    if (typeof file !== 'object') {
      return;
    }
    setLoading(true);
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws);
      handleSetFileUpload(dataParse)
        .then((res) => {
          setPlaceList(res);
          onChangeXlsx(res);
          // setLoading(false);
        })
        .catch((err) => {
          MessageAlert.warning(err);
          // setLoading(false);
        });
    };
    reader.readAsBinaryString(file);
  };

  /* ===== HOOKS ===== */
  useEffect(() => {
    // console.log('onchange');
    uploadFile && handleFile(uploadFile);
  }, [uploadFile]);

  useEffect(() => {
    onChangeLoading(loading);
  }, [loading]);

  // console.log('loading: ', loading);
  /* ===== RENDER ===== */
  return (
    <>
      {/* <Radio.Group onChange={(v) => setOption(v.target.value)} value={option}>
        <Radio value={'new'} style={{ left: 0 }}>
          신규항목추가
        </Radio>
        <Radio value={'old'} style={{ left: 0 }}>
          기존항목제외
        </Radio>
      </Radio.Group> */}
      <Upload.Dragger
        name='files'
        action={'/notfund'}
        onChange={handleChange}
        maxCount={1}
        style={{ height: 100 }}
      >
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>엑셀을 입력해주세요</p>
        <p className='ant-upload-hint'>엑셀 양식을 맞춰서 업로드 해주세요</p>
      </Upload.Dragger>
    </>
  );
};

XlsxParser.defaultProps = {
  onChangeXlsx: () => {},
  onChangeLoading: () => {},
};

export default XlsxParser;
