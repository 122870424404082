import React from 'react';
import dashboard_icon from 'Assets/img/dashboard-icon.png';
import moment from 'moment';
import { TypeManager } from 'Utils';
import './place-dashboard.css';

const DASHBOARD_STATUS = TypeManager.getDashboardStatusList();

const PlaceDashboard = ({ dashboard_status }) => {
  /* Router */
  /* State */
  const dateStatus = moment().format('YYYY년 MM월 DD일');

  /* Functions */
  /* Hooks */
  /* Render */
  const dashboardStatus = dashboard_status.map((item, idx) => {
    const { status, value } = item;
    const { title } = TypeManager.getDashboardStatus(status);
    return (
      <div className="place-dashboard-top-inner-box" key={idx}>
        <div className="place-dashboard-top-inner-box-img">
          <img src={dashboard_icon} alt="dashboard-icon" />
        </div>{' '}
        <div className="place-dashboard-top-inner-box-txt">
          <p>{title}</p>
          <p>
            <span>{value}</span>건
          </p>
        </div>
      </div>
    );
  });
  return (
    <div className="place-dashboard-top">
      <div className="place-dashboard-top-title">
        <h2>{dateStatus} 근태 현황</h2>
      </div>
      <div className="place-dashboard-top-inner">{dashboardStatus}</div>
      {/* place-dashboard-top-inner end */}
    </div>
  );
};

PlaceDashboard.defaultProps = {
  dashboard_status: DASHBOARD_STATUS.map((item) => {
    return {
      ...item,
      status_value: Math.floor(Math.random() * (100 - 0 + 1) + 0),
    };
  }),
};

export default PlaceDashboard;
