/**
 *
 *
 */

import './community.css';
import React, { useState } from 'react';
import { CommunityDetail } from './components';
import { ModalLayout } from 'Components';
import { Input, Table, Button } from 'antd';
import moment from 'moment';

const { Search } = Input;

/**
 * Community Presenter
 * --
 */
const CommunityPresenter = ({ communityList, replyList }) => {
  /* ===== Router ===== */
  /* ===== State ===== */
  const [detailModal, setDetailModal] = useState(false);
  const [community, setCommunity] = useState();
  const [keyword, setKeyword] = useState('');
  const [keyword2, setKeyword2] = useState('');

  /* ===== Functions ===== */
  /**
   * 키워드 검색
   */
  const onSearch = () => {
    setKeyword2(keyword);
  };

  /**
   * 모달 출력
   * --
   */
  const handleDetailModal = () => {
    setDetailModal(!detailModal);
  };

  /**
   * 커뮤니티 상세
   * --
   * @param {*} item
   */
  const handleCommunityDetail = async (item) => {
    const { community_id, reply_cnt } = item;
    if (reply_cnt >= 1) {
      const reply = await replyList(community_id);
      if (reply) {
        setCommunity({ ...item, reply });
      }
    } else {
      setCommunity(item);
    }
    setDetailModal();
  };

  /**
   *
   */
  const onCell = (record, _index) => {
    return {
      onClick: (_) => handleCommunityDetail(record),
    };
  };

  /* ===== Hooks ===== */

  const filteredData = communityList
    ? keyword2
      ? communityList.filter((c) => c.community_title.indexOf(keyword2) !== -1)
      : communityList
    : [];

  /* ===== Render ===== */
  return (
    <div className="community-container">
      <div className="community-contents">
        <div className="community-contents-top">
          {/* 검색  */}
          <div>
            <Search
              size="large"
              placeholder="제목을 입력해주세요"
              onSearch={onSearch}
              onChange={(e) => setKeyword(e.target.value)}
              style={{ width: 330 }}
              value={keyword}
            />
            <Button
              size="large"
              disabled={keyword2 === '' ? true : false}
              onClick={() => {
                setKeyword('');
                setKeyword2('');
              }}
            >
              초기화
            </Button>
          </div>
        </div>
        {/* 데이터 */}
        <div className="community-contents-bottom">
          <Table
            style={{ cursor: 'pointer' }}
            columns={[
              {
                title: '작성자',
                dataIndex: 'user_nm',
                key: 'user_nm',
                onCell,
              },
              {
                title: '제목',
                render: ({ community_title }) => {
                  const title =
                    community_title.length >= 15
                      ? `${community_title.slice(0, 15)}...`
                      : community_title;
                  return title;
                },
                onCell,
              },
              {
                title: '댓글',
                render: ({ reply_cnt }) => `${reply_cnt}개`,
                onCell,
              },
              {
                title: '등록일',
                render: ({ created_at }) =>
                  moment(created_at).format('YYYY-MM-DD'),
                onCell,
              },
            ]}
            dataSource={filteredData}
          ></Table>
        </div>{' '}
      </div>
      <ModalLayout custom modal={detailModal} setModal={handleDetailModal}>
        <CommunityDetail community={community} close={handleDetailModal} />
      </ModalLayout>
    </div>
  );
};

CommunityPresenter.defaultProps = {
  communityList: [],
};

export default CommunityPresenter;
