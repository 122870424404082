/**
 *
 *
 */
import { Row, Col, Typography, Button } from 'antd';
import { Content } from 'Components';
import moment from 'moment';

const { Title } = Typography;
const styles = {
  label: { padding: '3.5px 0' },
  content: { padding: '3.5px 0', fontWeight: 'normal' },
};
/**
 * [Component] 일정 상세보기
 * --
 */
const ScheduleInfo = ({ schedule, closeModal }) => {
  const { raw } = schedule;

  /*  
  'schedule_type',
  'schedule_start_date',
  'schedule_title',
  'schedule_content',
  'created_at',
  */
  /* ===== Render ===== */
  return (
    <>
      <Content>
        <Row>
          {/* === 구분 === */}
          <Col span={4}>
            <Title level={5} style={styles.label}>
              구분
            </Title>
          </Col>
          <Col span={20}>
            <Title level={5} style={styles.content}>
              {raw &&
                (raw.schedule_type === 'INTERNAL' ? '개인일정' : '공식일정')}
            </Title>
          </Col>
          <Col span={24}>
            <hr style={{ margin: '3px 0 10px 0', opacity: 0.35 }} />
          </Col>

          {/* === 날짜 === */}
          <Col span={4}>
            <Title level={5} style={styles.label}>
              날짜
            </Title>
          </Col>
          <Col span={20}>
            <Title level={5} style={styles.content}>
              {raw &&
                moment(raw.schedule_start_date).format('YYYY년 MM월 DD일')}
            </Title>
          </Col>
          <Col span={24}>
            <hr style={{ margin: '3px 0 10px 0', opacity: 0.35 }} />
          </Col>

          {/* === 시간 === */}
          <Col span={4}>
            <Title level={5} style={styles.label}>
              시간
            </Title>
          </Col>
          <Col span={20}>
            <Title level={5} style={styles.content}>
              {raw && `${raw.schedule_start_time} ~ ${raw.schedule_end_time}`}
            </Title>
          </Col>
          <Col span={24}>
            <hr style={{ margin: '3px 0 10px 0', opacity: 0.35 }} />
          </Col>

          {/* === 일정명 === */}
          <Col span={4}>
            <Title level={5} style={styles.label}>
              일정명
            </Title>
          </Col>
          <Col span={20}>
            <Title level={5} style={styles.content}>
              {raw && raw.schedule_title}
            </Title>
          </Col>
          <Col span={24}>
            <hr style={{ margin: '3px 0 10px 0', opacity: 0.35 }} />
          </Col>

          {/* === 내용 === */}
          <Col span={4}>
            <Title level={5} style={styles.label}>
              내용
            </Title>
          </Col>
          <Col span={20}>
            <Title level={5} style={styles.content}>
              {raw && raw.schedule_content}
            </Title>
          </Col>
          <Col span={24}>
            <hr style={{ margin: '3px 0 10px 0', opacity: 0.35 }} />
          </Col>

          {/* === 생성일 === */}
          <Col span={4}>
            <Title level={5} style={styles.label}>
              생성일
            </Title>
          </Col>
          <Col span={20}>
            <Title level={5} style={styles.content}>
              {raw && moment(raw.created_at).format('YYYY년 MM월 DD일')}
            </Title>
          </Col>
        </Row>
      </Content>

      <div
        style={{
          width: '100%',
          borderTop: '1px solid #f1f1f1',
          padding: '15px 25px',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Button onClick={closeModal}>닫기</Button>&nbsp;
        <Button
          type="primary"
          onClick={() =>
            closeModal({
              id: raw.schedule_id,
              mode: 'SCHEDULE',
            })
          }
        >
          수정
        </Button>
      </div>
    </>
  );
};

export default ScheduleInfo;
