import ApiConstant from 'Api/ApiConstant';
import { ApiManager } from 'Utils';
import MessageAlert from 'Utils/MessageAlert';
const $http = new ApiManager();

const AuthApi = {
  /**
   * 관리자 가입 요청
   * --
   * @param {Object} adminInfo
   * @returns
   */
  signupAdmin: async (adminInfo) => {
    try {
      const url = ApiConstant.SIGNUP_ADMIN;
      const { status, data, message } = await $http.post(url, adminInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },
  /**
   * 관리자 로그인 요청
   * --
   * @param {Object} adminInfo
   * @returns
   */
  signinAdmin: async (adminInfo) => {
    try {
      const url = ApiConstant.SIGNIN_ADMIN;
      const { status, data, message } = await $http.post(url, adminInfo);
      if (status === 200) {
        return data;
      } else {
        MessageAlert.warning('모든 파라미터를 확인해주세요');
      }
      throw message;
    } catch (e) {
      return false;
    }
  },
  verify: async () => {
    try {
      const url = ApiConstant.VERIFY;
      const { status, data, message } = await $http.post(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },
  adminCheck: async () => {
    try {
      const url = ApiConstant.CHECK;
      const { status, data, message } = await $http.post(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },
};
export default AuthApi;
