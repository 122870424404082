import React, { useEffect, useState, useRef, useCallback } from 'react';
import { PlaceApi } from 'Api';
import './PlaceRegist.css';
import MapAddress from './MapAddress';
import MapAddressLocation from './MapAddressLocation';
import { useDispatch } from 'react-redux';
import commonSlice from 'Store/reducers/CommonSlice';
import { useNavigate } from 'react-router-dom';
// import { InboxOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Row,
  Col,
  Tabs,
  Typography,
  List,
  Tag,
  Button,
} from 'antd';
import { Content, XlsxParser } from 'Components';
import MessageAlert from 'Utils/MessageAlert';

const { Title } = Typography;
const { TabPane } = Tabs;
// const formItemLayout = {
//   labelCol: { span: 3 },
//   wrapperCol: { span: 19 },
// };
const options = {
  //지도를 생성할 때 필요한 기본 옵션
  center: new window.kakao.maps.LatLng(33.450701, 126.570667), //지도의 중심좌표.
  level: 3, //지도의 레벨(확대, 축소 정도)
};

/**
 * [Component] PlaceRegist
 * --
 */
const PlaceRegist = () => {
  const dispatch = useDispatch();
  /* ===== Router ===== */
  const navigate = useNavigate();
  /* ===== State ===== */
  const container = useRef(null); //지도를 담을 영역의 DOM 레퍼런스
  const initialState = {
    place_nm: '',
    place_addr: '',
    place_latitude: '',
    place_longitude: '',
    place_addr_sido: '',
    place_addr_sigungu: '',
    place_addr_bname: '',
    place_addr_roadname: '',
    place_addr_detail: '',
    place_addr_code: '',
    place_tel: '',
    place_distance: '',
  };
  const [placeInfo, setPlaceInfo] = useState(initialState);
  const [placeAddr, setPlaceAddr] = useState();
  const [placeInfoLoc, setPlaceInfoLoc] = useState(initialState);
  const [placeAddrLoc, setPlaceAddrLoc] = useState();
  const [placeList, setPlaceList] = useState([]);
  const [xlssPlaceList, setXlsxPlaceList] = useState([]);
  const [, setDaumPostCode] = useState(null);
  const [, setCurrentTab] = useState('1');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [customFields, setCustomFiedls] = useState({
    no: 'No.',
    title: '단체',
    addr: '주소',
    tel: '연락처',
    newFlag: '신규여부',
  });

  /* ===== Functions ===== */
  const handleError = (error) => {
    console.log(error);
  };

  /**
   * 근무지 전체 목록 조회
   * --
   */
  const handlePlaceList = useCallback(async () => {
    const result = await PlaceApi.getPlceList();
    if (result) {
      setPlaceList(result);
      return true;
    }
    setPlaceList([]);
    return false;
  }, []);

  /**
   * 근무지 정보 입력
   * --
   * @param {KeyboardEvent} e
   */
  const handlePlaceInfo = (e) => {
    setPlaceInfo({ ...placeInfo, [e.target.name]: e.target.value });
  };
  /**
   * 근무지 좌표 정보 입력
   * --
   * @param {KeyboardEvent} e
   */
  const handlePlaceInfoLoc = (e) => {
    setPlaceInfoLoc({ ...placeInfoLoc, [e.target.name]: e.target.value });
  };
  /**
   * 근무지 등록
   * --
   * @param {*} placeInfo
   * @returns
   */
  const handleCreatePlace = async () => {
    const postData = {
      ...placeInfo,
      place_latitude: placeInfo.place_latitude,
      place_longitude: placeInfo.place_longitude,
      place_distance: placeInfo?.place_distance
        ? parseFloat(placeInfo.place_distance / 1000)
        : null,
    };

    const result = await PlaceApi.createPlace(postData);
    dispatch(commonSlice.actions.setLoading(true));
    if (result) {
      setTimeout(() => {
        dispatch(commonSlice.actions.setLoading(false));
        navigate('/place');
      }, 1000);
      return true;
    }
    setTimeout(() => {
      dispatch(commonSlice.actions.setLoading(false));
    }, 1000);
    return false;
  };
  /**
   * 근무지 좌표 등록
   * --
   * @param {*} placeInfoLoc
   * @returns
   */
  const handleCreatePlaceLoc = async () => {
    const postData = {
      ...placeInfoLoc,
      place_latitude: placeInfoLoc.place_latitude.toString(),
      place_longitude: placeInfoLoc.place_longitude.toString(),
      place_distance: placeInfoLoc?.place_distance
        ? parseFloat(placeInfoLoc.place_distance / 1000)
        : null,
    };
    const result = await PlaceApi.createPlace(postData);
    dispatch(commonSlice.actions.setLoading(true));
    if (result) {
      setTimeout(() => {
        dispatch(commonSlice.actions.setLoading(false));
        navigate('/place');
      }, 1000);
      return true;
    }
    setTimeout(() => {
      dispatch(commonSlice.actions.setLoading(false));
    }, 1000);
    return false;
  };
  // /**
  //  *
  //  * --
  //  */
  // const openPostCodePopup = () => {
  //   if (daumPostCode) {
  //     setPlaceAddr(null);
  //     daumPostCode.open();
  //   }
  // };

  /**
   *
   * --
   */
  const initiate = (e) => {
    window.daum.postcode.load(() => {
      setDaumPostCode(
        new window.daum.Postcode({
          oncomplete: (data) => {
            // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.
            const { sido, sigungu, zonecode, roadname, address, bname } = data;

            // 지도를 생성합니다
            var map = new window.kakao.maps.Map(container.current, options);

            // 주소-좌표 변환 객체를 생성합니다
            var geocoder = new window.kakao.maps.services.Geocoder();

            // 주소로 좌표를 검색합니다
            geocoder.addressSearch(address, function (result, status) {
              // 정상적으로 검색이 완료됐으면
              if (status === window.kakao.maps.services.Status.OK) {
                var coords = new window.kakao.maps.LatLng(
                  result[0].y,
                  result[0].x
                );
                setPlaceAddr({
                  place_addr: address,
                  place_addr_bname: bname,
                  place_addr_code: zonecode,
                  place_addr_roadname: roadname,
                  place_addr_sido: sido,
                  place_addr_sigungu: sigungu,
                  place_latitude: result[0].y,
                  place_longitude: result[0].x,
                });

                // 결과값으로 받은 위치를 마커로 표시합니다
                var marker = new window.kakao.maps.Marker({
                  map: map,
                  position: coords,
                });

                // 인포윈도우로 장소에 대한 설명을 표시합니다
                var infowindow = new window.kakao.maps.InfoWindow({
                  content: `<div style="width:150px;text-align:center;padding:6px 0;">${address}</div>`,
                });
                infowindow.open(map, marker);

                // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
                map.setCenter(coords);
              }
            });
          },
        })
      );
    });
  };

  /**
   *
   * --
   */
  const initiate2 = (locations = [], single = false) => {
    if (
      !window.confirm(
        single
          ? `${locations[0].place_nm}근무지를 등록하시겠습니까?`
          : `일괄등록 하시겠습니까?\n업로드 분량에 따라 다소 시간이 소요됩니다.`
      )
    ) {
      return;
    }
    // const locations = ['서울 중구 세종대로 110 서울특별시청'];

    // 지도를 생성합니다
    // var map = new window.kakao.maps.Map(container.current, options);

    // 주소-좌표 변환 객체를 생성합니다
    const geocoder = new window.kakao.maps.services.Geocoder();

    let newModels = [];
    let notWorkings = [];
    let limit = locations.length;
    // 반복문
    locations.forEach((location, index) => {
      // 위도경도 구하기
      geocoder.addressSearch(location.place_addr, function (result, status) {
        // console.log('[geocoder.addressSearch] status : ', status);
        // console.log('[geocoder.addressSearch] result : ', result);
        // console.log('[geocoder.addressSearch] location : ', location);
        if (result.length > 0) {
          const { road_address } = result[0];
          const newModel = {
            ...location,
            place_addr: road_address.address_name,
            place_addr_detail: location.place_addr,
            place_latitude: road_address.y,
            place_longitude: road_address.x,
            place_addr_sido: road_address.region_1depth_name,
            place_addr_sigungu: road_address.region_2depth_name,
            place_addr_bname: road_address.region_3depth_name,
            place_addr_roadname: road_address.road_name,
            place_addr_code: road_address.zone_no,
          };

          newModels.push(newModel);
        } else {
          notWorkings.push(location);
        }

        // console.log('newModels: ', newModels);
        if (newModels.length + notWorkings.length === limit) {
          // API CALL
          PlaceApi.createPlaceBulk(newModels)
            .then((res) => {
              console.log('res: ', res);
              MessageAlert.success(`${limit}개의 근무지를 등록했습니다.`);

              if (single === false) {
                navigate('/place');
              } else {
                const fid = xlssPlaceList.findIndex(
                  (x) => x.place_nm === locations[0].place_nm
                );
                if (fid >= 0) {
                  setXlsxPlaceList((prev) => {
                    console.log('prev: ', prev);

                    return [
                      ...prev.slice(0, fid),
                      {
                        ...prev[fid],
                        place_isAdded: false,
                      },
                      ...prev.slice(fid + 1),
                    ];
                  });
                }
              }
            })
            .catch((e) => {
              console.log('Convert error: ', e);
              MessageAlert.error(
                '근무지 등록을 할 수 없습니다. 다시 시도해주세요'
              );
            });

          //  const cutLength =
          //    newModels.length > 100 ? Math.ceil(newModels.length / 100) : 1;
          // for (let i = 0; i > cutLength; i++) {
          //   PlaceApi.createPlaceBulk(newModels.slice(i * 100, (i + 1) * 100))
          //     .then((res) => {
          //       // console.log('res : ', res);
          //       MessageAlert.error(`${limit}개의 근무지를 등록했습니다.`);
          //       navigate('/place');
          //     })
          //     .catch((e) => {
          //       console.log('Convert error: ', e);
          //       MessageAlert.error(
          //         '근무지 등록을 할 수 없습니다. 다시 시도해주세요'
          //       );
          //     });
          // } // for loop
        }
      });
    });

    // // 주소로 좌표를 검색합니다
    // geocoder.addressSearch(location, function (result, status) {
    //   console.log('status: ', status);
    //   console.log('result: ', result);
    //   // 정상적으로 검색이 완료됐으면
    //   if (status === window.kakao.maps.services.Status.OK) {
    //     var coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);
    //     console.log('[geocoder] coords: ', coords);

    //     // 결과값으로 받은 위치를 마커로 표시합니다
    //     var marker = new window.kakao.maps.Marker({
    //       map: map,
    //       position: coords,
    //     });
    //     console.log('[geocoder] marker: ', marker);

    //     // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
    //     map.setCenter(coords);
    //   }
    // });

    // window.daum.postcode.load(() => {
    //   setDaumPostCode(
    //     new window.daum.Postcode({
    //       oncomplete: (data) => {
    //         // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

    //         // 지도를 생성합니다
    //         var map = new window.kakao.maps.Map(container.current, options);

    //         // 주소-좌표 변환 객체를 생성합니다
    //         var geocoder = new window.kakao.maps.services.Geocoder();

    //         // 주소로 좌표를 검색합니다
    //         geocoder.addressSearch("서울 중구 세종대로 110","서울특별시청", function (result, status) {
    //           // 정상적으로 검색이 완료됐으면
    //           if (status === window.kakao.maps.services.Status.OK) {
    //             var coords = new window.kakao.maps.LatLng(
    //               result[0].y,
    //               result[0].x
    //             );
    //             console.log('[geocoder] coords: ', coords);

    //             // 결과값으로 받은 위치를 마커로 표시합니다
    //             var marker = new window.kakao.maps.Marker({
    //               map: map,
    //               position: coords,
    //             });
    //             console.log('[geocoder] marker: ', marker);

    //             // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
    //             map.setCenter(coords);
    //           }
    //         });
    //       },
    //     })
    //   );
    // });
  };

  /* ===== HOOKS ===== */
  /* Hooks */
  /**
   * 근무지목록 조회
   * --
   */
  useEffect(() => {
    handlePlaceList();
  }, [handlePlaceList]);

  /**
   * 근무지 변경 훅
   */
  useEffect(() => {
    if (placeAddr) {
      setPlaceInfo((p) => ({ ...p, ...placeAddr }));
    }
    if (placeAddrLoc) {
      setPlaceInfoLoc((p) => ({ ...p, ...placeAddrLoc }));
    }
  }, [placeAddr, placeAddrLoc]);

  /* Hooks */
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
    script.onload = (e) => initiate(e);
    script.onerror = (error) => handleError(error);
    script.id = 'daum-post-code-api';
    document.body.appendChild(script);
    // eslint-disable-next-line
  }, []);

  console.log('xlssPlaceList: ', xlssPlaceList);
  /* ===== RENDER ===== */
  return (
    <>
      <div className='place-regist-container'>
        <div className='place-regist-contents'>
          {/* === TAB === */}
          <Tabs defaultActiveKey='1' size='large' onChange={setCurrentTab}>
            {/* =  = */}
            <TabPane tab='직접입력' key='1'>
              <br />
              <div className='place-regist-name'>
                <label htmlFor='place-name'>근무지명</label>
                <input
                  type='text'
                  name='place_nm'
                  value={placeInfo.place_nm}
                  onChange={handlePlaceInfo}
                  placeholder='내용을 입력해 주세요.'
                />
              </div>{' '}
              <MapAddress
                placeAddr={placeAddr}
                setPlaceAddr={setPlaceAddr}
                placeInfo={placeInfo}
                setPlaceInfo={setPlaceInfo}
              />
              {/* <div className="place-regist-work-type">
          <p>근로제</p>
          <input type="radio" id="work-type1" name="work-type" />
          <label htmlFor="work-type1">일반 근로제</label>
          <input type="radio" id="work-type2" name="work-type" />
          <label htmlFor="work-type2">시차출퇴근제</label>
          <input type="radio" id="work-type3" name="work-type" />
          <label htmlFor="work-type3">간주 근로제</label>
          <input type="radio" id="work-type4" name="work-type" />
          <label htmlFor="work-type4">기타 근로</label>
        </div>{' '}
        <div className="place-regist-work-plan">
          <p>근로 일정</p>
          <table>
            <thead>
              <tr>
                <th>근태 / 근로요일</th>
                <td>월</td>
                <td>화</td>
                <td>수</td>
                <td>목</td>
                <td>금</td>
                <td>토</td>
                <td>일</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>업무 시작 시간</th>
                <td>09:00</td>
                <td>09:00</td>
                <td>09:00</td>
                <td>09:00</td>
                <td>09:00</td>
                <td>10:00</td>
                <td>휴무</td>
              </tr>
              <tr>
                <th>휴게 시간</th>
                <td>12:00 ~ 13:00</td>
                <td>12:00 ~ 13:00</td>
                <td>12:00 ~ 13:00</td>
                <td>12:00 ~ 13:00</td>
                <td>12:00 ~ 13:00</td>
                <td>12:00 ~ 13:00</td>
                <td>휴무</td>
              </tr>
              <tr>
                <th>업무 종료 시간</th>
                <td>19:00 </td>
                <td>19:00 </td>
                <td>19:00 </td>
                <td>19:00 </td>
                <td>19:00 </td>
                <td>19:00 </td>
                <td>휴무</td>
              </tr>
            </tbody>
          </table>
        </div>{' '}
        <div className="place-regist-overtime">
          <label htmlFor="overtime">연장 근무</label>
          <input type="radio" id="overtime1" name="overtime" />
          <label htmlFor="overtime1">사용</label>
          <input type="radio" id="overtime2" name="overtime" />
          <label htmlFor="overtime2">비사용</label>
        </div>{' '}
        <div className="place-regist-overtime-allow">
          <label htmlFor="overtime-allow">연장 근무 허용 시간</label>
          <input
            type="text"
            name="overtime-allow"
            id="overtime-allow"
            placeholder="내용을 입력해 주세요."
          />
        </div>{' '}
        <div className="place-regist-manager">
          <label htmlFor="manager">담당자</label>
          <input type="text" placeholder="내용을 입력해 주세요." />
        </div>{' '} */}
              <div className='place-regist-button'>
                <button onClick={handleCreatePlace}>근무지 등록</button>
              </div>
            </TabPane>

            {/* = = */}
            <TabPane tab='파일입력' key='2'>
              <Content maxWidth={1200} backgroundColor='none' padding={0}>
                <br />
                <Row>
                  {/* ===== 업로드 영역 ===== */}
                  <Col span={8}>
                    <Title level={4}>엑셀파일</Title>
                    <div>
                      <XlsxParser
                        customFields={customFields}
                        originalPlaceList={placeList}
                        onChangeLoading={setUploadLoading}
                        onChangeXlsx={(v) => {
                          setXlsxPlaceList(v);
                          // initiate2([
                          //   {
                          //     place_addr:
                          //       '부산시 북구 의성로 47번길 23(덕천동) 수업장소: 강서체육관',
                          //   },
                          // ]);
                        }}
                      />
                      <br />
                      <Button
                        size='large'
                        block
                        type='primary'
                        disabled={
                          uploadLoading === false && xlssPlaceList.length > 0
                            ? false
                            : true
                        }
                        onClick={() => initiate2(xlssPlaceList)}
                      >
                        등록완료
                      </Button>
                    </div>
                  </Col>
                  <Col span={1}></Col>

                  {/* ===== 업로드 파일 목록 ===== */}
                  <Col span={15}>
                    <Title level={4}>
                      근무지목록(신규:
                      {
                        xlssPlaceList.filter((i) => i.place_isAdded === true)
                          .length
                      }{' '}
                      / 전체:{xlssPlaceList.length})
                    </Title>
                    <Title level={5}>라벨지정</Title>
                    <Form
                      layout='inline'
                      style={{ textAlign: 'center', marginBottom: 10 }}
                    >
                      <Form.Item
                        label='No'
                        style={{ width: '24%', marginRight: '1%' }}
                      >
                        <Input
                          name='no'
                          placeholder='번호'
                          value={customFields.no}
                          onChange={(e) =>
                            setCustomFiedls((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value,
                            }))
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label='이름'
                        style={{ width: '24%', marginRight: '1%' }}
                      >
                        <Input
                          name='title'
                          placeholder='근무지명'
                          value={customFields.title}
                          onChange={(e) =>
                            setCustomFiedls((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value,
                            }))
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label='주소'
                        style={{ width: '24%', marginRight: '1%' }}
                      >
                        <Input
                          name='addr'
                          placeholder='주소'
                          value={customFields.addr}
                          onChange={(e) =>
                            setCustomFiedls((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value,
                            }))
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label='연락처'
                        style={{ width: '24%', marginRight: '1%' }}
                      >
                        <Input
                          name='tel'
                          placeholder='전화번호'
                          value={customFields.tel}
                          onChange={(e) =>
                            setCustomFiedls((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value,
                            }))
                          }
                        />
                      </Form.Item>
                    </Form>
                    <div
                      style={{
                        padding: 5,
                        minHeight: 151.5,
                        background: '#f1f1f1',
                        border: '1px solid #dadada',
                        maxHeight: 550,
                        overflowY: 'auto',
                      }}
                    >
                      {uploadLoading === true && (
                        <div
                          style={{
                            position: 'absolute',
                            width: '100%',
                            height: 'calc(100% - 38px)',
                            background: 'rgba(0,0,0, 0.45)',
                            left: 0,
                            top: 38,
                            zIndex: 99,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Title level={5} color={'#fff'}>
                            엑셀 변환 중...
                          </Title>
                        </div>
                      )}
                      <List
                        style={{ background: '#fff' }}
                        bordered
                        dataSource={xlssPlaceList.sort(
                          (a, b) => b.place_isAdded - a.place_isAdded
                        )}
                        renderItem={(item) => (
                          <List.Item
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <div style={{ width: 40 }}>{item.place_seq}</div>
                            <div style={{ width: 'calc(100% - 120px)' }}>
                              {item.place_isAdded ? (
                                <Tag color='green'>신규</Tag>
                              ) : (
                                <Tag color='gray'>기존</Tag>
                              )}
                              {item.place_nm}
                              <br />
                              <span style={{ color: '#d1d1d1' }}>
                                주소: {item.place_addr}
                              </span>
                            </div>

                            <div style={{ width: 80 }}>
                              {item.place_isAdded ? (
                                <Button
                                  type='primary'
                                  block
                                  onClick={() => initiate2([item], true)}
                                >
                                  등록
                                </Button>
                              ) : (
                                <Button type='default' block>
                                  수정
                                </Button>
                              )}
                            </div>
                          </List.Item>
                        )}
                      />
                    </div>
                  </Col>

                  {/* <Col span={14}>
                    <Form {...formItemLayout}>
                      <Form.Item
                        label={<label htmlFor="place_tel">엑셀등록</label>}
                        help="The information is being validated..."
                      >
                        <Form.Item
                          name="dragger"
                          valuePropName="fileList"
                          // getValueFromEvent={normFile}
                          noStyle
                          help="The information is being validated..."
                        >
                          <XlsxParser
                            onChangeXlsx={(v) => {
                              setXlsxPlaceList(v);
                              initiate2();
                            }}
                          />
                        </Form.Item>
                      </Form.Item>
                    </Form>
                  </Col>
                  <Col span={10}>{JSON.stringify(xlssPlaceList)}</Col> */}
                </Row>
              </Content>
            </TabPane>
            {/* =  = */}
            <TabPane tab='좌표입력' key='3'>
              <br />
              <div className='place-regist-name'>
                <label htmlFor='place-name'>근무지명</label>
                <input
                  type='text'
                  name='place_nm'
                  value={placeInfoLoc?.place_nm}
                  onChange={handlePlaceInfoLoc}
                  placeholder='내용을 입력해 주세요.'
                />
              </div>{' '}
              <MapAddressLocation
                placeAddrLoc={placeAddrLoc}
                setPlaceAddrLoc={setPlaceAddrLoc}
                placeInfoLoc={placeInfoLoc}
                setPlaceInfoLoc={setPlaceInfoLoc}
              />
              <div className='place-regist-button'>
                <button onClick={handleCreatePlaceLoc}>근무지 등록</button>
              </div>
            </TabPane>
          </Tabs>
          {/* === TAB === */}
        </div>
      </div>
    </>
  );
};

export default PlaceRegist;
