import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  session: false,
  page_title: '대시보드',
  date: '',
  alertList: [
    // {
    //   id: 1,
    //   type: 'SUCCESS',
    //   title: '테스트 타이틀',
    //   message:
    //     'Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis eveniet modi eos neque placeat dolore ratione nam ex? Esse itaque harum reprehenderit ex ab nulla natus soluta cum ut similique.',
    //   timer: 3000,
    // },
  ],
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setPageTitle(state, action) {
      state.page_title = action.payload;
    },
    addAlert(state, action) {
      const temp = state.alertList.slice();
      temp.push(action.payload);
      state.alertList = temp;
    },
    removeAlert(state, action) {
      const temp = state.alertList.filter((item) => {
        const { id } = item;
        return id !== action.payload;
      });
      state.alertList = temp;
    },
    setDate(state, action) {
      state.date = action.payload;
    },
  },
});

export default commonSlice;
