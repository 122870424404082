import { PlaceApi, UserApi } from 'Api';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import commonSlice from 'Store/reducers/CommonSlice';
import UsersPresenter from './UsersPresenter';

const UsersContainer = () => {
  const dispatch = useDispatch();
  /* ===== Router ===== */

  /* ===== State ===== */
  const [userList, setUserList] = useState([]);
  const [placeList, setPlaceList] = useState([]);

  /* ===== Functions ===== */
  /**
   * 회원 전체 목록 조회
   * --
   */
  const handleUserList = useCallback(async () => {
    const result = await UserApi.getUserlist();
    if (result) {
      setUserList(result);
      return true;
    } else {
      return false;
    }
  }, []);

  /**
   * 회원 등록
   * --
   * @param {Object} userInfo
   * @returns
   */
  const handleCreateUser = async (userInfo) => {
    const result = await UserApi.createUser(userInfo);
    dispatch(commonSlice.actions.setLoading(true));
    if (result) {
      setTimeout(() => {
        dispatch(commonSlice.actions.setLoading(false));
        handleUserList();
      }, 1000);
      return result;
    }
    dispatch(commonSlice.actions.setLoading(false));
    return false;
  };

  /**
   * 회원 상세 정보 조회
   * --
   * @param {String} user_id
   * @returns
   */
  const handleUserDetail = async (user_id) => {
    const result = await UserApi.getUserDetail(user_id);
    dispatch(commonSlice.actions.setLoading(true));
    if (result) {
      setTimeout(() => {
        dispatch(commonSlice.actions.setLoading(false));
      }, 1000);
      return result;
    }
    dispatch(commonSlice.actions.setLoading(false));
    return false;
  };

  /**
   *
   * --
   */
  const handlePlaceList = useCallback(async () => {
    const result = await PlaceApi.getPlceList();
    if (result) {
      setPlaceList(result);
      return true;
    }
    setPlaceList([]);
    return false;
  }, []);

  /**
   * 유저 상태변경
   * --
   */
  const handleChangeUserStatus = (id, value) => {
    const fid = userList.findIndex((user) => user.user_id === id);
    if (fid >= 0) {
      setUserList([
        ...userList.slice(0, fid),
        {
          ...userList[fid],
          user_status: value,
        },
        ...userList.slice(fid + 1),
      ]);
    }
  };

  /**
   * 유저 삭제
   * --
   */
  const handleDeleteUser = (userId) => {
    setUserList((prevState) =>
      prevState.filter((item) => item.user_id !== userId)
    );
  };

  /* ===== Hooks ===== */
  useEffect(() => {
    handleUserList();
  }, [handleUserList]);

  useEffect(() => {
    handlePlaceList();
  }, [handlePlaceList]);

  /* ===== Render ===== */
  return (
    <UsersPresenter
      userList={userList}
      placeList={placeList}
      createUser={handleCreateUser}
      getUserDetail={handleUserDetail}
      onChangeUserStatus={handleChangeUserStatus}
      onDeleteUser={handleDeleteUser}
    />
  );
};

export default UsersContainer;
