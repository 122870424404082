import { AuthApi } from 'Api';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCookie } from 'Utils';
import HomePresenter from './HomePresenter';

const HomeContainer = () => {
  /* Router */
  const navigate = useNavigate();
  /* State */
  const s = getCookie('busad_token');
  /* Functions */
  /**
   * 관리자 로그인 요청
   * --
   * @param {Object} adminInfo
   * @returns
   */
  const handleSignin = async (adminInfo) => {
    const result = await AuthApi.signinAdmin(adminInfo);
    if (result) {
      return result;
    }

    return false;
  };
  /* Hooks */
  useEffect(() => {
    if (s) {
      navigate('/dashboard');
      return false;
    } else {
      return true;
    }
  }, [navigate, s]);

  /* Render */
  return <HomePresenter signin={handleSignin} />;
};

export default HomeContainer;
