import React, { useCallback, useEffect, useState } from 'react';
import './UsersDetail.css';

// fontawesomeicon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCircleUser } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
// import NO_BOX from 'Assets/img/no-data-blue.png';
import UserCalendar from './UserCalendar';
import {
  Typography,
  Table,
  Radio,
  DatePicker,
  Button,
  Tooltip,
  InputNumber,
  Row,
  Col,
  Select,
} from 'antd';
import { USER_STATUS } from 'Utils/TypeManager';
import locale from 'antd/es/date-picker/locale/ko_KR';
import MessageAlert from 'Utils/MessageAlert';
import * as XLSX from 'xlsx/xlsx.mjs';
import { AttendanceApi, VacationApi, UserApi, ScheduleApi } from 'Api';

const { Title } = Typography;

/**
 * Comp
 * --
 */
function UsersDetail2({
  userInfo,
  placeList,
  setModal,
  onDelete,
  onChangeUserStatus,
}) {
  /* ===== Router ===== */

  /* ===== State ===== */
  const [attendanceList, setAttendanceList] = useState();
  const [vacationList, setVacationList] = useState();
  const [vacationInfo, setVacationInfo] = useState(null);
  const [userInfoView, setUserInfoView] = useState(userInfo);
  const [joinDate, setJoinDate] = useState(null);
  const [vacationEditMode, setVacationEditMode] = useState(false);
  const [newVacationCount, setNewVacationCount] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [sortValue, setSortValue] = useState('place');

  // console.log('attendanceList: ', attendanceList);
  // console.log('vacationList: ', vacationList);
  // console.log('vacationInfo: ', vacationInfo);
  // console.log('userInfoView: ', userInfoView);

  const {
    user_id,
    user_nm,
    user_mail,
    // user_agency,
    user_field,
    user_tel,
    created_at,
    modified_at,
    user_place,
    user_status,
    user_joinDate,
  } = userInfoView;

  /* ===== Functions ===== */
  /**
   * 근태이력 조회
   */
  const handleUserAttendance = useCallback(async () => {
    const result = await AttendanceApi.getAttendance(user_id);
    if (result) {
      setAttendanceList(result);
      return true;
    }
    setAttendanceList();
    return false;
  }, [user_id]);

  /**
   * 유저별 일정 정보
   * --
   */
  const handleGetSchedule = useCallback(async () => {
    const { user_id } = userInfo;
    const result = await ScheduleApi.getSchedules(user_id);
    if (!result) {
      MessageAlert.error(
        '일정정보를 불러올 수 없습니다. 페이지를 새로고침 해주세요'
      );
      return true;
    }
    setSchedules(result);
    return false;
  }, [user_id]);

  /**
   * 휴가조회
   * --
   */
  const handleUserVacation = useCallback(async () => {
    const result = await VacationApi.getVacation(user_id);
    const acceptVacationCount = result.vacations.filter(
      (v) => v.vacation_status === 'ACCEPT'
    ).length;
    const waitVacationCount = result.vacations.filter(
      (v) => v.vacation_status === 'PENDING'
    ).length;

    if (result) {
      setVacationList(result);
      setVacationInfo({
        ...result.user_vacation,
        allVacationCount:
          result.user_vacation.assignment_cnt - acceptVacationCount,
        acceptVacationCount,
        waitVacationCount,
      });
      return true;
    }
    setVacationList([]);
    return false;
  }, [user_id]);

  /**
   * 유저 상태변경
   * --
   */
  const handleChangeUserStatus = async (value) => {
    try {
      if (
        !window.confirm(`${USER_STATUS[value].title}상태로 변경하시겠습니까?`)
      ) {
        return;
      }
      const newData = {
        user_id: user_id,
        user_status: value,
      };
      const result = await UserApi.updateUserStatus(newData);
      alert('변경되었습니다.');
      setUserInfoView({
        ...userInfoView,
        user_status: result.newStatus,
      });
      onChangeUserStatus(user_id, result.newStatus);
    } catch (e) {
      console.log('e: ', e);
    }
  };

  /**
   * 입사일 변경감지 함수
   * --
   */
  const onChangeJoinDate = (date, dateString) => {
    // console.log(date, dateString);
    setJoinDate(dateString);
  };

  /**
   * 입사일 변경감지 함수
   * --
   */
  const onSaveJoinDate = async (value) => {
    try {
      if (!joinDate || joinDate.length < 10) {
        MessageAlert.warning('입사일을 확인해주세요');
        return;
      }
      if (!window.confirm(`${joinDate} 날짜로 입사일을 변경하시겠습니까?`)) {
        return;
      }

      // return;
      const newData = {
        user_id: user_id,
        user_joinDate: joinDate,
      };
      const result = await UserApi.updateUserStatus(newData);
      if (!result) {
        MessageAlert.error('다시 시도해주세요');
        return;
      }
      MessageAlert.success('변경되었습니다.');
    } catch (e) {
      console.log('e: ', e);
    }
  };

  /**
   * 유저 완전삭제
   * --
   */
  const handleDeleteUser = async () => {
    try {
      if (
        !window.confirm(
          '경고\n회원정보 삭제 시 회원의 근태,휴가,일정 데이터가 모두 삭제되며 \n복구할 수 없습니다. \n\n이에 동의하며 삭제를 희망하면 확인을 눌러주세요'
        )
      ) {
        return;
      }
      const result = await UserApi.deleteUser(userInfo.user_id);

      if (result) {
        setModal(false);
        onDelete(userInfo.user_id);
        MessageAlert.success('삭제되었습니다');
      } else {
        MessageAlert.error('데이터를 삭제할 수 없습니다. 다시 시도해주세요');
      }
    } catch (error) {
      console.log('[UsersDetail] handleDeleteUser: ', error);
      MessageAlert.error('데이터를 삭제할 수 없습니다. 다시 시도해주세요');
    }
  };

  /**
   * 잔여연차 수 수정
   * --
   */
  const handleModifyVacationCount = async () => {
    try {
      const { assignment_id, assignment_year } = vacationInfo;
      if (newVacationCount === null || newVacationCount < 0) {
        return MessageAlert.warning(
          '수정값을 입력하지 않았거나 범위를 벗어났습니다. 확인 후 다시 시도해주세요'
        );
      }
      const newData = {
        assignment_id,
        assignment_year,
        assignment_cnt: newVacationCount,
      };
      // updateUserVacationCount
      const result = await UserApi.updateUserVacationCount(newData);
      setVacationInfo({
        ...vacationInfo,
        allVacationCount: newVacationCount,
      });

      if (!result) {
        throw 'error';
      }
      setNewVacationCount(null);
      setVacationEditMode(false);
      MessageAlert.success('수정되었습니다.');
    } catch (error) {
      console.log('[handleModifyVacationCount] Error:', error);
      MessageAlert.error('잔여연차를 수정할 수 없습니다. 다시 시도해주세요.');
    }
  };

  /**
   * 엑셀 다운로드
   * --
   */
  const handleDownloadXlsx = () => {
    const sortF =
      sortValue === 'place'
        ? (a, b) =>
            a.place.place_nm < b.place.place_nm
              ? -1
              : a.place.place_nm == b.place.place_nm
              ? 0
              : 1
        : sortValue === 'time'
        ? (a, b) =>
            a.created_at < b.created_at
              ? -1
              : a.created_at == b.created_at
              ? 0
              : 1
        : (a, b) =>
            a.user_nm < b.user_nm ? -1 : a.user_nm == b.user_nm ? 0 : 1;
    const xlsxData = attendanceList.sort(sortF).map((item, idx) => ({
      ['번호']: idx + 1,
      ['지도자']: userInfo.user_nm,
      ['근무지']: `${item.place.place_nm}`,
      ['지역']: `${item.place.place_addr_sigungu}`,
      ['날짜']: moment(item.created_at).format('YYYY-MM-DD'),
      ['시작시간']: moment(item.created_at).format('HH:mm:ss'),
      ['종료시간']: item.attendance_end
        ? moment(item.attendance_end).format('HH:mm:ss')
        : '-',
      ['구분']: item.attendance_status === 'GO_TO_WORK' ? '출근' : '퇴근',
    }));

    const ws = XLSX.utils.json_to_sheet(xlsxData);
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(
      wb,
      `${userInfo.user_nm}_근태기록_${moment().format('YYYYMMDDHHmmss')}.xlsx`
    );
  };

  /* ===== Hooks ===== */
  /**
   * Init
   */
  useEffect(() => {
    handleUserAttendance();
    handleUserVacation();
    handleGetSchedule();
  }, [handleUserAttendance, handleUserVacation]);

  // console.log('attendanceList: ', attendanceList);
  /* ===== RENDER ===== */
  return (
    <div className='users-detail-container'>
      <div
        className='users-detail-contents fade-in-top'
        style={{ marginTop: 0 }}
      >
        <div className='users-detail-contents-top'>
          <Title level={3} style={{ color: '#fff', margin: 0 }}>
            <FontAwesomeIcon
              icon={faCircleUser}
              className='users-detail-CircleUser'
            />
            상세보기
          </Title>
          <div>
            <FontAwesomeIcon
              icon={faXmark}
              className='users-detail-Xmark'
              onClick={setModal}
              style={{ margin: 0 }}
            />
          </div>
        </div>

        <div className='users-detail-contents-inner'>
          {/* = = */}
          <div className='users-detail-info'>
            {/*  */}
            <Title level={4} style={{ color: '#4d77ee' }}>
              기본정보{' '}
              <small style={{ color: '#b1b1b1' }}>
                (최근 수정일: {moment(modified_at).format('llll')} )
              </small>
            </Title>
            {/*  */}
            <div className='user-detail-field'>
              <label htmlFor='call'>이름</label>
              <p>{user_nm}</p>
            </div>
            {/*  */}
            <div className='user-detail-field'>
              <label htmlFor='call'>연락처</label>
              <p>{user_tel}</p>
            </div>
            {/*  */}
            <div className='user-detail-field'>
              <label htmlFor='call'>이메일 </label>
              <p>{user_mail}</p>
            </div>
            {/*  */}
            <div className='user-detail-field'>
              <label htmlFor='call'>부서</label>
              <p>{user_field}</p>
            </div>
            {/*  */}
            {/* <div className="user-detail-field">
              <label htmlFor="call">소속</label>
              <p>{user_agency}</p>
            </div> */}
            {/*  */}
            <div className='user-detail-field'>
              <label htmlFor='call'>입사일</label>

              <div>
                <DatePicker
                  // picker="year"
                  locale={locale}
                  placeholder='입사일을 입력해주세요'
                  style={{ width: 185, zIndex: 999 }}
                  onChange={onChangeJoinDate}
                  {...(user_joinDate && {
                    defaultValue: moment(user_joinDate, 'YYYY-MM-DD'),
                  })}
                />{' '}
                <Button type='primary' onClick={onSaveJoinDate}>
                  저장
                </Button>{' '}
                <Tooltip
                  title='달력에서 연도와 월을 클릭하면 더 빠르게 날짜를 지정할 수 있습니다.'
                  placement='right'
                >
                  <Button type='default' shape='circle' size='small'>
                    ?
                  </Button>
                </Tooltip>
              </div>
            </div>
            {/*  */}
            <div className='user-detail-field'>
              <label htmlFor='call'>가입일</label>
              <p>{moment(created_at).format('llll')}</p>
            </div>
            {/*  */}
            <div className='user-detail-field'>
              <label htmlFor='call'>사용여부</label>
              <p>
                <Radio.Group
                  defaultValue={user_status}
                  value={user_status}
                  buttonStyle='solid'
                  onChange={(e) => handleChangeUserStatus(e.target.value)}
                >
                  {Object.keys(USER_STATUS).map((key) => (
                    <Radio.Button
                      key={USER_STATUS[key].value}
                      value={USER_STATUS[key].value}
                    >
                      {USER_STATUS[key].title}
                    </Radio.Button>
                  ))}
                </Radio.Group>
                <Button
                  style={{
                    // right: 60,
                    // marginTop: 5,
                    // position: 'absolute',
                    marginLeft: 12,
                  }}
                  type='link'
                  danger
                  onClick={handleDeleteUser}
                >
                  삭제
                </Button>
              </p>
            </div>
            {/*  */}
            <div className='user-detail-field'>
              <label htmlFor='call'>잔여연차</label>
              <p>
                {vacationEditMode === false ? (
                  <>
                    {vacationInfo
                      ? `${vacationInfo.allVacationCount}개 (상신:${vacationInfo.waitVacationCount} | 사용:${vacationInfo.acceptVacationCount})`
                      : '-'}
                    <Button
                      type='default'
                      style={{ marginLeft: 5 }}
                      onClick={() => setVacationEditMode(true)}
                    >
                      수정
                    </Button>
                  </>
                ) : (
                  <>
                    {vacationInfo && (
                      <InputNumber
                        defaultValue={vacationInfo.allVacationCount}
                        min={0}
                        max={100}
                        style={{ width: 120 }}
                        onChange={setNewVacationCount}
                      />
                    )}
                    <Button
                      type='default'
                      style={{ marginLeft: 3 }}
                      onClick={() => setVacationEditMode(false)}
                    >
                      취소
                    </Button>
                    <Button
                      type='primary'
                      style={{ marginLeft: 3 }}
                      onClick={handleModifyVacationCount}
                      disabled={newVacationCount === null}
                    >
                      저장
                    </Button>
                  </>
                )}
              </p>
            </div>
            {/*  */}
            <div className='user-detail-field'>
              <label htmlFor='call'>다운로드</label>
              <p style={{ width: 400 }}>
                <Row>
                  <Col span={6}>
                    <Select
                      defaultValue={sortValue}
                      onChange={setSortValue}
                      style={{
                        width: '97%',
                      }}
                    >
                      <Select.Option value={'place'}>근무지순</Select.Option>
                      <Select.Option value={'time'}>시간순</Select.Option>
                      <Select.Option value={'name'}>이름순</Select.Option>
                    </Select>
                  </Col>
                  <Col span={18}>
                    <Button type='default' onClick={handleDownloadXlsx}>
                      엑셀다운로드
                    </Button>
                  </Col>
                </Row>
              </p>
            </div>
          </div>
          <br />
          <br />

          {/* = = */}
          <div style={{ borderBottom: '1px solid #f8f8f8', marginBottom: 20 }}>
            <Title level={4} style={{ color: '#4d77ee' }}>
              소속 근무지
            </Title>
            <Table
              size='small'
              indentSize={5}
              style={{ cursor: 'pointer' }}
              columns={[
                {
                  title: '근무지명',
                  dataIndex: 'place_nm',
                  key: 'place_nm',
                  width: '25%',
                },
                {
                  title: '주소',
                  render: ({ place_addr }) =>
                    `${place_addr.slice(0, 10)}${
                      place_addr.length > 38 ? '...' : ''
                    }`,
                },
                {
                  title: '최근 출근일',
                  width: '20%',
                  render: ({ recently_date }) =>
                    recently_date
                      ? moment(recently_date).format('YYYY-MM-DD')
                      : '-',
                },
              ]}
              dataSource={user_place ? user_place : []}
            ></Table>
          </div>

          <div className='users-detail-attendance'>
            <UserCalendar
              userInfo={userInfo}
              placeList={placeList}
              attendanceList={attendanceList}
              vacationList={vacationList}
              scheduleList={schedules}
              getAttendance={handleUserAttendance}
              getVacation={handleUserVacation}
              getSchedule={handleGetSchedule}
              closePage={setModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UsersDetail2;
