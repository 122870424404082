import React, { useState } from 'react';
import './UsersRegist.css';

// fontawesomeicon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCircleUser } from '@fortawesome/free-solid-svg-icons';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { DatePicker } from 'antd';
import moment from 'moment';

function UsersRegist({ createUser, setModal }) {
  /* Router */
  /* State */
  const initialState = {
    user_nm: '',
    user_mail: '',
    user_pw: '',
    user_agency: '',
    user_field: '',
    user_tel: '',
  };
  const [userInfo, setUserInfo] = useState(initialState);
  /* Functions */
  const handleUserInfo = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const handleCreateUser = async () => {
    const result = await createUser(userInfo);
    if (result) {
      setUserInfo(initialState);
      return true;
    }
    return false;
  };

  /* Hooks */
  /* Render */
  return (
    <div className="users-regist-container">
      <div
        className="users-regist-contents fade-in-top"
        style={{ marginTop: 0, width: 740 }}
      >
        <div className="users-regist-contents-top">
          <div className="users-regist-contents-top-inner">
            <div>
              <FontAwesomeIcon
                icon={faCircleUser}
                className="users-regist-CircleUser"
              />{' '}
              <span style={{ fontSize: '1.5em' }}>회원등록</span>
            </div>
            <FontAwesomeIcon
              icon={faXmark}
              className="users-regist-Xmark"
              onClick={setModal}
            />
          </div>
        </div>{' '}
        {/* users-regist-contents-top end */}
        <div className="users-regist-contents-inner">
          <div className="users-regist-info">
            <h4>기본정보</h4>
            <div
              className="users-regist-info-call"
              style={{ paddingTop: 5, paddingBottom: 5, height: 50 }}
            >
              <label style={{ marginRight: 0, width: 95 }} htmlFor="user_tel">
                이름
              </label>
              <input
                style={{ border: '1px solid #d7d7d7', width: 380 }}
                type="text"
                id="user_nm"
                name="user_nm"
                placeholder="회원명"
                value={userInfo.user_nm}
                onChange={handleUserInfo}
              />
            </div>
            <div
              className="users-regist-info-email"
              style={{ paddingTop: 5, paddingBottom: 5, height: 50 }}
            >
              <label style={{ marginRight: 0, width: 95 }} htmlFor="user_mail">
                이메일
              </label>
              <input
                style={{ border: '1px solid #d7d7d7', width: 380 }}
                type="text"
                id="user_mail"
                name="user_mail"
                placeholder="이메일을 입력해주세요"
                value={userInfo.user_mail}
                onChange={handleUserInfo}
              />
            </div>

            <div
              className="users-regist-info-pw"
              style={{ paddingTop: 5, paddingBottom: 5, height: 50 }}
            >
              <label style={{ marginRight: 0, width: 95 }} htmlFor="user_pw">
                패스워드
              </label>
              <input
                style={{ border: '1px solid #d7d7d7', width: 380 }}
                type="text"
                id="user_pw"
                name="user_pw"
                placeholder="패스워드를 입력해주세요"
                value={userInfo.user_pw}
                onChange={handleUserInfo}
              />
            </div>
            <div
              className="users-regist-info-field"
              style={{ paddingTop: 5, paddingBottom: 5, height: 50 }}
            >
              <label style={{ marginRight: 0, width: 95 }} htmlFor="user_field">
                부서
              </label>
              <input
                style={{ border: '1px solid #d7d7d7', width: 380 }}
                type="text"
                id="user_field"
                name="user_field"
                placeholder="부서를 입력해주세요"
                value={userInfo.user_field}
                onChange={handleUserInfo}
              />
            </div>

            <div
              className="users-regist-info-call"
              style={{ paddingTop: 5, paddingBottom: 5, height: 50 }}
            >
              <label style={{ marginRight: 0, width: 95 }} htmlFor="user_tel">
                연락처
              </label>
              <input
                style={{ border: '1px solid #d7d7d7', width: 380 }}
                type="text"
                id="user_tel"
                name="user_tel"
                placeholder="연락처를 입력해주세요"
                value={userInfo.user_tel}
                onChange={handleUserInfo}
              />
            </div>
            {/* <div className="users-regist-info-belong" style={{paddingTop:, paddingBottom:, height:50,5}}>
              <label style={{marginRight:, width:95}} htmlFor="user_agency">소속</label>
              <input
                type="text"
                id="user_agency"
                name="user_agency"
                value={userInfo.user_agency}
                onChange={handleUserInfo}
              />
            </div> */}
            <div
              className="users-regist-info-belong"
              style={{ paddingTop: 5, paddingBottom: 5, height: 50 }}
            >
              <label
                style={{ marginRight: 0, width: 95 }}
                htmlFor="user_agency"
              >
                입사일
              </label>
              <DatePicker
                // picker="year"
                locale={locale}
                placeholder="입사일을 입력해주세요"
                style={{ width: 185, zIndex: 998 }}
                onChange={(value) =>
                  handleUserInfo({
                    target: {
                      name: 'user_joinDate',
                      value: moment(value).format('YYYY-MM-DD'),
                    },
                  })
                }
                // {...(user_joinDate && {
                //   defaultValue: moment(user_joinDate, 'YYYY-MM-DD'),
                // })}
              />{' '}
            </div>
            {/* <div className="users-regist-info-birth">
              <label htmlFor="call">생년월일</label>
              <input type="text" id="birth" />
            </div> */}
          </div>{' '}
          {/* users-regist-info end */}
          <div className="users-regist-button">
            <button onClick={handleCreateUser}>등록</button>
          </div>
        </div>{' '}
        {/* users-regist-contents-inner end */}
      </div>{' '}
      {/* users-regist-contents end */}
    </div>
  );
}

export default UsersRegist;
