import ApiConstant from 'Api/ApiConstant';
import { ApiManager } from 'Utils';
const $http = new ApiManager();

const VacationApi = {
  /**
   * 연차 정보 등록
   * --
   * @param {Object} createVacationInfo 연차 정보
   * @returns
   */
  createVacation: async (createVacationInfo) => {
    try {
      const url = ApiConstant.CREATE_VACATION;
      const { status, data, message } = await $http.post(
        url,
        createVacationInfo
      );
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },
  /**
   * 연차 정보 다중 등록
   * --
   * @param {Array} createVacationInfo 연차 정보
   * @returns
   */
  createVacationMulti: async (createVacationInfo) => {
    try {
      const url = ApiConstant.CREATE_VACATION_MULTI;
      const { status, data, message } = await $http.post(
        url,
        createVacationInfo
      );
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },

  /**
   * 연차 정보 조회
   * --
   * @param {string} user_id 사용자 교유 번호
   * @returns
   */
  getVacation: async (user_id) => {
    try {
      const url = ApiConstant.GET_VACATION.replace(':user_id', user_id);
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },
  updateVacation: async (updateVacationInfo) => {
    try {
      const url = ApiConstant.UPDATE_VACATION;
      const { status, data, message } = await $http.put(
        url,
        updateVacationInfo
      );
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
};
export default VacationApi;
