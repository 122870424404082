import ApiConstant from 'Api/ApiConstant';
import { ApiManager } from 'Utils';
const $http = new ApiManager();

const UserApi = {
  /**
   * 유저 정보 생성
   * --
   * @param {Object} userInfo
   * @returns
   */
  createUser: async (userInfo) => {
    try {
      const url = ApiConstant.CREATE_USER;
      const { status, data, message } = await $http.post(url, userInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },
  /**
   * 유저상태 변경
   * --
   * @param {Object} userInfo
   * @returns
   */
  updateUserStatus: async (userInfo) => {
    try {
      const url = ApiConstant.UPDATE_USER_STATUS;
      const { status, data, message } = await $http.put(url, userInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },

  /**
   * 유저 잔여연차 변경
   * --
   * @param {Object} userInfo
   * @returns
   */
  updateUserVacationCount: async (newData) => {
    try {
      const url = ApiConstant.UPDATE_USER_VACATION_COUNT;
      const { status } = await $http.put(url, newData);
      if (status !== 200) {
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  },

  /**
   * 유저 전체 목록 조회
   * --
   * @returns
   */
  getUserlist: async () => {
    try {
      const url = ApiConstant.GET_USER_LIST;
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },

  /**
   * 회원 상세 정보 조회
   * --
   * @returns
   */
  getUserDetail: async (user_id) => {
    try {
      const url = ApiConstant.GET_USER_DETAIL.replace(':user_id', user_id);
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },

  /**
   * 회원 삭제
   * --
   * @returns
   */
  deleteUser: async (user_id) => {
    try {
      const url = ApiConstant.DELETE_USER.replace(':user_id', user_id);
      const { status, message } = await $http.delete(url);
      if (status === 200) {
        return true;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },
};
export default UserApi;
