import React from 'react';
import './PlaceCalendarDetail.css';
// fontawesomeicon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import VacationInfo from './VacationInfo';
import ScheduleInfo from './ScheduleInfo';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import commonSlice from 'Store/reducers/CommonSlice';
import { AttendanceApi, VacationApi } from 'Api';
import { AttendanceModify } from 'Route/pages/Users/components';
import { useNavigate } from 'react-router-dom';

/**
 * [Component] 상세보기 모달
 * --
 */
function PlaceCalendarDetail({
  closeModal,
  scheduleDetail,
  userInfo,
  placeList,
  // closePage,
  editMode,
}) {
  const dispatch = useDispatch();
  /* ===== Router ===== */
  const navigate = useNavigate();
  // const location = useLocation();
  // const { pathname, search } = location;

  /* ===== State ===== */
  const { user_nm } = userInfo;
  const { type, data } = scheduleDetail;
  const { raw } = data;
  const { date } = useSelector((state) => state.common);
  const dd = moment(JSON.parse(date)).format('MM - DD (dd)');

  /* ===== Functions ===== */
  // const handleModify = async (data) => {
  //   switch (type) {
  //     case 'ATTENDANCE':
  //       await handleUpdateAttendance(data);
  //       return;
  //     case 'VACATION':
  //       await handleUpdateVacation(data);
  //       return;
  //     case 'SCHEDULE':
  //       await handleUpdateSchedule(data);
  //       return;
  //     default:
  //       return;
  //   }
  // };

  const handleUpdateSchedule = async (schedule) => {
    // console.log(schedule);
  };

  const handleUpdateAttendance = async (attendance) => {
    const result = await AttendanceApi.updateAttendance(attendance);
    if (result) {
      // closePage();
      return true;
    }
    return false;
  };
  /**
   * 연차 수정
   * --
   * @param {*} vacation
   * @returns
   */
  const handleUpdateVacation = async (vacation) => {
    dispatch(commonSlice.actions.setLoading(true));
    const result = await VacationApi.updateVacation(vacation);
    if (result) {
      setTimeout(() => {
        const id = moment().unix();
        const alertData = {
          id: id,
          type: 'SUCCESS',
          title: '수정되었습니다',
          message: '수정되었습니다. 페이지를 다시 로드해주세요.',
        };
        // console.log('${pathname}${search} : ', `${pathname}${search}`);
        // closePage();
        dispatch(commonSlice.actions.addAlert(alertData));
        dispatch(commonSlice.actions.setLoading(false));
        navigate(0);
        // navigate(`${pathname}${search}`, { replace: false });
      }, 1200);
      return true;
    }

    setTimeout(() => {
      const id = moment().unix();
      const alertData = {
        id: id,
        type: 'FAILURE',
        title: '오류가 발생했습니다',
        message: '페이지를 새로고침 해주세요',
      };
      dispatch(commonSlice.actions.addAlert(alertData));
      dispatch(commonSlice.actions.setLoading(false));
    }, 2000);

    return false;
  };

  /**
   *
   * --
   */
  const render = (pageType) => {
    switch (pageType) {
      case 'ATTENDANCE':
        return (
          <AttendanceModify
            attendance={raw}
            placeList={placeList}
            userInfo={userInfo}
            updateAttendance={handleUpdateAttendance}
            closeModal={closeModal}
          />
        );
      case 'VACATION':
        return (
          <VacationInfo
            vacation={data}
            userInfo={userInfo}
            updateVacation={handleUpdateVacation}
            closeModal={closeModal}
          />
        );
      case 'SCHEDULE':
        return (
          <ScheduleInfo
            schedule={data}
            userInfo={userInfo}
            closeModal={closeModal}
            updateSchedule={handleUpdateSchedule}
          />
        );
      default:
        return '';
    }
  };

  /* ===== Hooks ===== */
  // useEffect(() => {
  //   if(editMode.mode !== null){

  //   }
  // }, [editMode]);

  const pageType = editMode && editMode.mode ? editMode.mode : type;

  /* ===== Render ===== */
  return (
    <>
      <div
        id='UserCalendar'
        className='place-calendar-detail-contents-top'
        style={{ borderRadius: 0 }}
      >
        <div className='place-calendar-detail-contents-top-inner'>
          <div>
            <h3 style={{ color: '#fff' }}>{dd}</h3>
          </div>
          <div></div>
          <div>
            <h4 style={{ color: '#fff' }}>{user_nm}</h4>
            <FontAwesomeIcon
              icon={faCircleUser}
              className='place-calendar-detail-CircleUser'
            />
          </div>
        </div>{' '}
      </div>{' '}
      {render(pageType)}
    </>
  );
}

export default PlaceCalendarDetail;
