import ApiConstant from 'Api/ApiConstant';
import { ApiManager } from 'Utils';
const $http = new ApiManager();

const CommunityApi = {
  /**
   * 커뮤니티 등록
   * --
   * @param {*} communityInfo
   * @returns
   */
  createCommunity: async (communityInfo) => {
    try {
      const url = ApiConstant.CREATE_COMMUNITY;
      const { status, data, message } = await $http.post(url, communityInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },
  /**
   * 커뮤니티 목록 조회
   * --
   * @returns
   */
  getCommunity: async () => {
    try {
      const url = ApiConstant.GET_COMMUNITY;
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },

  /**
   * 커뮤니티 정보 수정
   * --
   * @param {*} communityInfo
   * @returns
   */
  updateCommunity: async (communityInfo) => {
    try {
      const url = ApiConstant.UPDATE_COMMUNITY;
      const { status, data, message } = await $http.put(url, communityInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },
  /**
   * 커뮤니티 정보 삭제
   * --
   * @param {*} community_id
   * @returns
   */
  deleteCommunity: async (community_id) => {
    try {
      const url = ApiConstant.DELETE_COMMUNITY.replace(
        ':community_id',
        community_id
      );
      const { status, data, message } = await $http.delete(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  getReply: async (community_id) => {
    try {
      const url = ApiConstant.GET_REPLY.replace(':community_id', community_id);
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
};
export default CommunityApi;
