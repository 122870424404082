import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
// import { BrowserRouter as Router } from 'react-router-dom';
import { HashRouter as Router } from 'react-router-dom';
import App from './App';
import store from './Store';
import 'moment/locale/ko';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import 'styles/Animation.css'; // or 'antd/dist/antd.less'

console.log('------------------------------------------------');
console.log('Deploy Version 1.1.6');
console.log('Deploy Date 2022.06.14 12:00');
console.log('------------------------------------------------');

ReactDOM.render(
  // <React.StrictMode>
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
