import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import RootReducer from './reducers';

// env
const ENV = process.env.NODE_ENV !== 'production';

// middleware
const middlewareArray = ENV ? [logger, thunk] : [thunk];

export default configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewareArray),
  devTools: ENV,
});
