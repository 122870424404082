import React, { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
// import { Link } from 'react-router-dom';
// import NO_BOX from 'Assets/img/no-data-grey.png';
import { Table, Typography } from 'antd';
import './place-table.css';

// const MAX_IDX = 10;
const { Title } = Typography;

/**
 * Comp
 * --
 */
const PlaceTable = ({ data, placeDetail, keyword }) => {
  /* ===== Router ===== */
  /* ===== State ===== */
  // const [, setPage] = useState(1);
  // const total_page = Math.ceil(data.length / MAX_IDX);
  const [currentPagination, setCurrentPagination] = useState(1);

  /* ===== Functions ===== */
  /**
   * 페이징 처리
   * --
   * @param {number} idx
   */
  // const handlePage = (idx) => {
  //   setPage(idx);
  // };

  const handlePlaceDetail = (place_id) => {
    placeDetail(place_id);
  };

  /* ===== Hooks ===== */

  /* ===== Render ===== */
  /**
   * 테이블 요소 출력
   * --
   */
  // const tableRender = data
  //   .slice((page - 1) * MAX_IDX, page * MAX_IDX)
  //   .map((item, idx) => {
  //     const {
  //       place_id,
  //       place_nm,
  //       place_latitude,
  //       place_longitude,
  //       place_addr,
  //       place_addr_detail,
  //       place_cnt,
  //     } = item;
  //     const num = idx + 1;
  //     const addr = `${place_addr} ${place_addr_detail}`;
  //     const addr_length = addr.length;
  //     const renderAddr = addr_length >= 25 ? addr.slice(0, 25) + '...' : addr;
  //     return (
  //       <ul
  //         className="place-contents-bottom-table-content"
  //         key={place_id}
  //         onClick={() => handlePlaceDetail(place_id)}
  //       >
  //         <li>
  //           <div>{num}</div>
  //           <div>{place_nm}</div>
  //           <div style={{ width: '30%' }}>
  //             {place_latitude} / {place_longitude}
  //           </div>
  //           <div style={{ width: '30%' }}>{renderAddr}</div>
  //           <div>{place_cnt}</div>
  //           <div>관리자</div>
  //         </li>
  //       </ul>
  //     );
  //   });

  /**
   * 페이지네이션
   */
  // const paging = [...new Array(total_page)].map((item, idx) => {
  //   const pages = idx + 1;
  //   return (
  //     <Link
  //       to="#"
  //       onClick={() => handlePage(pages)}
  //       className="main-dashboard-content-bottom-pagination-number "
  //     >
  //       {pages}
  //     </Link>
  //   );
  // });

  /* 근무지 목록 필터리스트 */
  const placeList = keyword
    ? data
        .filter((d) => d.place_nm.indexOf(keyword) !== -1)
        .sort((a, b) =>
          a.place_nm < b.place_nm ? 1 : a.place_nm === b.place_nm ? 0 : -1
        )
    : data;

  const onCell = (record, _index) => {
    return {
      onClick: (_) => handlePlaceDetail(record.place_id),
    };
  };

  /* ===== RENDER ===== */
  return (
    <>
      <div className='place-contents-bottom'>
        <Title level={5}>총 {placeList.length}개 근무지</Title>
        <Table
          style={{ cursor: 'pointer' }}
          onChange={({ current }) => setCurrentPagination(current)}
          current={currentPagination}
          columns={[
            {
              width: '10%',
              title: '번호',
              onCell,
              render: (_, __, index) =>
                index + 1 + 10 * (currentPagination - 1),
            },

            {
              width: '30%',
              title: '근무지명',
              dataIndex: 'place_nm',
              key: 'place_nm',
              onCell,
            },

            {
              // width: '34%',
              title: '주소',
              dataIndex: 'place_addr',
              key: 'place_addr',
              onCell,
            },
            {
              width: '15%',
              title: '구/군',
              dataIndex: 'place_addr_sigungu',
              key: 'place_addr_sigungu',
              onCell,
              // render: (text) => <a>{text}</a>,
              // render: (_, __, index) => index + 1,
            },
            // {
            // width:'',
            //   title: '인원',
            //   dataIndex: 'place_cnt',
            //   key: 'place_cnt',
            //   render: (item) => `${item}명`,
            //   onCell,
            // },

            // {
            //   width: '10%',
            //   title: '우편번호',
            //   dataIndex: 'place_addr_code',
            //   key: 'place_addr_code',
            //   onCell,
            // },
          ]}
          dataSource={placeList ? placeList : []}
        />
      </div>
    </>
  );

  // return (
  //   <div className="place-contents-bottom">
  //     <div className="place-contents-bottom-table">
  //       <ul className="place-contents-bottom-table-header">
  //         <li>
  //           <div>번호</div>
  //           <div>근무지명</div>
  //           <div style={{ width: '30%' }}>위도 / 경도</div>
  //           <div style={{ width: '30%' }}>주소</div>
  //           <div>인원</div>
  //           <div>담당자</div>
  //         </li>
  //       </ul>{' '}
  //       {/* Data Render */}
  //       {data.length === 0 && (
  //         <ul className="no-columns">
  //           <img className="no-columns-img" src={NO_BOX} alt="no-columns" />
  //           데이터가 없습니다.
  //         </ul>
  //       )}
  //       {tableRender}
  //     </div>{' '}
  //     {/* place-contents-bottom-table end */}
  //     {total_page !== 0 && (
  //       <div className="main-dashboard-content-bottom-pagination">
  //         <Link to="#" className="pagination-angle-icon-wrap">
  //           <FontAwesomeIcon
  //             icon={faAngleLeft}
  //             className="pagination-angle-icon"
  //           />
  //         </Link>
  //         {paging}
  //         <Link to="#" className="pagination-angle-icon-wrap-next">
  //           <FontAwesomeIcon
  //             icon={faAngleRight}
  //             className="pagination-angle-icon"
  //           />
  //         </Link>
  //       </div>
  //     )}
  //     {/* main-dashboard-content-bottom-pagination end */}
  //   </div>
  // );
};

export default PlaceTable;
