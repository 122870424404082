import React, { useCallback, useEffect, useState } from 'react';
import './schedule-regist.css';

// fontawesomeicon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import TypeSelect from './TypeSelect';
import AttendanceInput from './AttendanceInput';
import VacationInput from './VacationInput';
import ScheduleInput from './ScheduleInput';
import { AttendanceApi, PlaceApi, VacationApi } from 'Api';
import { useSelector } from 'react-redux';

const ScheduleRegist = ({
  scheduleInfo,
  calendarDate,
  closeModal,
  userInfo,
  getAttendance,
  getVacation,
  closePage,
}) => {
  /* Router */
  /* State */
  const { user_nm } = userInfo;
  const { calendar_date } = scheduleInfo;
  const [type, setType] = useState();
  const [placeList, setPlaceList] = useState([]);
  const { date } = useSelector((state) => state.common);
  /* Functions */
  const handlePlaceList = useCallback(async () => {
    const result = await PlaceApi.getPlceList();
    if (result) {
      setPlaceList(result);
      return true;
    }
    setPlaceList([]);
    return false;
  }, []);

  const handleCreateAttendance = async (attendance) => {
    const result = await AttendanceApi.createAttendance(attendance);
    if (result) {
      await getAttendance();
      closePage();
      return true;
    }
    return false;
  };

  const handleCreateVacation = async (vacation) => {
    const result = await VacationApi.createVacation(vacation);
    if (result) {
      await getVacation();
      closePage();
      return true;
    }
    return false;
  };

  const handleCreateVacationMulti = async (vacations) => {
    const result = await VacationApi.createVacationMulti(vacations);
    if (result) {
      await getVacation();
      closePage();
      return true;
    }
    return false;
  };

  const render = () => {
    switch (type) {
      case 'ATTENDANCE':
        return (
          <AttendanceInput
            displayDate={calendar_date}
            closeModal={closeModal}
            placeList={placeList}
            userInfo={userInfo}
            createAttendance={handleCreateAttendance}
          />
        );
      case 'VACATION':
        return (
          <VacationInput
            placeList={placeList}
            displayDate={calendar_date}
            closeModal={closeModal}
            userInfo={userInfo}
            createVacation={handleCreateVacation}
            createVacationMulti={handleCreateVacationMulti}
          />
        );
      case 'SCHEDULE':
        return <ScheduleInput closeModal={closeModal} userInfo={userInfo} />;
      default:
        return (
          <TypeSelect
            close={closeModal}
            handleType={setType}
            userInfo={userInfo}
          />
        );
    }
  };
  /* Hooks */
  useEffect(() => {
    handlePlaceList();
  }, [handlePlaceList]);

  /* Render */
  return (
    <div className="schedule-regist-container">
      <div className="schedule-regist-contents">
        <div className="schedule-regist-contents-top">
          <div className="schedule-regist-contents-top-inner">
            <div>
              <h3>{moment(JSON.parse(date)).format('MM - DD(ddd)')}</h3>
            </div>
            <div>
              <h4>{user_nm}</h4>
              <FontAwesomeIcon
                icon={faCircleUser}
                className="schedule-regist-CircleUser"
              />
            </div>
          </div>
        </div>
        <div className="schedule-regist-contents-inner">{render()}</div>
      </div>
    </div>
  );
};

export default ScheduleRegist;
