import { CommunityApi } from 'Api';
import React, { useCallback, useEffect, useState } from 'react';
import CommunityPresenter from './CommunityPresenter';

const CommunityContainer = () => {
  /* Router */
  /* State */
  const [communityList, setCommunityList] = useState([]);
  /* Functions */
  const handleCommunityList = useCallback(async () => {
    const result = await CommunityApi.getCommunity();
    if (result) {
      setCommunityList(result);
    }
  }, []);

  const handleReply = async (community_id) => {
    const result = await CommunityApi.getReply(community_id);
    if (result) {
      return result;
    }
    return false;
  };

  /* Hooks */
  useEffect(() => {
    handleCommunityList();
  }, [handleCommunityList]);

  /* Render */
  return (
    <CommunityPresenter communityList={communityList} replyList={handleReply} />
  );
};

export default CommunityContainer;
