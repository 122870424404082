import React from 'react';
import { useNavigate } from 'react-router-dom';
import './notfound.css';

const NotFound = () => {
  /* Router */
  const navigate = useNavigate();
  /* State */
  /* Functions */
  const handlePage = () => {
    navigate('/', { replace: true });
  };
  /* Hooks */
  /* Render */
  return (
    <div className="notfound-container">
      <div className="numbers">404</div>
      <div className="desc">페이지를 찾을 수 없습니다.</div>
      <div className="btn" onClick={handlePage}>
        홈페이지로 돌아가기
      </div>
    </div>
  );
};

export default NotFound;
