import { DashboardApi } from 'Api';
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import DashboardPresenter from './DashboardPresenter';

/**
 * [Component] 대시보드 컨테이너
 * --
 */
const DashboardContainer = () => {
  /* ===== Router ===== */

  /* ===== State ===== */
  const [dashboardInfo, setDashboardInfo] = useState([]);
  const [attendanceList, setAttendanceList] = useState([]);

  /* ===== Functions ===== */
  const handleDashboard = useCallback(async () => {
    const result = await DashboardApi.getDashboard();
    if (result) {
      const { dashboard, attendance } = result;
      setAttendanceList(attendance);

      const dd = Object.keys(dashboard).map((item) => {
        const temp = {
          status: item,
          value: dashboard[item],
        };
        return temp;
      });

      setDashboardInfo(dd);
      return true;
    }
    return false;
  }, []);

  /* ===== Hooks ===== */
  useEffect(() => {
    handleDashboard();
  }, [handleDashboard]);

  /* ===== Render ===== */
  return (
    <DashboardPresenter
      attendanceList={attendanceList}
      dashboardInfo={dashboardInfo}
    />
  );
};

export default DashboardContainer;
