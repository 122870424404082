import React from 'react';
// const styles = {
//   label: {
//     width: 65,
//   },
//   input: {
//     width: 'calc(100% - 68px)',
//   },
// };

const ScheduleInput = ({ closeModal }) => {
  return (
    <div>
      <div className='schedule-regist-info'>
        {/* <div>
          <p>연차 구분</p>
          <select name="attendance_type">
            <option value="0">연차 구분 선택</option>
          </select>
        </div> */}
        <div>
          <p>일정 시작</p>
          <input
            type='datetime-local'
            name='vacation_end_date'
            placeholder='내용을 입력해주세요.'
          />
        </div>
        <div>
          <p>일정 종료</p>
          <input
            type='datetime-local'
            name='vacation_end_time'
            placeholder='내용을 입력해주세요.'
          />
        </div>
        <div>
          <p>일정 제목</p>
          <input
            type='text'
            name='vacation_end_time'
            placeholder='내용을 입력해주세요.'
          />
        </div>
        <div>
          <p>일정 내용</p>
          <textarea
            name='vacation_content'
            cols='30'
            rows='5'
            placeholder='내용을 입력해주세요.'
          ></textarea>
        </div>
      </div>
      <div className='schedule-regist-button'>
        <button className='cancel-btn' onClick={closeModal}>
          닫기
        </button>
        <button className='regist-btn'>등록</button>
      </div>
    </div>
  );
};

export default ScheduleInput;
