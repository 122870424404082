import moment from 'moment';
import React from 'react';
import { TypeManager } from 'Utils';
const VACATION_TYPE = TypeManager.getVacationList();
const VACATION_STATUS = TypeManager.getVacationStatusList();

const styles = {
  label: {
    width: 65,
  },
  input: {
    width: 'calc(100% - 68px)',
  },
};

/**
 * [Component] 연차 정보 확인(상세보기)
 * --
 */
const VacationInfo = ({ vacation, updateVacation, closeModal }) => {
  /* ===== Router ===== */
  /* ===== State ===== */
  const { raw } = vacation;
  const {
    vacation_id,
    vacation_start_date,
    vacation_start_time,
    vacation_end_date,
    vacation_end_time,
    vacation_content,
    vacation_status,
    vacation_type,
    user_vacation,
    vacation_cnt,
  } = raw;
  const { assignment_cnt } = user_vacation;

  /* ===== Functions ===== */

  /* ===== Hooks ===== */

  /* ===== Variables ===== */
  const options = VACATION_TYPE.map((item) => {
    const { id, title, value } = item;
    return (
      <option value={value} key={id}>
        {title}
      </option>
    );
  });

  const handleUpdate = async (val) => {
    const postData = {
      vacation_id,
      vacation_status: val,
    };
    const result = await updateVacation(postData);
    if (result) {
      return true;
    }
    return false;
  };

  const statusRender = VACATION_STATUS.map((item) => {
    const { title, value, id } = item;
    const active = vacation_status === value ? 'active' : '';
    return (
      <span key={id} className={active} onClick={() => handleUpdate(value)}>
        {/* <span key={id} className={active} onClick={() => handleUpdate(value)}> */}
        {title}
      </span>
    );
  });

  /* ===== Render ===== */
  return (
    <>
      <div className="place-calendar-detail-info">
        <div>
          <p style={styles.label}>연차번호</p>
          <p style={styles.input}>{vacation_id}</p>
        </div>
        <div>
          <p style={styles.label}>잔여연차</p>
          <p style={styles.input}>
            {vacation_cnt}({assignment_cnt})
          </p>
        </div>
        <div>
          <p style={styles.label}>기간</p>
          <p style={styles.input}>
            {moment(vacation_start_date).format('ll')} {vacation_start_time} ~{' '}
            {moment(vacation_end_date).format('ll')} {vacation_end_time}
          </p>
        </div>
        <div>
          <p style={styles.label}>구분</p>
          <p style={styles.input}>
            <select
              name="place-calendar-detail"
              id="place-calendar-detail"
              value={vacation_type}
            >
              {options}
            </select>
          </p>
        </div>
        <div>
          <p style={styles.label}>사유</p>
          <p style={styles.input}>
            <input
              type="text"
              placeholder="내용을 입력해주세요."
              readOnly
              value={vacation_content}
              style={{ width: '100%' }}
            />
          </p>
        </div>
        <div>
          <p style={styles.label}>상태</p>
          {statusRender}
        </div>
        <div className="place-calendar-detail-contents-inner">
          <div className="place-calendar-detail-button">
            <button className="close-btn" onClick={closeModal}>
              닫기
            </button>
            <button onClick={handleUpdate}>수정</button>
          </div>
        </div>{' '}
      </div>
    </>
  );
};

export default VacationInfo;
