import { combineReducers } from 'redux';
import commonSlice from './CommonSlice';
import userReducer from './UsersSlice';

const RootReducer = combineReducers({
  user: userReducer.reducer,
  common: commonSlice.reducer,
});

export default RootReducer;
