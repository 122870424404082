import React from 'react';
import { Outlet } from 'react-router-dom';
import './css/home.css';

const HomeLayout = () => {
  /* Router */
  /* State */
  /* Hooks */
  /* Functions */
  /* Render */
  return (
    <div className="homelayout-container">
      <Outlet />
    </div>
  );
};

export default HomeLayout;
