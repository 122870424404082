/**
 *
 *
 */

import './place.css';
import React, { useState } from 'react';
// fontawesomeicon
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { PlaceTable } from './components';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'antd';
const { Search } = Input;

/**
 * Presenter
 * --
 */
const PlacePresenter = ({ placeList }) => {
  /* ===== Router ===== */
  const navigate = useNavigate();

  /* ===== State ===== */
  const [keyword, setKeyword] = useState('');
  const [keyword2, setKeyword2] = useState('');

  /* ===== Functions ===== */
  /**
   * 근무지 등록으로 이동
   * --
   */
  const handleCreate = () => {
    navigate('/place/regist');
  };

  /**
   * 근무지 상세 보기 페이지로 이동
   * --
   * @param {String} place_id
   */
  const handlePlaceDetail = async (place_id) => {
    navigate(`/place/detail/${place_id}`);
  };

  /**
   * 키워드 검색
   */
  const onSearch = () => {
    setKeyword2(keyword);
  };

  /* ===== Hooks ===== */
  /* ===== Render ===== */
  return (
    <div className="place-container">
      <div className="place-contents">
        <div className="place-contents-top">
          <div>
            <Search
              size="large"
              placeholder="검색할 근무지명을 입력해주세요"
              onSearch={onSearch}
              onChange={(e) => setKeyword(e.target.value)}
              style={{ width: 330 }}
              value={keyword}
            />
            <Button
              size="large"
              disabled={keyword2 === '' ? true : false}
              onClick={() => {
                setKeyword('');
                setKeyword2('');
              }}
            >
              초기화
            </Button>
          </div>
          <div>
            <Button type="primary" size="large" onClick={handleCreate}>
              근무지 등록
            </Button>
          </div>
          {/* place-contents-top-button end */}
        </div>
        {/* place-contents-top end */}
        <PlaceTable
          keyword={keyword2}
          data={placeList}
          placeDetail={handlePlaceDetail}
        />
        {/* place-contents-bottom end */}
      </div>
      {/* place-contents end */}
    </div>
  ); /* place-container end */
};

PlacePresenter.defaultProps = {
  placeList: [...new Array(11)],
};

export default PlacePresenter;
