import React from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import {
  faCircleCheck,
  faCircleXmark,
  faCircleQuestion,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MessageBox = ({ id, type, title, message, close }) => {
  /* Router */
  /* State */
  const className =
    type === 'SUCCESS' ? 'blue' : type === 'WARNING' ? 'yellow' : 'red';

  const iconObj =
    type === 'SUCCESS'
      ? faCircleCheck
      : type === 'WARNING'
      ? faCircleQuestion
      : faCircleXmark;

  const iconClass =
    type === 'SUCCESS'
      ? 'message-box-faCircleCheck'
      : type === 'WARNING'
      ? 'message-box-faCircleQuestion'
      : 'message-box-faCircleXmark';
  /* Functions */
  const onClose = () => {
    close(id);
  };
  /* Hooks */
  /* Render */

  // class:blue, icon: Check
  // class:red, icon: Xmark
  // class yellow, icon: question
  return (
    <div className={`message-box message-box-${className}`}>
      <div className="message-header">
        <FontAwesomeIcon icon={iconObj} className={`type-icon ${iconClass}`} />
        <h4 className="message-title">{title}</h4>
        <FontAwesomeIcon
          icon={faXmark}
          className="message-close message-box-faXmark"
          onClick={onClose}
        />
      </div>
      <div className="message-content">{message}</div>
    </div>
  );
};

export default MessageBox;
