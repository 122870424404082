import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { TypeManager } from 'Utils';

const ATTENDANCE_TYPE = TypeManager.getAttendanceList();

const AttendanceInput = ({
  userInfo,
  displayDate,
  placeList,
  closeModal,
  createAttendance,
}) => {
  /* Router */
  /* State */
  const { date } = useSelector((state) => state.common);
  const initialState = {
    user_id: userInfo.user_id,
    place_id: '',
    attendance_date: moment(JSON.parse(date)).format('YYYY-MM-DD'),
    attendance_time: '00:00',
    attendance_status: '',
  };
  const [attendanceInfo, setAttendanceInfo] = useState(initialState);

  /* Functions */
  const handleAttendanceInfo = (e) => {
    setAttendanceInfo({ ...attendanceInfo, [e.target.name]: e.target.value });
  };
  const handleAttendanceType = (e) => {
    if (e.target.value === 0) {
      return;
    }
    handleAttendanceInfo(e);
  };

  const handleCreateAttendance = async () => {
    const postData = {
      ...attendanceInfo,
      attendance_start: `${attendanceInfo.attendance_date} ${attendanceInfo.attendance_time}`,
    };

    const result = await createAttendance(postData);
    if (result) {
      closeModal();
      return result;
    }

    return false;
  };

  const handleAttendancePlace = (e) => {
    if (e.target.value === 0) {
      return;
    }
    handleAttendanceInfo(e);
  };

  const handleAttendanceDate = (e) => {
    setAttendanceInfo({ ...attendanceInfo, attendance_date: e.target.value });
  };

  const handleAttendanceTime = (e) => {
    setAttendanceInfo({ ...attendanceInfo, attendance_time: e.target.value });
  };

  /* Hooks */
  /* Render */
  const attendanceRender = ATTENDANCE_TYPE.map((item) => {
    const { id, title, value } = item;
    return (
      <option value={value} key={id}>
        {title}
      </option>
    );
  });

  const placeRender = placeList.map((item) => {
    const { place_id, place_nm } = item;
    return (
      <option value={place_id} key={place_id}>
        {place_nm}
      </option>
    );
  });
  return (
    <div>
      <div className="schedule-regist-contents-inner">
        <div className="schedule-regist-info">
          <div>
            <p>근무지 명</p>
            <select name="place_id" onChange={handleAttendancePlace}>
              <option value="0">근무지 선택</option>
              {placeRender}
            </select>
          </div>
          <div>
            <p>근태 구분</p>
            <select name="attendance_status" onChange={handleAttendanceType}>
              <option value="0">근태 구분 선택</option>
              {attendanceRender}
            </select>
          </div>
          <div>
            <p>근태 일자</p>
            <input
              type="date"
              name="attendance_date"
              placeholder="내용을 입력해주세요."
              value={attendanceInfo.attendance_date}
              onChange={handleAttendanceDate}
            />
          </div>
          <div>
            <p>근태 시간</p>
            <input
              type="time"
              name="attendance_time"
              placeholder="내용을 입력해주세요."
              value={attendanceInfo.attendance_time}
              onChange={handleAttendanceTime}
            />
          </div>
        </div>
        <div className="schedule-regist-button">
          <button className="cancel-btn" onClick={closeModal}>
            닫기
          </button>
          <button className="regist-btn" onClick={handleCreateAttendance}>
            등록
          </button>
        </div>
      </div>
    </div>
  );
};

export default AttendanceInput;
