import React from 'react';
import './css/loading.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Loading = ({ loading }) => {
  return loading ? (
    <div className="global-loading-container">
      <FontAwesomeIcon className="loading-spinner" icon={faSpinner} />
      <div className="loading-text">페이지를 로드중입니다.</div>
    </div>
  ) : (
    ''
  );
};

export default Loading;
