import React from 'react';
import './CommunityRegist.css';

// fontawesomeicon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { getCookie, readBuffer } from 'Utils';

const NoticeRegist = ({ regist, close }) => {
  /* Router */
  /* State */
  const initialState = {
    notice_title: '',
    notice_content: '',
  };
  const [community, setCommunity] = useState(initialState);

  const s = JSON.parse(getCookie('admin_user'));

  /* Functions */
  const handleCommunity = (e) => {
    setCommunity({ ...community, [e.target.name]: e.target.value });
  };

  const handleRegist = async () => {
    const postData = {
      ...community,
      admin_id: s.admin_id,
      is_file: false,
    };
    const result = await regist(postData);
    if (result) {
      setCommunity(initialState);
      close();
      return true;
    }
    return false;
  };

  const handleClose = () => {
    setCommunity(initialState);
    close();
  };

  /* Hooks */
  /* Render */
  return (
    <div className="community-regist-container">
      <div className="community-regist-contents">
        <div className="community-regist-contents-top">
          <select name="board-type" id="board-type">
            <option value="community-board-type">공지사항</option>
            <option value="community-board-type-notice-1" disabled>
              커뮤니티
            </option>
          </select>
          <FontAwesomeIcon
            icon={faXmark}
            className="Xmark"
            onClick={handleClose}
          />
        </div>{' '}
        {/* community-regist-contents-top end */}
        <div className="community-regist-contents-bottom">
          <div>
            <select name="board-type" id="board-type" disabled>
              <option value="community-board-writer">
                {readBuffer(s.admin_nm)}
              </option>
              <option value="community-board-writer-adm-1">관리자1</option>
              <option value="community-board-writer-adm-2">관리자2</option>
            </select>
            <input
              type="text"
              name="notice_title"
              id="community-board-title-id"
              placeholder="글 제목"
              value={community.notice_title}
              onChange={handleCommunity}
            />
          </div>
          <textarea
            name="notice_content"
            id="community-board-textarea-id"
            cols="30"
            rows="10"
            placeholder="내용을 입력해 주세요."
            value={community.notice_content}
            onChange={handleCommunity}
          ></textarea>
        </div>{' '}
        {/* community-regist-contents-bottom end */}
        <div className="community-regist-contents-button">
          <button onClick={handleRegist}>등록</button>
        </div>{' '}
        {/* community-regist-contents-button */}
      </div>{' '}
      {/* community-regist-contents end */}
    </div>
  ); /* community-regist-container end */
};

export default NoticeRegist;
