import React, { useState } from 'react';
import './css/config-page.css';

const ConfigPresenter = ({ signupAdmin }) => {
  /* Router */
  /* State */
  const initialState = {
    admin_nm: '',
    admin_mail: '',
    admin_pw: '',
    admin_div: 'ADMIN',
  };
  const [adminInfo, setAdminInfo] = useState(initialState);
  /* Functions */
  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignupAdmin();
  };

  const handleAdminInfo = (e) => {
    setAdminInfo({ ...adminInfo, [e.target.name]: e.target.value });
  };

  const handleSignupAdmin = async () => {
    const result = await signupAdmin(adminInfo);
    if (result) {
      setAdminInfo(initialState);
      return true;
    }
    return false;
  };

  /* Hooks */
  /* Render */
  return (
    <div className="config-container">
      <h2>관리자 세팅</h2>
      <form onSubmit={handleSubmit} className="config-form">
        <div className="config-info">
          <div className="config-label">관리자 메일: </div>
          <input
            type="text"
            className="config-input"
            name="admin_mail"
            value={adminInfo.admin_mail}
            placeholder="관리자 메일"
            onChange={handleAdminInfo}
          />
        </div>
        <div className="config-info">
          <div className="config-label">관리자 이름: </div>
          <input
            type="text"
            className="config-input"
            name="admin_nm"
            value={adminInfo.admin_nm}
            placeholder="관리자 이름"
            onChange={handleAdminInfo}
          />
        </div>
        <div className="config-info">
          <div className="config-label">관리자 비밀번호: </div>
          <input
            type="text"
            className="config-input"
            name="admin_pw"
            value={adminInfo.admin_pw}
            placeholder="관리자 비밀번호"
            onChange={handleAdminInfo}
          />
        </div>
        <div className="config-info">
          <div className="config-label">관리자 구분: </div>
          <input
            type="text"
            className="config-input"
            value="통합 관리자"
            readOnly
            disabled
          />
        </div>
        <div className="config-info">
          <button
            type="submit"
            className="config-submit"
            onClick={handleSubmit}
          >
            등록
          </button>
        </div>
      </form>
    </div>
  );
};

export default ConfigPresenter;
