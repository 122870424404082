import ApiConstant from 'Api/ApiConstant';
import { ApiManager } from 'Utils';
const $http = new ApiManager();

const AttendanceApi = {
  /**
   * 근태 등록
   * @param {Object} createAttendanceInfo
   * @returns
   */
  createAttendance: async (createAttendanceInfo) => {
    try {
      alert(1);
      const url = ApiConstant.CREATE_ATTENDANCE;
      const { status, data, message } = await $http.post(
        url,
        createAttendanceInfo
      );
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },
  /**
   * 근태 목록 조회
   * --
   * @param {string} user_id 사용자 고유 번호
   * @returns
   */
  getAttendance: async (user_id) => {
    try {
      const url = ApiConstant.GET_ATTENDANCE.replace(':user_id', user_id);
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },
  /**
   * 근태정보 수정
   * --
   * @param {*} attednanceInfo
   * @returns
   */
  updateAttendance: async (attednanceInfo) => {
    try {
      const url = ApiConstant.UPDATE_ATTENDANCE;
      const { status, data, message } = await $http.put(url, attednanceInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },
};
export default AttendanceApi;
