import React from 'react';
import './PlaceCalendarRegist.css';

// fontawesomeicon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';

function PlaceCalendarRegist() {
  return (
    <div className="place-calendar-regist-container">
      <div className="place-calendar-regist-contents">
        <div className="place-calendar-regist-contents-top">
          <div className="place-calendar-regist-contents-top-inner">
            <div>
              <h3>01 - 25 (화)</h3>
            </div>
            <div>
              <h4>김성훈</h4>
              <FontAwesomeIcon
                icon={faCircleUser}
                className="place-calendar-regist-CircleUser"
              />
            </div>
          </div>{' '}
          {/* place-calendar-regist-contents-top-inner end */}
        </div>{' '}
        {/* place-calendar-regist-contents-top end */}
        <div className="place-calendar-regist-contents-inner">
          <div className="place-calendar-regist-info">
            <div>
              <p>잔여 연차</p>
              <p>15(2)</p>
            </div>
            <div>
              <p>휴가 구분</p>
              <select name="place-calendar-regist" id="place-calendar-regist">
                <option value="place-calendar-regist">종일 휴가</option>
                <option value="place-calendar-regist">종일 휴가</option>
                <option value="place-calendar-regist">종일 휴가</option>
              </select>
            </div>
            <div>
              <p>휴가 사유</p>
              <input type="text" placeholder="내용을 입력해주세요." />
            </div>
          </div>
          <div className="place-calendar-regist-button">
            <button>등록</button>
          </div>
        </div>{' '}
        {/* place-calendar-regist-contents-inner end */}
      </div>
    </div>
  ); /* place-calendar-regist-container end */
}

export default PlaceCalendarRegist;
