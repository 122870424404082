const DASHBOARD_STATUS = [
  {
    id: 0,
    value: 'TOTAL',
    title: '전체',
  },
  {
    id: 1,
    value: 'NORMAL',
    title: '정상',
  },
  {
    id: 2,
    value: 'LATE',
    title: '지각',
  },
  {
    id: 3,
    value: 'VACATION',
    title: '연차',
  },
  {
    id: 4,
    value: 'UNPROCESSED',
    title: '미처리',
  },
];

const ATTENDANCE_TYPE = [
  {
    id: 1,
    value: 'GO_TO_WORK',
    title: '시작',
    bgColor: '#DAE6FE',
    borderColor: '#556FDF',
  },
  {
    id: 2,
    value: 'LEAVE_TO_WORK',
    title: '종료',
    bgColor: '#FEDAE3',
    borderColor: '#DF5576',
  },
  {
    id: 3,
    value: 'UNPROCESSED',
    title: '미처리',
    bgColor: '',
    borderColor: '',
  },
];

const VACATION_TYPE = [
  {
    id: 1,
    value: 'ALL_DAY',
    title: '연차',
  },
  {
    id: 2,
    value: 'HALF',
    title: '반차',
  },
  // {
  //   id: 3,
  //   value: 'HALF_AM',
  //   title: '오전반차',
  // },
  // {
  //   id: 4,
  //   value: 'HALF_PM',
  //   title: '오후반차',
  // },
];

export const VACATION_STATUS_NEW = {
  PENDING: {
    id: 1,
    value: 'PENDING',
    title: '대기',
    bgColor: '#DAE6FE',
    borderColor: '#DF5576',
  },
  ACCEPT: {
    id: 2,
    value: 'ACCEPT',
    title: '승인',
    bgColor: '#DAE6FE',
    borderColor: '#556FDF',
  },
  REJECT: {
    id: 3,
    value: 'REJECT',
    title: '반려',
    bgColor: '#DAE6FE',
    borderColor: '#DF5576',
  },
  CANCEL: {
    id: 4,
    value: 'CANCEL',
    title: '취소',
    bgColor: '#DAE6FE',
    borderColor: '#DF5576',
  },
};

const VACATION_STATUS = [
  {
    id: 1,
    value: 'PENDING',
    title: '대기',
    bgColor: '#DAE6FE',
    borderColor: '#DF5576',
  },
  {
    id: 2,
    value: 'ACCEPT',
    title: '승인',
    bgColor: '#DAE6FE',
    borderColor: '#556FDF',
  },
  {
    id: 3,
    value: 'REJECT',
    title: '반려',
    bgColor: '#DAE6FE',
    borderColor: '#DF5576',
  },
  {
    id: 4,
    value: 'CANCEL',
    title: '취소',
    bgColor: '#DAE6FE',
    borderColor: '#DF5576',
  },
];

// bgColor: '#FEDAE3',
//         borderColor: '#DF5576',

// bgColor: '#FEF4DA',
// borderColor: '#DFA855',

const PAGE_TITLE = [
  {
    id: 0,
    value: '/dashboard',
    title: '대시보드',
  },
  {
    id: 1,
    value: '/place',
    title: '근무지 목록',
  },
  {
    id: '1-1',
    value: '/place/regist',
    title: '근무지 등록',
  },
  {
    id: '1-2',
    value: '/place/detail/',
    title: '근무지 상세',
  },
  {
    id: 2,
    value: '/users',
    title: '회원 관리',
  },
  {
    id: 3,
    value: '/notice',
    title: '공지사항 관리',
  },
  {
    id: 4,
    value: '/community',
    title: '커뮤니티 관리',
  },
];

export const PLACE_TYPE = {
  DELETE: {
    value: 'DELETE',
    title: '삭제',
    color: 'volcano',
  },
  TEST: {
    value: 'TEST',
    title: '테스트',
    color: 'purple',
  },
  PUBLIC: {
    value: 'PUBLIC',
    title: '사용중',
    color: 'green',
  },
};

export const USER_STATUS = {
  WAIT: {
    value: 'WAIT',
    title: '승인대기',
    color: 'volcano',
  },
  TESTER: {
    value: 'TESTER',
    title: '테스트계정',
    color: 'purple',
  },
  ACCESS: {
    value: 'ACCESS',
    title: '승인',
    color: 'green',
  },
};

const TypeManager = {
  getDashboardStatusList: () => {
    return DASHBOARD_STATUS;
  },
  getDashboardStatus: (val) => {
    const [temp] = DASHBOARD_STATUS.filter((item) => {
      const { value } = item;
      return value === val;
    });
    return temp;
  },

  getPageTitleList: () => {
    return PAGE_TITLE;
  },

  getPageTitle: (val) => {
    const [temp] = PAGE_TITLE.filter((item) => {
      const { value } = item;
      return value.includes(val);
    });
    if (temp) {
      return temp;
    }
    return { id: -1, value: undefined, title: '-' };
  },

  getAttendanceList: () => {
    return ATTENDANCE_TYPE;
  },

  getAttendance: (val) => {
    const [temp] = ATTENDANCE_TYPE.filter((item) => {
      const { value } = item;
      return value === val;
    });
    return temp;
  },

  getVacationList: () => {
    return VACATION_TYPE;
  },

  getVacation: (val) => {
    const [temp] = VACATION_TYPE.filter((item) => {
      const { value } = item;
      return value === val;
    });
    return temp;
  },

  getVacationStatusList: () => {
    return VACATION_STATUS;
  },
  getVacationStatus: (val) => {
    const [temp] = VACATION_STATUS.filter((item) => {
      const { value } = item;
      return value === val;
    });
    return temp;
  },
};

export default TypeManager;
