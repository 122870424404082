import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import commonSlice from 'Store/reducers/CommonSlice';
import './css/alert.css';
import MessageBox from './MessageBox';

const Alert = () => {
  const dispatch = useDispatch();
  /* Router */
  /* State */
  const { alertList } = useSelector((state) => state.common);
  /* Functions */
  /**
   * Alert 닫기
   * --
   * @param {Number} id
   */
  const close = (id) => {
    dispatch(commonSlice.actions.removeAlert(id));
  };
  /* Hooks */
  /* Render */
  const renderList = alertList.map((item, idx) => {
    const { id, title, message, type } = item;
    return (
      <MessageBox
        id={id}
        type={type}
        message={message}
        title={title}
        key={id}
        close={close}
      />
    );
  });

  // class:blue, icon: Check
  // class:red, icon: Xmark
  // class yellow, icon: question
  return <div className="alert-container">{renderList}</div>;
};

export default Alert;
