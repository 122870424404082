import { AuthApi } from 'Api';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCookie } from 'Utils';
import ConfigPresenter from './ConfigPresenter';

const ConfigContainer = () => {
  /* Router */
  const navigate = useNavigate();
  const s = getCookie('busad_token');
  /* State */
  /* Functions */

  /**
   * 회원가입 가능 여부 체크
   * --
   */
  const checkAdmin = useCallback(async () => {
    if (!s) {
      navigate('/');
      return false;
    }
    const result = await AuthApi.adminCheck();
    // console.log('result: ', result);
    if (result) {
      const { check } = result;
      if (!check) {
        navigate('/');
      }
      return true;
    }
    return false;
  }, [s, navigate]);

  /**
   * 관리자 회원가입
   * --
   */
  const handleSignupAdmin = async (adminInfo) => {
    const result = await AuthApi.signupAdmin(adminInfo);
    if (result) {
      navigate('/');
      return true;
    }
    return false;
  };

  /* Hooks */
  useEffect(() => {
    checkAdmin();
  }, [checkAdmin]);

  /* Render */
  return <ConfigPresenter signupAdmin={handleSignupAdmin} />;
};

export default ConfigContainer;
