/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import Calendar from 'tui-calendar';
import './test.css';
import 'tui-calendar/dist/tui-calendar.css';
import moment from 'moment';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalLayout } from 'Components';
import { PlaceCalendarDetail } from 'Route/pages/Place/components';
import ScheduleRegist from './ScheduleRegist';
import { useDispatch } from 'react-redux';
import commonSlice from 'Store/reducers/CommonSlice';
import { TypeManager } from 'Utils';

const AtdCalendar = ({
  attendanceList,
  vacationList,
  userInfo,
  placeList,
  getVacation,
  getAttendance,
  closePage,
}) => {
  const calendarRef = useRef();
  const dispatch = useDispatch();
  /* Router */
  /* State */
  const [calendar, setCalendar] = useState(null);
  const [calendarDate, setCalendarDate] = useState();
  const [toggle, setToggle] = useState(false);

  const [createModal, setCreateModal] = useState(false);
  const initialState = {
    calendar_date: '',
  };
  const [scheduleInfo, setScheduleInfo] = useState(initialState);

  const [detailModal, setDetailModal] = useState(false);
  const detailState = {
    type: '',
    data: {},
    userInfo: userInfo,
  };
  const [scheduleDetail, setScheduleDetail] = useState(detailState);

  /* Functions */
  const handleDetailModal = () => {
    setDetailModal(!detailModal);
  };

  const openDetailModal = (obj) => {
    const { schedule } = obj;
    const { raw, start } = schedule;
    const { type } = raw;
    dispatch(commonSlice.actions.setDate(JSON.stringify(start._date)));
    setScheduleDetail({
      ...scheduleDetail,
      type: type,
      data: schedule,
      userInfo: userInfo,
    });
    handleDetailModal();
  };

  const handleCreateModal = () => {
    setCreateModal(!createModal);
  };

  const openCreateModal = () => {
    handleCreateModal();
  };

  const closeCreateModal = () => {
    setScheduleInfo(initialState);
    handleCreateModal();
  };

  const closeDetailModal = () => {
    setScheduleDetail(detailState);
    handleDetailModal();
  };

  const handleUpdateVacation = () => {};

  /**
   * 캘린더 상세 모달
   * --
   * @returns
   */
  const initCalendar = () => {
    if (calendar) {
      return;
    }

    const d = new Date();
    setCalendarDate(d);

    var cal = new Calendar(calendarRef.current, {
      defaultView: 'month',
      taskView: true,
      month: {
        daynames: ['일', '월', '화', '수', '목', '금', '토'],
        // isAlways6Week: false,
        startDayOfWeek: 0,
        narrowWeekend: true,
      },
      template: {
        allday: function (schedule) {
          return `<span style='line-height: 2;height: 100%;'>${schedule.title} ${schedule.body}</span>`;
        },
        alldayTitle: function () {
          return 'All Day';
        },
      },
    });

    cal.on({
      clickSchedule: function (e) {
        openDetailModal(e);
      },
      beforeCreateSchedule: function (e) {
        var triggerEventName = e.triggerEventName;

        if (triggerEventName === 'click' || triggerEventName === 'mouseup') {
          const d = JSON.stringify(moment(e.start._date));
          dispatch(commonSlice.actions.setDate(d));
          openCreateModal(e.start);
        }

        e.guide.clearGuideElement();
      },
    });
    setCalendar(cal);
  };

  const handlePrev = () => {
    if (!calendar) {
      return;
    }
    calendar.prev();
    setCalendarDate(moment(calendarDate).subtract(1, 'M'));
  };

  const handleNext = () => {
    if (!calendar) {
      return;
    }
    calendar.next();
    setCalendarDate(moment(calendarDate).add(1, 'M'));
  };

  const handleCreateSchedule = (schedules) => {
    calendar.createSchedules(schedules);
  };
  const initialAttedance = () => {
    const schedules = attendanceList.map((item) => {
      const {
        attendance_id,
        attendance_end,
        attendance_status,
        attendance_start,
      } = item;

      const body = `${moment(attendance_start).format('HH:mm')}${
        attendance_end ? ` - ${moment(attendance_end).format('HH:mm')}` : ''
      }`;
      const { title } = TypeManager.getAttendance(attendance_status);
      return {
        id: attendance_id,
        calendarId: attendance_id,
        title: title,
        category: 'allday',
        body: `(${body})`,
        bgColor: '#FEDAE3',
        borderColor: '#DF5576',
        start: attendance_start,
        end: attendance_start,
        raw: { ...item, type: 'ATTENDANCE' },
      };
    });

    handleCreateSchedule(schedules);
  };

  const initialVacation = () => {
    const { vacations, user_vacation, vacation_cnt } = vacationList;
    const schedules = vacations.map((item) => {
      const {
        vacation_id,
        vacation_start_date,
        vacation_start_time,
        vacation_type,
        vacation_status,
      } = item;
      const dd = `${moment(vacation_start_date).format(
        'YYYY-MM-DD'
      )}T${vacation_start_time}:00+09:00`;

      const {
        bgColor,
        borderColor,
        title: body,
      } = TypeManager.getVacationStatus(vacation_status);

      const { title } = TypeManager.getVacation(vacation_type);

      return {
        id: vacation_id,
        calendarId: vacation_id,
        title: title,
        body: body,
        category: 'allday',
        bgColor: bgColor,
        borderColor: borderColor,
        start: dd,
        end: dd,
        raw: { ...item, type: 'VACATION', user_vacation, vacation_cnt },
      };
    });
    handleCreateSchedule(schedules);
  };

  /* Hooks */
  useEffect(() => {
    initCalendar();
  });

  useEffect(() => {
    if (!calendar || !attendanceList || !vacationList) {
      return;
    }
    if (toggle) {
      return;
    }
    initialAttedance();
    initialVacation();
    setToggle(true);
  }, [calendar, attendanceList, vacationList]);

  /* Render */
  return (
    <div className="calendar-container">
      <div className="calendar-top">
        <div className="calendar-top-title">
          <FontAwesomeIcon
            icon={faAngleLeft}
            className="user-calendar-faAngleLeft"
            onClick={handlePrev}
          />
          <h3 onClick={handleCreateSchedule}>
            {moment(calendarDate).format('YYYY.MM')}
          </h3>
          <FontAwesomeIcon
            icon={faAngleRight}
            className="user-calendar-faAngleRight"
            onClick={handleNext}
          />
        </div>
      </div>
      <div
        className="calendar-content"
        ref={calendarRef}
        style={{ height: '800px' }}
      ></div>
      <ModalLayout custom modal={detailModal} setModal={handleDetailModal}>
        <PlaceCalendarDetail
          scheduleDetail={scheduleDetail}
          placeList={placeList}
          closeModal={closeDetailModal}
          userInfo={userInfo}
          closePage={closePage}
        />
      </ModalLayout>
      <ModalLayout custom modal={createModal} setModal={handleCreateModal}>
        <ScheduleRegist
          userInfo={userInfo}
          scheduleInfo={scheduleInfo}
          calendarDate={calendarDate}
          closeModal={closeCreateModal}
          getAttendance={getAttendance}
          getVacation={getVacation}
          closePage={closePage}
        />
      </ModalLayout>
    </div>
  );
};

AtdCalendar.defaultProps = {
  attendanceList: undefined,
  vacationList: undefined,
  scheduleList: [],
};

export default AtdCalendar;
