import { PlaceApi } from 'Api';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import commonSlice from 'Store/reducers/CommonSlice';
import PlacePresenter from './PlacePresenter';

const PlaceContainer = () => {
  const dispatch = useDispatch();
  /* Router */
  /* State */
  const [placeList, setPlaceList] = useState([]);
  /* Functions */
  /**
   * 근무지 전체 목록 조회
   * --
   */
  const handlePlaceList = useCallback(async () => {
    const result = await PlaceApi.getPlceList();
    if (result) {
      setPlaceList(result);
      return true;
    }
    setPlaceList([]);
    return false;
  }, []);

  const handlePlaceDetail = async (place_id) => {
    const result = await PlaceApi.getPlaceDetail(place_id);
    dispatch(commonSlice.actions.setLoading(true));
    if (result) {
      setTimeout(() => {
        dispatch(commonSlice.actions.setLoading(false));
      }, 1000);
      return result;
    }
    dispatch(commonSlice.actions.setLoading(false));
    return false;
  };

  /* Hooks */
  useEffect(() => {
    handlePlaceList();
  }, [handlePlaceList]);

  /* Render */
  return (
    <PlacePresenter placeList={placeList} placeDetail={handlePlaceDetail} />
  );
};

export default PlaceContainer;
