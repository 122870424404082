import React from 'react';
import './css/modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
const ModalLayout = ({
  children,
  modal,
  setModal,
  width = '1080px',
  height = '500px',
  title,
  close = true,
  padding,
  custom = false,
}) => {
  /* Router */
  /* State */
  /* Functions */
  /* Hooks */
  /* Render */
  return modal ? (
    <div className="modal-layout-container">
      {custom ? (
        children
      ) : (
        <div className="modal-content" style={{ width, height }}>
          <div className="title">
            <div className="title-content">
              {title ? title : 'Modal'}
              {close && (
                <div className="modal-close-btn" onClick={setModal}>
                  <FontAwesomeIcon
                    icon={faX}
                    className="place-calendar-detail-CircleUser"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="modal-body" style={{ padding }}>
            {children}
          </div>
        </div>
      )}
    </div>
  ) : (
    ''
  );
};

ModalLayout.defaultProps = {
  modal: true,
  setModal: () => {},
};

export default ModalLayout;
