import React from 'react';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import dashboard_icon from 'Assets/img/dashboard-icon.png';
import moment from 'moment';
// import { TypeManager } from 'Utils';
import { useNavigate } from 'react-router-dom';
import { Typography, Card, Col, Row, Statistic, Button } from 'antd';
// import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

const { Title } = Typography;

/**
 * DashboardPreview
 * --
 */
const DashboardPreview = ({ statics }) => {
  /* ===== Router ===== */
  const navigate = useNavigate();
  /* ===== State ===== */
  const dateStatus = moment().format('YYYY년 MM월');

  /* ===== Functions ===== */
  const handlePlace = () => {
    navigate('/place');
  };
  /* ===== Hooks ===== */
  /* ===== Render ===== */
  return (
    <>
      <div className='main-dashboard-content-bottom'>
        <br />
        {/* Header */}
        <Title level={3}>
          {dateStatus} 근태 현황{' '}
          <Button shape='round' type='primary' onClick={handlePlace}>
            근무지별 근태 현황 보러가기
            <FontAwesomeIcon icon={faAngleRight} className='angle-icon' />
          </Button>
        </Title>
        <br />

        {/* Static data */}
        <div className='main-dashboard-content-bottom-table'>
          <div className='site-statistic-demo-card'>
            <Row gutter={16}>
              {/* = = */}
              <Col span={6}>
                <Card>
                  <Statistic title={'전체'} value={statics.total} suffix='건' />
                </Card>
              </Col>
              {/* = = */}
              <Col span={6}>
                <Card>
                  <Statistic
                    title={'정상'}
                    value={statics.complete}
                    suffix='건'
                  />
                </Card>
              </Col>
              {/* = = */}
              <Col span={6}>
                <Card>
                  <Statistic
                    title={'진행중'}
                    value={statics.inprogress}
                    suffix='건'
                  />
                </Card>
              </Col>
              {/* = = */}
              <Col span={6}>
                <Card>
                  <Statistic
                    title={'근무이상'}
                    value={statics.err}
                    suffix='건'
                  />
                </Card>
              </Col>

              {/* {dashboard_status &&
                dashboard_status
                  .filter((item) => item.status !== 'UNPROCESSED')
                  .map((item, idx) => {
                    const { status, value } = item;
                    const { title } = TypeManager.getDashboardStatus(status);
                    return (
                      <Col span={6} key={`dashboard_static_${idx}`}>
                        <Card>
                          <Statistic title={title} value={value} suffix="건" />
                        </Card>
                      </Col>
                    );
                  })} */}
            </Row>
          </div>
        </div>
        <br />
      </div>
    </>
  );
};

DashboardPreview.defaultProps = {
  dashboard_status: [],
  // dashboard_status: DASHBOARD_STATUS.map((item) => {
  //   return {
  //     ...item,
  //     status_value: Math.floor(Math.random() * (100 - 0 + 1) + 0),
  //   };
  // }),
};

export default DashboardPreview;
