import React from 'react';
import AppPresenter from './AppPresenter';

const AppContainer = () => {
  /* Router */
  /* State */
  /* Hooks */
  /* Functions */
  /* Render */
  return <AppPresenter />;
};

export default AppContainer;
