import React, { useCallback, useEffect, useState } from 'react';
import './schedule-regist.css';

// fontawesomeicon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import TypeSelect from './TypeSelect';
import AttendanceInput from './AttendanceInput';
import VacationInput from './VacationInput';
import ScheduleInput from './ScheduleInput';
import { AttendanceApi, PlaceApi, VacationApi, ScheduleApi } from 'Api';
import { useSelector } from 'react-redux';
import MessageAlert from 'Utils/MessageAlert';
import { useNavigate } from 'react-router-dom';

const ScheduleRegist = ({
  scheduleInfo,
  // calendarDate,
  closeModal,
  userInfo,
  getAttendance,
  getVacation,
  // closePage,
}) => {
  /* Router */
  const navi = useNavigate();
  /* State */
  const { user_nm } = userInfo;
  const { calendar_date } = scheduleInfo;
  const [type, setType] = useState(null);
  const [placeList, setPlaceList] = useState([]);
  const { date } = useSelector((state) => state.common);
  /* Functions */
  const handlePlaceList = useCallback(async () => {
    const result = await PlaceApi.getPlceList();
    if (result) {
      setPlaceList(result);
      return true;
    }
    setPlaceList([]);
    return false;
  }, []);

  /**
   * 근태등록
   * --
   */
  const handleCreateAttendance = async (attendance) => {
    const result = await AttendanceApi.createAttendance(attendance);
    if (result) {
      await getAttendance();
      // closePage();
      navi(0);
      return true;
    }
    return false;
  };

  /**
   * 일정등록
   * --
   */
  const handleCreateSchedule = async (newData) => {
    const result = await ScheduleApi.create(newData);
    if (!result) {
      MessageAlert.error('일정을 등록할 수 없습니다. 다시 시도해주세요');
      return false;
    }
    handleCloseModal(false);
    navi(0);
  };
  /**
   * 휴가등록
   * --
   */
  const handleCreateVacation = async (vacation) => {
    const result = await VacationApi.createVacation(vacation);
    if (result) {
      await getVacation();
      // closePage();
      navi(0);
      return true;
    }
    return false;
  };

  /**
   * 휴가 연속 등록
   * --
   */
  const handleCreateVacationMulti = async (vacations) => {
    const result = await VacationApi.createVacationMulti(vacations);
    if (result) {
      await getVacation();
      // closePage();
      navi(0);
      return true;
    }
    return false;
  };

  /**
   *
   */
  const handleCloseModal = (type = true) => {
    if (type === false) setType(null);
    closeModal();
  };

  const render = () => {
    switch (type) {
      case 'ATTENDANCE':
        return (
          <AttendanceInput
            displayDate={calendar_date}
            closeModal={handleCloseModal}
            placeList={placeList}
            userInfo={userInfo}
            createAttendance={handleCreateAttendance}
          />
        );
      case 'VACATION':
        return (
          <VacationInput
            placeList={placeList}
            displayDate={calendar_date}
            closeModal={handleCloseModal}
            userInfo={userInfo}
            createVacation={handleCreateVacation}
            createVacationMulti={handleCreateVacationMulti}
          />
        );
      case 'SCHEDULE':
        return (
          <ScheduleInput
            closeModal={handleCloseModal}
            userInfo={userInfo}
            onCreateSchedule={handleCreateSchedule}
            currentDate={JSON.parse(date)}
          />
        );
      default:
        return (
          <TypeSelect
            close={handleCloseModal}
            handleType={setType}
            userInfo={userInfo}
            // onClear={}
          />
        );
    }
  };
  /* Hooks */
  useEffect(() => {
    handlePlaceList();
  }, [handlePlaceList]);

  /* ===== Render ===== */
  return (
    <>
      <div className='schedule-regist-contents-top' style={{ borderRadius: 0 }}>
        <div className='schedule-regist-contents-top-inner'>
          <div>
            <h3 style={{ color: '#fff' }}>
              {moment(JSON.parse(date)).format('MM - DD(ddd)')}
            </h3>
          </div>
          <div>
            <h4 style={{ color: '#fff' }}>{user_nm}</h4>
            <FontAwesomeIcon
              icon={faCircleUser}
              className='schedule-regist-CircleUser'
            />
          </div>
        </div>
      </div>
      <div className='schedule-regist-contents-inner'>{render()}</div>
    </>
  );

  // return (
  //   <div className="schedule-regist-container">
  //     <div className="schedule-regist-contents">
  //       <div className="schedule-regist-contents-top">
  //         <div className="schedule-regist-contents-top-inner">
  //           <div>
  //             <h3>{moment(JSON.parse(date)).format('MM - DD(ddd)')}</h3>
  //           </div>
  //           <div>
  //             <h4>{user_nm}</h4>
  //             <FontAwesomeIcon
  //               icon={faCircleUser}
  //               className="schedule-regist-CircleUser"
  //             />
  //           </div>
  //         </div>
  //       </div>
  //       <div className="schedule-regist-contents-inner">{render()}</div>
  //     </div>
  //   </div>
  // );
};

export default ScheduleRegist;
