import React from 'react';
import './CommunityRegist.css';

// fontawesomeicon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const CommunityDetail = ({ community, close }) => {
  /* Router */
  /* State */
  const { user_nm, community_title, community_content, reply } = community;

  console.log(reply);
  /* Functions */
  /* Hooks */
  /* Render */
  const replyRender =
    reply &&
    reply.map((item) => {
      const { reply_id, user_nm, reply_content, created_at } = item;
      const dd = moment(created_at).format('YYYY-MM-DD HH:mm');
      return (
        <div className="reploy_box" key={reply_id}>
          <div className="reply_user">
            {user_nm} <span className="reply_tiem">{dd}</span>
          </div>
          <div className="reply_content">{reply_content}</div>
        </div>
      );
    });
  return (
    <div className="community-regist-container">
      <div className="community-regist-contents">
        <div className="community-regist-contents-top">
          <select name="board-type" id="board-type">
            <option value="community-board-type">커뮤니티</option>
            <option value="community-board-type-notice-1" disabled>
              공지사항
            </option>
          </select>
          <FontAwesomeIcon icon={faXmark} className="Xmark" onClick={close} />
        </div>{' '}
        {/* community-regist-contents-top end */}
        <div className="community-regist-contents-bottom">
          <div>
            <select name="board-type" id="board-type" disabled>
              <option value="community-board-writer">{user_nm}</option>
            </select>
            <input
              type="text"
              name="notice_title"
              id="community-board-title-id"
              disabled
              placeholder="글 제목"
              value={community_title}
            />
          </div>
          <div className="community_content">{community_content}</div>
          <div className="reply_head">댓글</div>
          {!reply && (
            <div className="reploy_content_empty">댓글이 없습니다.</div>
          )}
          {replyRender}
          {/* <textarea
            name="notice_content"
            id="community-board-textarea-id"
            cols="30"
            rows="10"
            placeholder="내용을 입력해 주세요."
          ></textarea> */}
        </div>{' '}
        {/* community-regist-contents-bottom end */}
        {/* <div className="community-regist-contents-button">
          <button onClick={handleRegist}>등록</button>
        </div>{' '} */}
        {/* community-regist-contents-button */}
      </div>{' '}
      {/* community-regist-contents end */}
    </div>
  ); /* community-regist-container end */
};

export default CommunityDetail;
