import ApiConstant from 'Api/ApiConstant';
import { ApiManager, parameterToPath } from 'Utils';
const $http = new ApiManager();

const PlaceApi = {
  /**
   * 근무지 등록
   * --
   * @param {Object} placeInfo
   * @returns
   */
  createPlace: async (placeInfo) => {
    try {
      const url = ApiConstant.CREATE_PLACE;
      const { status, data, message } = await $http.post(url, placeInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },

  /**
   * 근무지 수정
   * --
   * @param {Object} placeInfo
   * @returns
   */
  updatePlace: async (placeInfo) => {
    try {
      const url = ApiConstant.UPDATE_PLACE;
      const { status, data, message } = await $http.put(url, placeInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },

  /**
   * 근무지 삭제
   * --
   * @param {string} place_id
   * @returns
   */
  deletePlace: async (place_id) => {
    try {
      const url = parameterToPath(ApiConstant.DELETE_PLACE, { place_id });
      const { status, data, message } = await $http.delete(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },

  /**
   * 근무지 등록
   * --
   * @param {Object} places
   * @returns
   */
  createPlaceBulk: (places) =>
    $http.post(ApiConstant.CREATE_PLACE_BULK, places),

  /**
   * 근무지 전체 목록 조회
   * --
   * @returns
   */
  getPlceList: async () => {
    try {
      const url = ApiConstant.GET_PLACE_LIST;
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },

  /**
   * 근무지 상세 정보 조회
   * --
   * @param {String} place_id
   * @returns
   */
  getPlaceDetail: async (place_id) => {
    try {
      const url = ApiConstant.GET_PLACE_DETAIL.replace(':place_id', place_id);
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },
};
export default PlaceApi;
