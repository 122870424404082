import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
// import { Link } from 'react-router-dom';
import moment from 'moment';
import { TypeManager } from 'Utils';
import { Typography, Table } from 'antd';

const { Title } = Typography;
// const { Search } = Input;

// const MAX_IDX = 10;

const DashboardRecently = ({ data }) => {
  /* ===== Router ===== */
  /* ===== State ===== */
  // const [page, setPage] = useState(1);
  // const total_page = Math.ceil(data.length / MAX_IDX);

  /* ===== Functions ===== */
  /**
   * 페이징 처리
   * --
   * @param {number} idx
   */
  // const handlePage = (idx) => {
  //   setPage(idx);
  // };

  /**
   * 상세보기
   * --
   * @param {number} id
   */
  // const handleDetail = (id) => {
  //   // console.log(id);
  // };

  /**
   *
   */
  const onCell = (_index) => {
    return {
      // onClick: (_) => handleUserDetail(record.user_id),
      onClick: (_) => {},
    };
  };

  /* ===== Hooks ===== */
  /* ===== Render ===== */
  /**
   * 테이블 요소 출력
   * --
   */
  // const render = data
  //   .slice((page - 1) * MAX_IDX, page * MAX_IDX)
  //   .map((item, idx) => {
  //     const {
  //       id,
  //       user,
  //       place,
  //       attendance_start,
  //       attendance_end,
  //       attendance_status,
  //     } = item;
  //     const { user_nm } = user;
  //     const { place_nm } = place;
  //     const d = attendance_end
  //       ? moment(attendance_end).format('YYYY-MM-DD HH:mm')
  //       : moment(attendance_start).format('YYYY-MM-DD HH:mm');

  //     const { title } = TypeManager.getAttendance(attendance_status);
  //     return (
  //       <ul className='main-dashboard-content-bottom-table-content' key={idx}>
  //         <li onClick={() => handleDetail(id)}>
  //           <div>{idx}</div>
  //           <div>{user_nm}</div>
  //           <div>{place_nm}</div>
  //           <div>{title}</div>
  //           <div>{d}</div>
  //         </li>
  //       </ul>
  //     );
  //   });

  // const paging = [...new Array(total_page)].map((item, idx) => {
  //   return (
  //     <Link
  //       onClick={() => handlePage(idx + 1)}
  //       to='#'
  //       className='main-dashboard-content-bottom-pagination-number'
  //       key={idx}
  //     >
  //       {idx + 1}
  //     </Link>
  //   );
  // });

  /* ===== RENDER ===== */
  return (
    <>
      <div className='main-dashboard-content-bottom'>
        <Title level={3}>최근 근태 기록 ({data.length})</Title>
        <div className='main-dashboard-content-bottom-table'>
          <Table
            style={{ cursor: 'pointer' }}
            columns={[
              {
                title: '사용자명',
                render: (item) => item.user.user_nm,
                onCell,
              },
              {
                title: '근무지명',
                render: (item) => item.place.place_nm,
                onCell,
              },
              {
                title: '근태유형',
                render: (item) => {
                  const { title } = TypeManager.getAttendance(
                    item.attendance_status
                  );
                  return title;
                },
                onCell,
              },
              {
                title: '근태일자',
                render: (item) => {
                  const d = item.attendance_end
                    ? moment(item.attendance_end).format('YYYY-MM-DD HH:mm')
                    : moment(item.attendance_start).format('YYYY-MM-DD HH:mm');
                  return d;
                },
                onCell,
              },
            ]}
            dataSource={data ? data : []}
          ></Table>
          {/* <table></table> */}
        </div>{' '}
        {/* main-dashboard-content-bottom-table end */}
        {/* <div className="main-dashboard-content-bottom-pagination">
          <Link to="#" className="pagination-angle-icon-wrap">
            <FontAwesomeIcon
              icon={faAngleLeft}
              className="pagination-angle-icon"
            />
          </Link>
          {paging}
          <Link to="#" className="pagination-angle-icon-wrap-next">
            <FontAwesomeIcon
              icon={faAngleRight}
              className="pagination-angle-icon"
            />
          </Link>
        </div>{' '} */}
        {/* main-dashboard-content-bottom-pagination end */}
      </div>
    </>
  );
};

DashboardRecently.defaultProps = {
  data: [...new Array(Math.floor(Math.random() * (100 - 9 + 1) + 9))].map(
    (item, idx) => ({
      id: idx,
      name: `test${idx}`,
    })
  ),
};

export default DashboardRecently;
