import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { TypeManager } from 'Utils';
import { TimePicker, DatePicker, Col, Row, Typography, Select } from 'antd';
import { Content } from 'Components';

const { Title } = Typography;
const ATTENDANCE_TYPE = TypeManager.getAttendanceList();
// const styles = {
//   label: { width: 60, height: 30, margin: '0 15px 0 0' },
//   value: { background: 'blue', height: 30, padding: 0, margin: 0 },
// };

const AttendanceInput = ({
  userInfo,
  displayDate,
  placeList,
  closeModal,
  createAttendance,
}) => {
  /* ===== Router ===== */

  /* ===== State ===== */
  const { date } = useSelector((state) => state.common);
  const initialState = {
    user_id: userInfo.user_id,
    place_id: '',
    attendance_date: moment(JSON.parse(date)).format('YYYY-MM-DD'),
    attendance_time: '00:00',
    attendance_status: '',
  };
  const [attendanceInfo, setAttendanceInfo] = useState(initialState);

  /* ===== Functions ===== */
  /**
   *
   */
  const handleCreateAttendance = async () => {
    const postData = {
      ...attendanceInfo,
      attendance_start: `${attendanceInfo.attendance_date} ${attendanceInfo.attendance_time}`,
    };

    const result = await createAttendance(postData);
    if (result) {
      closeModal();
      return result;
    }

    return false;
  };

  /**
   *
   */
  const handleChangeValue = (name, value) => {
    if (value === 0) {
      return;
    }
    setAttendanceInfo({
      ...attendanceInfo,
      [name]: value,
    });
  };

  /**
   *
   */
  const handleAttendanceDate = (_date, dateString) => {
    setAttendanceInfo({ ...attendanceInfo, attendance_date: dateString });
  };

  const handleAttendanceTime = (_time, timeString) => {
    setAttendanceInfo({ ...attendanceInfo, attendance_time: timeString });
  };

  /* ===== Hooks ===== */

  /* ===== Render ===== */
  const attendanceRender = ATTENDANCE_TYPE.map((item) => {
    const { id, title, value } = item;
    return (
      <Select.Option value={value} key={id}>
        {title}
      </Select.Option>
    );
  });

  const placeRender = placeList
    .sort((a, b) =>
      a.place_nm < b.place_nm ? -1 : a.place_nm > b.place_nm ? 1 : 0
    )
    .map((item) => {
      const { place_id, place_nm } = item;
      return (
        <Select.Option value={place_id} key={place_id}>
          {place_nm}
        </Select.Option>
      );
    });

  // console.log('[AttendanceInput] attendanceInfo: ', attendanceInfo);

  /* ===== Render ===== */
  return (
    <div>
      <Content>
        <Row>
          {/* === 근무지명 === */}
          <Col span={4}>
            <Title level={5} style={{ padding: '3.5px 0' }}>
              근무지
            </Title>
          </Col>
          <Col span={20}>
            <Select
              style={{ width: '100%' }}
              onChange={(value) => handleChangeValue('place_id', value)}
              placeholder="근무지를 선택해주세요"
            >
              {placeRender}
            </Select>
          </Col>
          {/* === 근태구분 === */}
          <Col span={4}>
            <Title level={5} style={{ padding: '3.5px 0' }}>
              구분
            </Title>
          </Col>
          <Col span={20}>
            <Select
              style={{ width: '100%' }}
              onChange={(value) =>
                handleChangeValue('attendance_status', value)
              }
              placeholder="근태 구분을 선택해주세요"
            >
              {attendanceRender}
            </Select>
          </Col>
          {/* === 근태일자 === */}
          <Col span={4}>
            <Title level={5} style={{ padding: '3.5px 0' }}>
              날짜
            </Title>
          </Col>
          <Col span={7}>
            <DatePicker
              style={{ width: '100%' }}
              onChange={handleAttendanceDate}
            />
          </Col>
          {/* === 근태시간 === */}
          <Col span={2} />
          <Col span={3}>
            <Title level={5} style={{ padding: '3.5px 0' }}>
              시간
            </Title>
          </Col>
          <Col span={8}>
            <TimePicker
              style={{ width: '100%' }}
              onChange={handleAttendanceTime}
              format="HH:mm"
              defaultOpenValue={moment('00:00', 'HH:mm')}
            />
          </Col>
        </Row>
      </Content>

      <div className="schedule-regist-contents-inner">
        <div className="schedule-regist-button">
          <button className="cancel-btn" onClick={() => closeModal(false)}>
            닫기
          </button>
          <button className="regist-btn" onClick={handleCreateAttendance}>
            등록
          </button>
        </div>
      </div>
    </div>
  );
};

export default AttendanceInput;
