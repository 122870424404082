import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';

const options = {
  //지도를 생성할 때 필요한 기본 옵션
  center: new window.kakao.maps.LatLng(33.450701, 126.570667), //지도의 중심좌표.
  level: 3, //지도의 레벨(확대, 축소 정도)
};

const MapAddress = ({ placeInfo, setPlaceInfo, placeAddr, setPlaceAddr }) => {
  const container = useRef(null); //지도를 담을 영역의 DOM 레퍼런스

  /* Router */
  /* State */
  const [daumPostCode, setDaumPostCode] = useState(null);

  /* Functions */
  const handleError = (error) => {
    // console.log(error);
  };

  /**
   * 근무지 정보 입력
   * --
   * @param {KeyboardEvent} e
   */
  const handlePlaceInfo = (e) => {
    let value = e.target.value;
    const regex = /^[0-9.]*$/;
    if (e.target.name === 'place_distance' && !regex.test(value)) {
      return;
    }
    setPlaceInfo({ ...placeInfo, [e.target.name]: value });
  };

  const openPostCodePopup = () => {
    if (daumPostCode) {
      setPlaceAddr(null);
      daumPostCode.open();
    }
  };

  const initiate = (e) => {
    window.daum.postcode.load(() => {
      setDaumPostCode(
        new window.daum.Postcode({
          oncomplete: (data) => {
            // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.
            const { sido, sigungu, zonecode, roadname, address, bname } = data;

            // 지도를 생성합니다
            var map = new window.kakao.maps.Map(container.current, options);

            // 주소-좌표 변환 객체를 생성합니다
            var geocoder = new window.kakao.maps.services.Geocoder();

            // 주소로 좌표를 검색합니다
            geocoder.addressSearch(address, function (result, status) {
              // 정상적으로 검색이 완료됐으면
              if (status === window.kakao.maps.services.Status.OK) {
                var coords = new window.kakao.maps.LatLng(
                  result[0].y,
                  result[0].x
                );
                setPlaceAddr({
                  place_addr: address,
                  place_addr_bname: bname,
                  place_addr_code: zonecode,
                  place_addr_roadname: roadname,
                  place_addr_sido: sido,
                  place_addr_sigungu: sigungu,
                  place_latitude: result[0].y,
                  place_longitude: result[0].x,
                });

                // 결과값으로 받은 위치를 마커로 표시합니다
                var marker = new window.kakao.maps.Marker({
                  map: map,
                  position: coords,
                });

                // 인포윈도우로 장소에 대한 설명을 표시합니다
                var infowindow = new window.kakao.maps.InfoWindow({
                  content: `<div style="width:150px;text-align:center;padding:6px 0;">${address}</div>`,
                });
                infowindow.open(map, marker);

                // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
                map.setCenter(coords);
              }
            });
          },
        })
      );
    });
  };

  /* Hooks */
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
    script.onload = (e) => initiate(e);
    script.onerror = (error) => handleError(error);
    script.id = 'daum-post-code-api';
    document.body.appendChild(script);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      // GeoLocation을 이용해서 접속 위치를 얻어옵니다
      navigator.geolocation.getCurrentPosition(function (position) {
        var lat = position.coords.latitude, // 위도
          lon = position.coords.longitude; // 경도
        var locPosition = new window.kakao.maps.LatLng(lat, lon);
        var map = new window.kakao.maps.Map(container.current, options);
        var marker = new window.kakao.maps.Marker({
          map: map,
          position: locPosition,
        });

        var iwContent =
          '<div style="width:150px;text-align:center;padding:6px 0;">현재 위치</div>'; // 인포윈도우에 표시할 내용

        // 인포윈도우를 생성합니다
        var infowindow = new window.kakao.maps.InfoWindow({
          content: iwContent,
        });

        // 인포윈도우를 마커위에 표시합니다
        infowindow.open(map, marker);
        map.setCenter(locPosition);
      });
    }
    return () => {};
  }, []);

  /* Hooks */
  /* Render */
  return (
    <>
      <div className='place-regist-top-wrap'>
        <div className='place-regist-left-wrap'>
          <div className='place-regist-adress'>
            <label htmlFor='place-adress'>기본 주소</label>
            <input
              type='text'
              name='search'
              readOnly
              value={placeInfo.place_addr}
              style={{ cursor: 'pointer' }}
              onClick={openPostCodePopup}
              placeholder='클릭하여 검색'
            />
          </div>
          <div className='place-regist-location'>
            <label htmlFor='place-location'>위도/경도</label>
            <input
              type='text'
              readOnly
              name='place-location'
              placeholder='위도'
              value={placeInfo.place_latitude}
            />
            <label htmlFor='place-location'>/</label>
            <input
              type='text'
              readOnly
              name='place-location'
              placeholder='경도'
              value={placeInfo.place_longitude}
            />
          </div>{' '}
          {/* place-regist-location end */}
          <div className='place-regist-adress-detail'>
            <label htmlFor='place_addr_detail'>주소 상세</label>
            <input
              type='text'
              name='place_addr_detail'
              placeholder='내용을 입력해 주세요.'
              value={placeInfo.place_addr_detail}
              onChange={handlePlaceInfo}
            />
          </div>{' '}
          {/* place-regist-adress-detail end */}
          <div className='place-regist-call'>
            <label htmlFor='place_tel'>대표 전화</label>
            <input
              type='text'
              name='place_tel'
              placeholder='내용을 입력해 주세요.'
              value={placeInfo.place_tel}
              onChange={handlePlaceInfo}
            />
          </div>{' '}
          {/* place-regist-adress-detail end */}
          <div className='place-regist-call'>
            <label htmlFor='place_distance'>거리 설정</label>
            <input
              type='text'
              name='place_distance'
              placeholder='거리를 입력해 주세요.(단위 : m)'
              value={placeInfo?.place_distance}
              onChange={handlePlaceInfo}
            />
          </div>{' '}
          {/* 엑셀등록 */}
          <div className='place-regist-call'></div> {/* place-regist-call */}
        </div>{' '}
        {/* place-regist-left-wrap end */}
        <div className='place-regist-right-map'>
          <div
            className='map'
            style={{
              width: '500px',
              height: '300px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'lightgray',
            }}
            ref={container}
          >
            <FontAwesomeIcon
              icon={faLocationDot}
              style={{ fontSize: '3rem' }}
            />
          </div>
        </div>{' '}
      </div>
    </>
  );
};

export default MapAddress;
