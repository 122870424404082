import React, { useCallback, useEffect, useState } from 'react';
import './PlaceDetail.css';
import { Typography, Table, Col, Row, Button, Modal, Input, Tag } from 'antd';

import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import commonSlice from 'Store/reducers/CommonSlice';
import { DashboardApi, PlaceApi } from 'Api';
// import AttendanceChart from './AttendanceChart';
import moment from 'moment';
import * as XLSX from 'xlsx/xlsx.mjs';
import MessageAlert from 'Utils/MessageAlert';
import { MapAddress } from 'Components';
import { PLACE_TYPE } from 'Utils/TypeManager';

const { Title } = Typography;

/**
 * [Component] PlaceDetail
 * --
 */
const PlaceDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* ===== Router ===== */
  const { place_id } = useParams();
  /* ===== State ===== */
  const initialState = {
    place_id: place_id,
    place_nm: '',
    place_addr: '',
    place_latitude: '',
    place_longitude: '',
    place_addr_sido: '',
    place_addr_sigungu: '',
    place_addr_bname: '',
    place_addr_roadname: '',
    place_addr_detail: '',
    place_addr_code: '',
    place_tel: '',
    place_distance: '',
  };
  const [placeValue, setPlaceValue] = useState(initialState);
  const [placeInfo, setPlaceInfo] = useState(initialState);
  // const [newName, setNewName] = useState(null);

  // 근무현황차트
  // const [attendanceLog, setAttendanceLog] = useState([]);
  const [attendanceRecord, setAttendanceRecord] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  // const [updatePlaceInfo, setUpdatePlaceInfo] = useState(null);

  /* ===== Functions ===== */
  /**
   * on change
   * --
   */
  const handleChangePlaceName = (e) => {
    const { name, value } = e.target;
    const regex = /^[0-9.]*$/;
    if (name === 'place_distance' && !regex.test(value)) {
      return;
    }
    setPlaceInfo({
      ...placeInfo,
      [name]: value,
    });
  };
  /**
   * 대시보드 정보 조회
   * --
   */
  const handleDashboard = useCallback(async () => {
    const {
      // chart,
      record,
    } = await DashboardApi.getPlaceDashboard(place_id);
    // 근무현황차트
    // setAttendanceLog(chart);
    setAttendanceRecord(record);
  }, [place_id]);

  /**
   * 근무지 상세정보 조회
   * --
   */
  const handlePlaceDetail = useCallback(async () => {
    const result = await PlaceApi.getPlaceDetail(place_id);
    dispatch(commonSlice.actions.setLoading(true));
    if (result) {
      setTimeout(() => {
        dispatch(commonSlice.actions.setLoading(false));
        setPlaceValue(result);
        result['place_distance'] = result['place_distance'] * 1000;
        setPlaceInfo(result);
      }, 1000);
      const { place_nm } = result;
      dispatch(commonSlice.actions.setPageTitle(place_nm));
      return true;
    }
    dispatch(commonSlice.actions.setLoading(false));
    return false;
  }, [dispatch, place_id]);

  /**
   * 엑셀 다운로드
   * --
   */
  const handleDownloadXlsx = () => {
    const { place_nm, place_addr, place_addr_sigungu } = placeInfo;
    const xlsxData = attendanceRecord.map((item, idx) => ({
      ['번호']: idx + 1,
      ['단체명']: item.user_agency,
      ['신청자']: '',
      ['종목']: item.user_field,
      ['연락처']: item.user_tel,
      ['요일']: '',
      ['시간']: '',
      ['신청기간']: '',
      ['장소(주소)']: place_addr,
      ['수업지역']: `${place_nm}(${place_addr_sigungu})`,
      ['요청내용']: '',
      ['지도자']: item.user_nm,
      ['구분']: '',
      ['이메일']: item.user_mail,
      ['신규여부']: '',
    }));

    const ws = XLSX.utils.json_to_sheet(xlsxData);
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(
      wb,
      `${place_nm}_근태기록_${moment().format('YYYYMMDDHHmmss')}.xlsx`
    );
  };

  /**
   * 근무지명 변경
   * --
   */

  const handleChangeContent = async () => {
    try {
      // const newPlace = updatePlaceInfo ? updatePlaceInfo : placeInfo;
      const data = {
        place_id: placeInfo.place_id,
        place_nm: placeInfo.place_nm,
        place_addr: placeInfo.place_addr,
        place_latitude: placeInfo.place_latitude.toString(),
        place_longitude: placeInfo.place_longitude.toString(),
        place_addr_sido: placeInfo.place_addr_sido,
        place_addr_sigungu: placeInfo.place_addr_sigungu,
        place_addr_bname: placeInfo.place_addr_bname,
        place_addr_roadname: placeInfo.place_addr_roadname,
        place_addr_detail: placeInfo.place_addr_detail,
        place_addr_code: placeInfo.place_addr_code,
        place_tel: placeInfo.place_tel,
        place_type: placeInfo.place_type,
        place_distance: placeInfo?.place_distance
          ? parseFloat(placeInfo.place_distance / 1000)
          : null,
      };
      // return;
      const result = await PlaceApi.updatePlace(data);
      MessageAlert.success('근무지 정보가 변경되었습니다.');
      setPlaceInfo({
        ...placeInfo,
        place_nm: result.place_nm,
      });
      setPlaceValue({
        ...placeInfo,
        place_nm: result.place_nm,
      });
    } catch (e) {
      MessageAlert.error(
        '근무지 정보를 수정하지 못했습니다. \n다시 시도해주세요'
      );
    }
  };

  /**
   * 근무지명 변경
   * --
   */
  const handleDeletePlace = async () => {
    try {
      const { place_id } = placeInfo;
      const result = await PlaceApi.deletePlace(place_id);
      if (result) {
        MessageAlert.success('근무지가 삭제되었습니다.');
        navigate('/place');
      } else {
        MessageAlert.error('다시 시도해주세요.');
      }
    } catch (error) {
      MessageAlert.error(
        '일시적인 오류가 발생했습니다. 증상이 반복될 경우 관리자에게 문의하세요'
      );
    }
  };

  /* ===== HOOKS ===== */
  /**
   *
   */
  useEffect(() => {
    handlePlaceDetail();
  }, [handlePlaceDetail]);

  /**
   *
   */
  useEffect(() => {
    handleDashboard();
  }, [handleDashboard]);

  /* ===== RENDER ===== */
  return (
    <>
      <div className='place-detail-container'>
        <div className='place-detail-contents'>
          {/* === [NEW] 기본정보 === */}
          <div>
            <div
              className='users-detail-contents-inner'
              style={{ margin: 0, marginBottom: 40 }}
            >
              <Row style={{ width: 1200 }}>
                <Col span={20}>
                  <Title level={4} style={{ color: '#4d77ee' }}>
                    기본정보
                  </Title>
                </Col>
                <Col span={4} style={{ textAlign: 'right' }}>
                  <Button onClick={() => setModalVisible(true)}>
                    정보수정
                  </Button>
                </Col>
              </Row>

              <div
                style={{ width: 1200, background: '#fff' }}
                className='users-detail-info'
              >
                {/* place_seq */}
                {/* <div className="user-detail-field">
                <label htmlFor="call">No</label>
                <p>{placeInfo.place_seq}</p>
              </div> */}
                {/* place_seq */}

                {/* place_nm */}
                <div className='user-detail-field'>
                  <label htmlFor='call'>근무지명</label>
                  <p>{placeValue?.place_nm}</p>
                </div>
                {/* place_nm */}

                {/* place_addr_sigungu */}
                <div className='user-detail-field'>
                  <label htmlFor='call'>지역구</label>
                  <p>{placeValue.place_addr_sigungu}</p>
                </div>
                {/* place_addr_sigungu */}

                {/* place_addr */}
                <div className='user-detail-field'>
                  <label htmlFor='call'>주소(우편번호)</label>
                  <p>
                    {placeValue.place_addr} {placeValue.place_addr_detail}(
                    {placeValue.place_addr_code})
                  </p>
                </div>
                {/* place_addr */}

                {/* place_latitude */}
                <div className='user-detail-field'>
                  <label htmlFor='call'>위도/경도</label>
                  <p>
                    {placeValue.place_latitude}/{placeValue.place_longitude}
                  </p>
                </div>
                {/* place_latitude */}

                {/* place_latitude */}
                <div className='user-detail-field'>
                  <label htmlFor='call'>연락처</label>
                  <p>{placeValue.place_tel}</p>
                </div>
                {/* place_latitude */}

                {/* place_latitude */}
                <div className='user-detail-field'>
                  <label htmlFor='call'>근무지유형</label>
                  <p>
                    {placeValue.place_type && (
                      <Tag color={PLACE_TYPE[placeValue.place_type].color}>
                        {PLACE_TYPE[placeValue.place_type].title}
                      </Tag>
                    )}
                  </p>
                </div>
                {/* place_latitude */}

                {/* place_latitude */}
                <div className='user-detail-field'>
                  <label htmlFor='call'>거리</label>
                  <p>
                    {placeValue?.place_distance
                      ? placeValue?.place_distance
                      : 1000}{' '}
                    m
                  </p>
                </div>
                {/* place_latitude */}
              </div>
            </div>
          </div>
          {/* === [NEW] 기본정보 === */}

          {/* === [NEW] 차트정보 === */}
          {/* <div style={{ marginBottom: 40 }}>
            <div
              className="users-detail-contents-inner"
              style={{ margin: 0, marginBottom: 40 }}
            >
              <Title level={4} style={{ color: '#4d77ee' }}>
                근무현황차트
              </Title>
              <div
                style={{
                  width: 1200,
                  background: '#fff',
                  padding: '20px 35px',
                }}
                className="users-detail-info"
              >
                <AttendanceChart data={attendanceLog} />
              </div>
            </div>
          </div> */}
          {/* === [NEW] 차트정보 === */}

          {/* === [NEW] 기본정보 === */}
          <div>
            <div
              className='users-detail-contents-inner'
              style={{ margin: 0, width: 1200 }}
            >
              <Row style={{ width: 1200 }}>
                <Col span={20}>
                  <Title level={4} style={{ color: '#4d77ee' }}>
                    근무상세이력({attendanceRecord.length})
                  </Title>
                </Col>
                <Col span={4} style={{ textAlign: 'right' }}>
                  <Button onClick={handleDownloadXlsx}>엑셀다운로드</Button>
                </Col>
              </Row>

              <div style={{ margin: 0, width: 1200 }}>
                <Table
                  width={1200}
                  style={{ cursor: 'pointer' }}
                  columns={[
                    {
                      width: '10%',
                      title: '구분',
                      dataIndex: 'attendance_status',
                      key: 'attendance_status',
                      render: (value) =>
                        value === 'GO_TO_WORK' ? '시작' : '종료',
                    },
                    {
                      width: '12%',
                      title: '근무자',
                      dataIndex: 'user_nm',
                      key: 'user_nm',
                    },
                    {
                      width: '22%',
                      title: '소속',
                      dataIndex: 'user_agency',
                      key: 'user_agency',
                    },
                    {
                      width: '22%',
                      title: '시간시간',
                      dataIndex: 'attendance_start',
                      key: 'attendance_start',
                      render: (_, rec) =>
                        rec.created_at
                          ? moment(rec.created_at).format('YYYY-MM-DD (HH:mm)')
                          : '[--:--]',
                    },
                    {
                      width: '22%',
                      title: '종료시간',
                      dataIndex: 'attendance_end',
                      key: 'attendance_end',
                      render: (_, rec) =>
                        rec.attendance_end
                          ? moment(rec.attendance_end).format(
                              'YYYY-MM-DD (HH:mm)'
                            )
                          : '[--:--]',
                    },
                    {
                      width: '12%',
                      title: '근무자유형',
                      dataIndex: 'user_status',
                      key: 'user_status',
                    },
                  ]}
                  dataSource={attendanceRecord}
                />
              </div>
            </div>
          </div>
          {/* === [NEW] 기본정보 === */}
        </div>
        {/* place-detail-contents end */}
      </div>

      {/* === 수정 Modal === */}
      <Modal
        title='근무지정보 수정'
        width={600}
        style={{
          top: 20,
        }}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handleChangeContent}
        cancelText='취소'
        okText='저장'
      >
        <div
          style={{ width: '100%', background: '#fff' }}
          className='users-detail-info'
        >
          {/* === 근무지명 === */}
          <div className='user-detail-field'>
            <label htmlFor='call'>근무지명</label>
            <p style={{ width: '80%' }}>
              <Input
                name='place_nm'
                defaultValue={placeInfo.place_nm}
                onChange={handleChangePlaceName}
                style={{ width: '100%' }}
              />
            </p>
          </div>

          {/* === 연락처 === */}
          <div className='user-detail-field'>
            <label htmlFor='call'>연락처</label>
            <p style={{ width: '80%' }}>
              <Input
                name='place_tel'
                defaultValue={placeInfo.place_tel}
                onChange={handleChangePlaceName}
                style={{ width: '100%' }}
              />
            </p>
          </div>

          {/* === 주소 === */}
          <div className='user-detail-field' style={{ height: 470 }}>
            <label htmlFor='call'>주소(우편번호)</label>
            <p style={{ width: '80%' }}>
              <MapAddress
                initial={{
                  place_addr: placeInfo.place_addr,
                  place_latitude: placeInfo.place_latitude,
                  place_longitude: placeInfo.place_longitude,
                  place_addr_sido: placeInfo.place_addr_sido,
                  place_addr_sigungu: placeInfo.place_addr_sigungu,
                  place_addr_bname: placeInfo.place_addr_bname,
                  place_addr_roadname: placeInfo.place_addr_roadname,
                  place_addr_detail: placeInfo.place_addr_detail,
                  place_addr_code: placeInfo.place_addr_code,
                }}
                onChange={(value) => {
                  setPlaceInfo({
                    ...value,
                    place_id: placeInfo.place_id,
                    place_nm: placeInfo.place_nm,
                    place_tel: placeInfo.place_tel,
                    place_distance: placeInfo.place_distance,
                    place_type: placeInfo.place_type,
                  });
                }}
              />
            </p>
          </div>

          {/* === 거리 === */}
          <div className='user-detail-field'>
            <label htmlFor='place_distance'>거리 설정</label>
            <p style={{ width: '80%' }}>
              <Input
                type='text'
                name='place_distance'
                placeholder='거리를 입력해 주세요.(단위 : m)'
                value={placeInfo?.place_distance}
                onChange={handleChangePlaceName}
                style={{ width: '100%' }}
              />
            </p>
          </div>
          {/* === 삭제 === */}
          <div className='user-detail-field' style={{ height: 'auto' }}>
            <label htmlFor='call'>삭제</label>
            <p style={{ margin: 0, width: '80%' }}>
              <Button
                size='small'
                type='danger'
                onClick={() => setDeleteModalVisible(true)}
              >
                근무지 삭제
              </Button>

              {deleteModalVisible && (
                <div
                  style={{
                    width: '100%',
                    background: '#f7f7f7',
                    marginBottom: 10,
                    padding: '10px 15px',
                    lineHeight: 1.5,
                  }}
                >
                  <h3 style={{ margin: 0, color: '#ff4d4f' }}>경고</h3>
                  <p style={{ margin: 0, color: '#a1a1a1' }}>
                    근무지를 삭제할 경우 근무지 내용과 근무지에 기록된 모든
                    근태이력은 영구적으로 삭제되며 삭제된 내용은 복구가
                    불가능합니다.
                  </p>
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      marginTop: 10,
                    }}
                  >
                    <Button
                      size='small'
                      onClick={() => setDeleteModalVisible(false)}
                    >
                      취소
                    </Button>{' '}
                    <Button size='small' danger onClick={handleDeletePlace}>
                      확인
                    </Button>
                  </div>
                </div>
              )}
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PlaceDetail;
