import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { TypeManager } from 'Utils';

const VACATION_TYPE = TypeManager.getVacationList();

const VacationInput = ({
  closeModal,
  userInfo,
  createVacation,
  createVacationMulti,
}) => {
  /* Router */
  /* State */
  const { date } = useSelector((state) => state.common);
  const initialState = {
    vacation_content: '',
    vacation_end_date: '',
    vacation_end_time: '',
    vacation_start_date: moment(JSON.parse(date)).format('YYYY-MM-DD'),
    vacation_start_time: '',
    vacation_status: 'PENDING',
    vacation_type: '',
    user_id: userInfo.user_id,
  };
  const [vacationInfo, setVacationInfo] = useState(initialState);
  /* Functions */
  const handleVacationType = (e) => {
    if (e.target.value === 0) {
      return;
    }
    alert(e.target.value);
    setVacationInfo({
      ...initialState,
      vacation_type: e.target.value,
      vacation_content: vacationInfo.vacation_content,
      vacation_start_date: moment(JSON.parse(date)).format('YYYY-MM-DD'),
    });
  };

  const handleVacationInfo = (e) => {
    setVacationInfo({ ...vacationInfo, [e.target.name]: e.target.value });
  };

  const handleCreateVacation = async () => {
    const cond = moment(vacationInfo.vacation_end_date).diff(
      vacationInfo.vacation_start_date,
      'days'
    );

    if (cond !== 0) {
      const temp = [...new Array(cond + 1)].map((item, idx) => {
        return {
          ...vacationInfo,
          vacation_start_date: moment(vacationInfo.vacation_start_date)
            .add(idx, 'days')
            .format('YYYY-MM-DD'),
          vacation_end_date: moment(vacationInfo.vacation_start_date)
            .add(idx, 'days')
            .format('YYYY-MM-DD'),
          vacation_start_time: '09:00',
          vacation_end_time: '18:00',
        };
      });
      const result = await createVacationMulti(temp);
      if (result) {
        return true;
      }
      return false;
    }
    const vacation = {
      ...vacationInfo,
      vacation_start_time: '09:00',
      vacation_end_time: '18:00',
    };
    const result = await createVacation(vacation);
    if (result) {
      return true;
    }
    return false;
  };

  /* Hooks */
  /* Render */
  const vacationRender = VACATION_TYPE.map((item) => {
    const { id, title, value } = item;
    return (
      <option value={value} key={id}>
        {title}
      </option>
    );
  });

  const dateRender =
    vacationInfo.vacation_type === 'ALL_DAY' ? (
      <>
        <div>
          <p>연차 시작</p>
          <input
            type="date"
            name="vacation_start_date"
            placeholder="내용을 입력해주세요."
            value={vacationInfo.vacation_start_date}
            onChange={handleVacationInfo}
          />
        </div>
        <div>
          <p>연차 종료</p>
          <input
            type="date"
            name="vacation_end_date"
            placeholder="내용을 입력해주세요."
            value={vacationInfo.vacation_end_date}
            onChange={handleVacationInfo}
          />
        </div>
        <div>
          <p>연차 사유</p>
          <textarea
            name="vacation_content"
            cols="30"
            rows="5"
            value={vacationInfo.vacation_content}
            onChange={handleVacationInfo}
          ></textarea>
        </div>
      </>
    ) : vacationInfo.vacation_status === 'HALF' ? (
      <>
        <div>
          <p>연차 일자</p>
          <input
            type="date"
            name="vacation_start_date"
            placeholder="내용을 입력해주세요."
            value={vacationInfo.vacation_start_date}
            onChange={handleVacationInfo}
          />
        </div>
        <div>
          <p>시작 시간</p>
          <input
            type="time"
            name="vacation_start_time"
            placeholder="내용을 입력해주세요."
            value={vacationInfo.vacation_start_time}
            onChange={handleVacationInfo}
          />
        </div>
        <div>
          <p>종료 시간</p>
          <input
            type="time"
            name="vacation_end_time"
            placeholder="내용을 입력해주세요."
            value={vacationInfo.vacation_end_time}
            onChange={handleVacationInfo}
          />
        </div>
        <div>
          <p>연차 사유</p>
          <textarea
            name="vacation_content"
            cols="30"
            rows="5"
            value={vacationInfo.vacation_content}
            onChange={handleVacationInfo}
          ></textarea>
        </div>
      </>
    ) : (
      ''
    );

  return (
    <div>
      <div className="schedule-regist-info">
        <div>
          <p>연차 구분</p>
          <select name="attendance_type" onChange={handleVacationType}>
            <option value="0">연차 구분 선택</option>
            {vacationRender}
          </select>
        </div>
        {dateRender}
      </div>
      <div className="schedule-regist-button">
        <button className="cancel-btn" onClick={closeModal}>
          닫기
        </button>
        <button className="regist-btn" onClick={handleCreateVacation}>
          등록
        </button>
      </div>
    </div>
  );
};

export default VacationInput;
