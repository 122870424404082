import { BASE_URL } from 'Utils';

/**
 * 인증 API
 */
const AUTH_API = {
  /**
   * @method POST
   * @param
   */
  SIGNUP_ADMIN: `${BASE_URL}/auth/signup/admin`,
  /**
   * @method POST
   * @param
   */
  SIGNIN_ADMIN: `${BASE_URL}/auth/signin/admin`,
  /**
   * @method POST
   * @param
   */
  VERIFY: `${BASE_URL}/auth/verify`,
  /**
   * @method POST
   * @param
   */
  CHECK: `${BASE_URL}/auth/check`,
};

/**
 * 관리자 API
 */
const ADMIN_API = {
  /**
   * @method POST
   * @param
   */
  CREATE_ADMIN: `${BASE_URL}/admin`,
};

/**
 * 근무지 API
 */
const PLACE_API = {
  /**
   * @method POST
   * @param
   */
  CREATE_PLACE: `${BASE_URL}/place`,
  /**
   * @method POST
   * @param
   */
  CREATE_PLACE_BULK: `${BASE_URL}/place/all`,
  /**
   * @method GET
   * @param
   */
  GET_PLACE_LIST: `${BASE_URL}/place`,
  /**
   * @method GET
   * @param {string} place_id
   */
  GET_PLACE_DETAIL: `${BASE_URL}/place/detail/:place_id`,
  /**
   * @method PUT
   * @param
   */
  UPDATE_PLACE: `${BASE_URL}/place`,
  /**
   * @method DELETE
   * @param {string} place_id
   */
  DELETE_PLACE: `${BASE_URL}/place/:place_id`,
};

/**
 * 사용자 API
 */
const USER_API = {
  /**
   * @method POST
   * @param
   */
  CREATE_USER: `${BASE_URL}/user`,
  /**
   * @method PUT
   * @param
   */
  UPDATE_USER_STATUS: `${BASE_URL}/user/status`,
  /**
   * @method PUT
   * @param
   */
  UPDATE_USER_VACATION_COUNT: `${BASE_URL}/user/vacation`,
  /**
   * @method GET
   * @param
   */
  GET_USER_LIST: `${BASE_URL}/user`,
  /**
   * @method GET
   * @param {String} user_id
   */
  GET_USER_DETAIL: `${BASE_URL}/user/:user_id`,
  /**
   * @method DELETE
   * @param {String} user_id
   */
  DELETE_USER: `${BASE_URL}/user/:user_id`,
};

/**
 * 근태 API
 */
const ATTENDANCE_API = {
  /**
   * @method POST
   * @param
   */
  CREATE_ATTENDANCE: `${BASE_URL}/attendance`,
  /**
   * @method GET
   * @param {string} user_id 사용자 고유 번호
   */
  GET_ATTENDANCE: `${BASE_URL}/attendance/detail/:user_id`,
  /**
   * @method GET
   * @param {string} place_id 근무지 고유 번호
   */
  GET_ATTENDANCE_PLACE: `${BASE_URL}/attendance/place/:place_id`,
  /**
   * @method PUT
   * @param
   */
  UPDATE_ATTENDANCE: `${BASE_URL}/attendance`,
  /**
   * @method DELETE
   * @param
   */
  DELETE_ATTENDANCE: `${BASE_URL}/attendance`,
};

/**
 * 휴가 API
 */
const VACATION_API = {
  /**
   * @method POST
   * @param
   */
  CREATE_VACATION: `${BASE_URL}/vacation`,
  /**
   * @method POST
   * @param
   */
  CREATE_VACATION_MULTI: `${BASE_URL}/vacation/multi`,
  /**
   * @method GET
   * @param {string} user_id 사용자 고유 번호
   */
  GET_VACATION: `${BASE_URL}/vacation/user/:user_id`,
  /**
   * @method PUT
   * @param
   */
  UPDATE_VACATION: `${BASE_URL}/vacation`,
  /**
   * @method DELETE
   * @param
   */
  DELETE_VACATION: `${BASE_URL}/vacation`,
};

const COMMUNITY_API = {
  /**
   * @method POST
   * @param
   */
  CREATE_COMMUNITY: `${BASE_URL}/community`,
  /**
   * @method GET
   * @param
   */
  GET_COMMUNITY: `${BASE_URL}/community`,
  /**
   * @method PUT
   * @param
   */
  UPDATE_COMMUNITY: `${BASE_URL}/community`,
  /**
   * @method DELETE
   * @param {string} community_id
   */
  DELETE_COMMUNITY: `${BASE_URL}/community/:community_id`,
};

/**
 * 댓글 API
 */
const REPLY_API = {
  /**
   * @method POST
   * @param
   */
  CREATE_REPLY: `${BASE_URL}/community/reply`,
  /**
   * @method GET
   * @param {string} community_id
   */
  GET_REPLY: `${BASE_URL}/community/reply/:community_id`,
  /**
   * @method PUT
   * @param
   */
  UPDATE_REPLY: `${BASE_URL}/community/reply`,
  /**
   * @method DELETE
   * @param {string} reply_id
   */
  DELETE_REPLY: `${BASE_URL}/community/reply/:reply_id`,
};

/**
 * 공지사항 API
 */
const NOTICE_API = {
  /**
   * @method POST
   * @param
   */
  CREATE_NOTICE: `${BASE_URL}/notice`,
  /**
   * @method GET
   * @param
   */
  GET_NOTICE: `${BASE_URL}/notice`,
  /**
   * @method UPDATE
   * @param
   */
  UPDATE_NOTICE: `${BASE_URL}/notice`,
  /**
   * @method DELETE
   * @param {string} notice_id
   */
  DELETE_NOTICE: `${BASE_URL}/notice/:notice_id`,
};

const DASHBOARD_API = {
  /**
   * @method GET
   * @param
   */
  GET_DASHBOADR: `${BASE_URL}/dashboard`,
  /**
   * @method GET
   * @param
   */
  GET_DASHBOARD_VACATIONS: `${BASE_URL}/dashboard/vacations`,
  /**
   * @method GET
   * @param
   */
  GET_DASHBOARD_CALENDAR: `${BASE_URL}/dashboard/calendar`,
  /**
   * @method GET
   * @param
   */
  GET_DASHBOARD_VACATIONS_DETAIL: `${BASE_URL}/dashboard/vacations/:current_date`,
  /**
   * @method GET
   * @param
   */
  GET_DASHBOARD_CALENDAR_DETAIL: `${BASE_URL}/dashboard/calendar/:current_date`,
  /**
   * @method GET
   * @param {string} place_id 근무지 고유 번호
   */
  GET_PLACE_DASHBOARD: `${BASE_URL}/dashboard/place/:place_id`,
};

const SCHEDULE_API = {
  /**
   * @method POST
   */
  CREATE_SCHEDULE: `${BASE_URL}/schedule`,
  /**
   * @method POST
   */
  GET_SCHEDULES: `${BASE_URL}/schedule/user/:user_id`,
};

const ApiConstant = {
  ...AUTH_API,
  ...PLACE_API,
  ...ADMIN_API,
  ...USER_API,
  ...ATTENDANCE_API,
  ...VACATION_API,
  ...COMMUNITY_API,
  ...REPLY_API,
  ...NOTICE_API,
  ...DASHBOARD_API,
  ...SCHEDULE_API,
};
export default ApiConstant;
