import React from 'react';
import './CommunityRegist.css';

// fontawesomeicon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

const NoticeModify = ({ notice, update, close }) => {
  /* Router */
  /* State */
  const [noticeInfo, setNoticeInfo] = useState(notice);

  /* Functions */
  const handleNoticeInfo = (e) => {
    setNoticeInfo({ ...noticeInfo, [e.target.name]: e.target.value });
  };

  const handleUpdate = async () => {
    const postData = {
      ...noticeInfo,
      is_file: false,
    };
    const result = await update(postData);
    if (result) {
      close();
      return true;
    }
    return false;
  };

  const handleClose = () => {
    close();
  };

  /* Hooks */
  /* Render */
  return (
    <div className="community-regist-container">
      <div className="community-regist-contents">
        <div className="community-regist-contents-top">
          <select name="board-type" id="board-type">
            <option value="community-board-type">공지사항</option>
          </select>
          <FontAwesomeIcon
            icon={faXmark}
            className="Xmark"
            onClick={handleClose}
          />
        </div>{' '}
        {/* community-regist-contents-top end */}
        <div className="community-regist-contents-bottom">
          <div>
            <select name="board-type" id="board-type" disabled>
              <option value="community-board-writer">
                {noticeInfo.admin_nm}
              </option>
            </select>
            <input
              type="text"
              name="notice_title"
              id="community-board-title-id"
              placeholder="글 제목"
              value={noticeInfo.notice_title}
              onChange={handleNoticeInfo}
            />
          </div>
          <textarea
            name="notice_content"
            id="community-board-textarea-id"
            cols="30"
            rows="10"
            placeholder="내용을 입력해 주세요."
            value={noticeInfo.notice_content}
            onChange={handleNoticeInfo}
          ></textarea>
        </div>{' '}
        {/* community-regist-contents-bottom end */}
        <div className="community-regist-contents-button">
          <button onClick={handleUpdate}>수정</button>
        </div>{' '}
        {/* community-regist-contents-button */}
      </div>{' '}
      {/* community-regist-contents end */}
    </div>
  ); /* community-regist-container end */
};

export default NoticeModify;
