/**
 *
 *
 */

import React, { useState } from 'react';
import { AtdFullCalendar } from 'Components';
import { DashboardPreview } from './components';

/**
 * [Presenter Component] DashboardPresenter
 * --
 */
const DashboardPresenter = ({ attendanceList, dashboardInfo }) => {
  /* ===== Router ===== */

  /* ===== State ===== */
  const [statics, setStatics] = useState({
    total: 0,
    complete: 0,
    inprogress: 0,
    err: 0,
  });

  /* ===== Functions ===== */

  /* ===== Hooks ===== */

  /* ===== Render ===== */
  return (
    <div className='main-dashboard-content'>
      <DashboardPreview dashboard_status={dashboardInfo} statics={statics} />
      <div
        className='main-dashboard-content-bottom'
        style={{ background: '#fff', padding: '12px 35px', paddingTop: 25 }}
      >
        <AtdFullCalendar setStatics={setStatics} />
        <br />
      </div>
      {/* <DashboardRecently data={attendanceList} /> */}
    </div>
  );
};

export default DashboardPresenter;
