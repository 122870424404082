/**
 *
 *
 */

import './users.css';
import React, { useState } from 'react';

// fontawesomeicon
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   faAngleLeft,
//   faAngleRight,
//   faSearch,
// } from '@fortawesome/free-solid-svg-icons';
import {
  // Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { ModalLayout } from 'Components';
import { UsersDetail, UsersRegist } from './components';
import moment from 'moment';
import { Button, Input, Table, Tag } from 'antd';
import { USER_STATUS } from 'Utils/TypeManager';
import { handleAscending } from 'Utils';
import { useEffect } from 'react';

const { Search } = Input;

/**
 * Presenter
 * --
 */
const UsersPresenter = ({
  userList,
  placeList,
  createUser,
  getUserDetail,
  onChangeUserStatus,
  onDeleteUser,
}) => {
  /* ===== Router ===== */
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const qsFilter = searchParams.get('filter');
  const qsUserId = searchParams.get('userId');

  /* ===== State ===== */
  const [keyword, setKeyword] = useState('');
  const [keyword2, setKeyword2] = useState('');
  const [userTypeFilter, setUserTypeFilter] = useState('ALL');

  const [modal, setModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const initialState = {
    user_id: '',
    user_nm: '',
    user_agency: '',
    user_field: '',
    user_tel: '',
    created_at: 0,
    modified_at: 0,
  };
  const [userInfo, setUserInfo] = useState(initialState);
  const [currentPagination, setCurrentPagination] = useState(1);

  /* ===== Functions ===== */
  const handleModal = () => {
    setModal(!modal);
  };

  const handleDetailModal = (value = null) => {
    const newValue = value !== null ? value : !detailModal;
    if (!newValue) {
      const qs = `${
        !qsFilter || qsFilter === 'ALL' ? '' : `?filter=${qsFilter}`
      }`;
      navigate(`${pathname}${qs}`, { replace: true });
    }
    setDetailModal(newValue);
  };

  const handleCreateUser = async (userInfo) => {
    const result = await createUser(userInfo);
    if (result) {
      handleModal();
      return true;
    }
    return false;
  };

  /**
   * 회원 상세 모달 열기
   * --
   * @param {String} user_id
   * @returns
   */
  const handleUserDetail = async (user_id, modalFlag = null) => {
    const result = await getUserDetail(user_id);
    if (result) {
      const qs = `${search ? `?filter=${qsFilter}&` : `?`}userId=${user_id}`;
      setUserInfo(result);
      handleDetailModal(modalFlag);
      navigate(`${pathname}${qs}`, { replace: true });
      return true;
    }
    return false;
  };

  /**
   * 키워드 검색
   * --
   */
  const onSearch = () => {
    setKeyword2(keyword);
  };

  /**
   * 테이블 로우 클릭 함수
   * --
   */
  const onCell = (record, _index) => {
    return {
      onClick: (_) => {
        const { user_id } = record;
        const qs = `${search ? `?filter=${qsFilter}&` : `?`}userId=${user_id}`;
        navigate(`${pathname}${qs}`, { replace: false });
      },
      // onClick: (_) => handleUserDetail(record.user_id),
    };
  };

  /**
   * 탭변경 함수
   * --
   */
  const handleChangeTab = (type) => {
    const qs = type === 'ALL' ? `` : `?filter=${type}`;
    setUserTypeFilter(type);
    navigate(`${pathname}${qs}`, { replace: true });
  };

  /* ===== Hooks ===== */
  //
  useEffect(() => {
    if (qsFilter) {
      setUserTypeFilter(qsFilter);
    }
  }, []);

  //
  useEffect(() => {
    if (qsUserId) {
      handleUserDetail(qsUserId, true);
    } else {
      handleDetailModal(false);
    }
  }, [qsUserId]);

  /* ===== Variables ===== */
  // 필터별 목록 수
  const lengths = {
    all: userList?.length,
    accept: userList?.filter((u) => u.user_status === 'ACCESS').length,
    wait: userList?.filter((u) => u.user_status === 'WAIT').length,
    tester: userList?.filter((u) => u.user_status === 'TESTER').length,
  };

  // 유저목록 - 1차필터
  const userList2 =
    userTypeFilter === 'ALL'
      ? userList.filter((u) => u.user_status !== 'TESTER')
      : userList.filter((u) => u.user_status === userTypeFilter);
  // 유저목록 - 검색필터
  const filteredUserList = keyword2
    ? userList2.filter((u) => u.user_nm.indexOf(keyword2) !== -1)
    : userList2;
  // 유저목록 - 정렬
  const sortedUserList = filteredUserList.sort((a, b) =>
    handleAscending(a.user_nm, b.user_nm)
  );

  /* ===== RENDER ===== */
  return (
    <div className='users-container'>
      <div className='users-contents'>
        <div
          className='users-contents-top'
          style={{
            marginTop: 25,
            display: 'block',
          }}
        >
          <Button
            onClick={() => handleChangeTab('ALL')}
            type={userTypeFilter === 'ALL' && 'primary'}
            size='large'
          >
            전체({lengths.all})
          </Button>{' '}
          <Button
            onClick={() => handleChangeTab('ACCESS')}
            type={userTypeFilter === 'ACCESS' && 'primary'}
            size='large'
          >
            승인({lengths.accept})
          </Button>{' '}
          <Button
            onClick={() => handleChangeTab('WAIT')}
            type={userTypeFilter === 'WAIT' && 'primary'}
            size='large'
          >
            대기({lengths.wait})
          </Button>{' '}
          <Button
            onClick={() => handleChangeTab('TESTER')}
            type={userTypeFilter === 'TESTER' && 'primary'}
            size='large'
          >
            테스터({lengths.tester})
          </Button>{' '}
        </div>
        <div className='users-contents-top' style={{ marginTop: 5 }}>
          {/* 상단 */}
          <div>
            <Search
              size='large'
              placeholder='검색할 회원명을 입력해주세요'
              onSearch={onSearch}
              onChange={(e) => setKeyword(e.target.value)}
              style={{ width: 330 }}
              value={keyword}
            />
            <Button
              size='large'
              disabled={keyword2 === '' ? true : false}
              onClick={() => {
                setKeyword('');
                setKeyword2('');
              }}
            >
              초기화
            </Button>
          </div>
          <div>
            <Button type='primary' size='large' onClick={handleModal}>
              회원 등록
            </Button>
          </div>
          {/* 상단 */}
        </div>

        <div className='users-contents-bottom'>
          <Table
            style={{ cursor: 'pointer' }}
            onChange={({ current }) => setCurrentPagination(current)}
            current={currentPagination}
            columns={[
              {
                width: '8%',
                title: '번호',
                onCell,
                render: (_, __, index) =>
                  index + 1 + 10 * (currentPagination - 1),
              },
              {
                title: '회원명',
                dataIndex: 'user_nm',
                key: 'user_nm',
                width: '13%',
                onCell,
              },
              {
                title: '이메일',
                dataIndex: 'user_mail',
                key: 'user_mail',
                render: (item) => (item ? item : '-'),
                onCell,
              },
              {
                title: '부서',
                dataIndex: 'user_agency',
                key: 'user_agency',
                render: (item) => (item ? item : '-'),
                width: '18%',
                onCell,
              },
              {
                title: '전화번호',
                dataIndex: 'user_tel',
                key: 'user_tel',
                width: '14%',
                onCell,
              },
              {
                title: '입사일',
                dataIndex: 'user_joinDate',
                key: 'user_joinDate',
                render: (v) => (v ? moment(v).format('YYYY.MM.DD') : '-'),
                width: '12%',
                onCell,
              },
              {
                title: '사용여부',
                render: ({ user_status }) => {
                  const { title, color } = USER_STATUS[user_status];
                  return <Tag color={color}>{title.slice(0, 3)}</Tag>;
                },
                width: '8%',
                onCell,
              },
            ]}
            dataSource={sortedUserList}
          ></Table>
        </div>
      </div>
      <ModalLayout
        modal={modal}
        setModal={handleModal}
        title='회원 등록'
        custom
      >
        <UsersRegist createUser={handleCreateUser} setModal={handleModal} />
      </ModalLayout>

      <ModalLayout
        width={1200}
        modal={detailModal}
        setModal={handleDetailModal}
        close={handleDetailModal}
        custom
      >
        {userInfo && (
          <UsersDetail
            userInfo={userInfo}
            placeList={placeList}
            setModal={() => handleDetailModal(!detailModal)}
            onChangeUserStatus={onChangeUserStatus}
            onDelete={onDeleteUser}
          />
        )}
      </ModalLayout>
    </div>
  ); /* users-container end */
};

export default UsersPresenter;
