import ApiConstant from 'Api/ApiConstant';
import { ApiManager } from 'Utils';
const $http = new ApiManager();

const NoticeApi = {
  /**
   * 공지사항 등록
   * --
   * @param {*} noticeInfo
   * @returns
   */
  registNotice: async (noticeInfo) => {
    try {
      const url = ApiConstant.CREATE_NOTICE;
      const { status, data, message } = await $http.post(url, noticeInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },
  getNotice: async () => {
    try {
      const url = ApiConstant.GET_NOTICE;
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },
  updateNotice: async (noticeInfo) => {
    try {
      const url = ApiConstant.UPDATE_NOTICE;
      const { status, data, message } = await $http.put(url, noticeInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },
  deleteNotice: async (notice_id) => {
    try {
      const url = ApiConstant.DELETE_NOTICE.replace(':notice_id', notice_id);
      const { status, data, message } = await $http.delete(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },
};

export default NoticeApi;
