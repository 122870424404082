import { Buffer } from 'buffer';
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const PUBLIC_URL = process.env.REACT_APP_DOMAIN.includes('/')
  ? process.env.REACT_APP_DOMAIN.slice(1)
  : process.env.REACT_APP_DOMAIN;

export { default as ApiManager } from './ApiManager';
export { default as TypeManager } from './TypeManager';

/**
 * 쿠키찾기
 * --
 */
export const getCookie = (name, options = null) => {
  const value = window.document.cookie.match(
    '(^|;) ?' + name + '=([^;]*)(;|$)'
  );
  return value ? value[2] : null;
};

/**
 * 쿠키저장
 */
export const setCookie = (name, value, callback = false) => {
  window.document.cookie = `${name}=${value}; path=/;max-age=${
    3600 * 8
  };Domain=${PUBLIC_URL};Path='/'`;
  if (callback) callback();
};

/**
 * 쿠키삭제
 */
export const deleteCookie = (name) => {
  if (getCookie(name)) {
    window.document.cookie = `${name}=;path=/;domain=${PUBLIC_URL};expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    // window.document.cookie =
    //   name +
    //   '=' +
    //   (path ? ';path=' + path : '') +
    //   (domain ? ';domain=' + domain : '') +
    //   ';expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
};

/**
 * Buffer 쓰기
 * --
 * @param {string} str
 * @returns
 */
export const writeBuffer = (str, format = 'base64') => {
  return Buffer.from(str, 'utf8').toString(format);
};

/**
 * Buffer 읽기
 * --
 * @param {string} str
 * @returns
 */
export const readBuffer = (str, format = 'base64') => {
  return Buffer.from(str, format).toString('utf8');
};

/**
 * API파라미터 Replace함수
 * --
 */
export const parameterToPath = (path, params = {}) => {
  const keys = Object.keys(params);
  let newStr = '';
  for (let key of keys) {
    newStr = path.replace(`:${key}`, params[key]);
  }
  return newStr;
};

/**
 * 가나다순
 * --
 */
export const handleAscending = (a, b) => (a < b ? -1 : a == b ? 0 : 1);
