import React, { useState } from 'react';
import { Content } from 'Components';
import {
  TimePicker,
  DatePicker,
  Col,
  Row,
  Typography,
  Input,
  Radio,
} from 'antd';
import moment from 'moment';
import MessageAlert from 'Utils/MessageAlert';

const { Title } = Typography;
const { TextArea } = Input;
// const styles = {
//   label: {
//     width: 65,
//   },
//   input: {
//     width: 'calc(100% - 68px)',
//   },
// };

/**
 * [Component]
 * --
 */
const ScheduleInput = ({
  userInfo,
  closeModal,
  currentDate,
  onCreateSchedule,
}) => {
  /* ===== State ===== */
  const [schedule, setSchedule] = useState({
    schedule_type: 'INTERNAL',
    schedule_title: '',
    schedule_start_date: currentDate,
    schedule_start_time: '00:00',
    schedule_end_date: '',
    schedule_end_time: '00:00',
    schedule_locate: '-',
  });

  /* ===== Functions ===== */
  const handleCreate = async () => {
    try {
      if (
        !schedule.schedule_type ||
        !schedule.schedule_title ||
        !schedule.schedule_start_date ||
        !schedule.schedule_start_time ||
        !schedule.schedule_start_date === '00:00' ||
        !schedule.schedule_start_time === '00:00' ||
        // !schedule.schedule_end_date ||
        !schedule.schedule_end_time ||
        !schedule.schedule_locate
      ) {
        return MessageAlert.warning('모든 항목을 입력해주세요');
      }

      onCreateSchedule({
        ...schedule,
        schedule_end_date: schedule.schedule_start_date,
        user_id: userInfo.user_id,
      });
    } catch (err) {
      MessageAlert.error(
        '일정을 등록할 수 없습니다. 페이지를 새로고침 해주세요'
      );
    }
  };
  /**
   *
   */
  const handleChange = (key, value) => {
    console.log(key, value);
    setSchedule((prev) => ({
      ...prev,
      [`schedule_${key}`]: value,
    }));
  };

  /* ===== Hooks ===== */
  // useEffect(() => {
  //   const call = () => {};
  // }, []);

  console.log('schedule: ', schedule);
  /* ===== Render ===== */
  return (
    <div>
      <Content>
        <Row>
          {/* === 구분 === */}
          <Col span={4}>
            <Title level={5} style={{ padding: '3.5px 0' }}>
              구분
            </Title>
          </Col>
          <Col span={20}>
            <Radio.Group
              onChange={(e) => handleChange('type', e.target.value)}
              defaultValue='INTERNAL'
            >
              <Radio.Button value='INTERNAL'>개인일정</Radio.Button>
              <Radio.Button value='EXTERNAL'>공식일정</Radio.Button>
            </Radio.Group>
          </Col>
          {/* === 날짜 === */}
          <Col span={4}>
            <Title level={5} style={{ padding: '3.5px 0' }}>
              날짜
            </Title>
          </Col>
          <Col span={20}>
            <DatePicker
              style={{ width: '100%' }}
              placeholder='YYYY-MM-DD'
              defaultValue={moment(schedule.schedule_start_date, 'YYYY-MM-DD')}
              onChange={(v) => handleChange('start_date', v)}
            />
          </Col>
          {/* === 시작시간 === */}
          <Col span={4}>
            <Title level={5} style={{ padding: '3.5px 0' }}>
              시작시간
            </Title>
          </Col>
          <Col span={8}>
            <TimePicker
              style={{ width: '100%' }}
              onChange={(_, ts) => handleChange('start_time', ts)}
              // value={schedule.schedule_start_time}
              format='HH:mm'
              placeholder='HH:mm'
              value={moment(schedule.schedule_start_time, 'HH:mm')}
              defaultOpenValue={moment('00:00', 'HH:mm')}
            />
          </Col>
          {/* === 종료시간 === */}
          <Col span={1} />
          <Col span={3}>
            <Title level={5} style={{ padding: '3.5px 0' }}>
              종료시간
            </Title>
          </Col>
          <Col span={8}>
            <TimePicker
              style={{ width: '100%' }}
              onChange={(_, ts) => handleChange('end_time', ts)}
              // value={schedule.schedule_start_time}
              format='HH:mm'
              placeholder='HH:mm'
              value={moment(schedule.schedule_end_time, 'HH:mm')}
              defaultOpenValue={moment('00:00', 'HH:mm')}
            />
          </Col>
          {/* === 일정명 === */}
          <Col span={4}>
            <Title level={5} style={{ padding: '3.5px 0' }}>
              일정명
            </Title>
          </Col>
          <Col span={20}>
            <Input
              style={{ width: '100%' }}
              placeholder='일정명을 입력해주세요'
              onChange={(v) => handleChange('title', v.target.value)}
              value={schedule.schedule_title}
            />
          </Col>
          {/* === 내용 === */}
          <Col span={4}>
            <Title level={5} style={{ padding: '3.5px 0' }}>
              내용
            </Title>
          </Col>
          <Col span={20}>
            <TextArea
              rows={5}
              placeholder='내용을 입력해주세요'
              maxLength={255}
              value={schedule.schedule_content}
              onChange={(v) => handleChange('content', v.target.value)}
            />
          </Col>
        </Row>
      </Content>

      <div className='schedule-regist-button'>
        <button className='cancel-btn' onClick={() => closeModal(false)}>
          닫기
        </button>
        <button className='regist-btn' onClick={handleCreate}>
          등록
        </button>
      </div>
    </div>
  );
};

ScheduleInput.defaultProps = {
  currentDate: new Date(),
};
export default ScheduleInput;
