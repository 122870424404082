import React from 'react';
// fontawesomeicon
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { NoticeRegist } from './components';
import { useState } from 'react';
import { ModalLayout } from 'Components';
import NoticeModify from './components/NoticeModify';
import { Input, Table, Button } from 'antd';
import moment from 'moment';
import MessageAlert from 'Utils/MessageAlert';

const { Search } = Input;

/**
 * NoticePresenter
 * --
 */
const NoticePresenter = ({
  communityList,
  registNotice,
  updateNotice,
  deleteNotice,
}) => {
  /* ===== Router ===== */
  /* ===== State ===== */
  const [registModal, setRegistModal] = useState(false);
  const [modifyModal, setModifyModal] = useState(false);
  const [notice, setNotice] = useState();
  const [keyword, setKeyword] = useState('');
  const [keyword2, setKeyword2] = useState('');

  /* ===== Functions ===== */
  const handleReisgtModal = () => {
    setRegistModal(!registModal);
  };
  const handleModifyModal = () => {
    setModifyModal(!modifyModal);
  };

  const handleNoticeDetail = (noticeItem) => {
    setNotice(noticeItem);
    handleModifyModal();
  };

  const handleModify = async (noticeInfo) => {
    const result = await updateNotice(noticeInfo);
    if (result) {
      handleModifyModal();
      return true;
    }
    return false;
  };

  const handleDeleteNotice = async (notice_id) => {
    if (
      !window.confirm(
        '삭제된 내용은 복구할 수 없습니다.\n정말로 삭제하시겠습니까?'
      )
    ) {
      return;
    }
    const result = await deleteNotice(notice_id);
    if (result) {
      // setModifyModal(false);
      // setRegistModal(false);
      MessageAlert.success('삭제되었습니다.');
      return true;
    }
    MessageAlert.error('다시 시도해주세요');
    return false;
  };

  /* ===== Functions ===== */
  /**
   * 키워드 검색
   */
  const onSearch = () => {
    setKeyword2(keyword);
  };

  /**
   *
   */
  const onCell = (record, _index) => {
    return {
      onClick: (_) => handleNoticeDetail(record),
    };
  };

  /* ===== Variables ===== */
  const filteredData = communityList
    ? keyword2
      ? communityList.filter((c) => c.notice_title.indexOf(keyword2) !== -1)
      : communityList
    : [];

  /* ===== Render ===== */
  return (
    <div className='community-container'>
      <div className='community-contents'>
        {/* 검색 */}
        <div className='community-contents-top'>
          <div>
            <Search
              size='large'
              placeholder='제목을 입력해주세요'
              onSearch={onSearch}
              onChange={(e) => setKeyword(e.target.value)}
              style={{ width: 330 }}
              value={keyword}
            />
            <Button
              size='large'
              disabled={keyword2 === '' ? true : false}
              onClick={() => {
                setKeyword('');
                setKeyword2('');
              }}
            >
              초기화
            </Button>
          </div>

          <div>
            <Button type='primary' size='large' onClick={handleReisgtModal}>
              공지사항 등록
            </Button>
          </div>
        </div>

        {/* 데이터 */}
        <div className='community-contents-bottom'>
          <Table
            style={{ cursor: 'pointer' }}
            columns={[
              {
                title: '작성자',
                // dataIndex: 'user_nm',
                // key: 'user_nm',
                render: (_) => '관리자',
                onCell,
              },
              {
                title: '제목',
                render: ({ notice_title }) => {
                  const title =
                    notice_title.length >= 15
                      ? `${notice_title.slice(0, 15)}...`
                      : notice_title;
                  return title;
                },
                onCell,
              },
              // {
              //   title: '댓글',
              //   render: ({ reply_cnt }) => `${reply_cnt}개`,
              //   onCell,
              // },
              {
                title: '등록일',
                render: ({ created_at }) =>
                  moment(created_at).format('YYYY-MM-DD'),
                onCell,
              },
              {
                title: '삭제',
                width: '10%',
                render: ({ notice_id }) => (
                  <Button
                    size='small'
                    type='danger'
                    onClick={() => handleDeleteNotice(notice_id)}
                  >
                    삭제
                  </Button>
                ),

                onCell,
              },
            ]}
            dataSource={filteredData}
          ></Table>
        </div>
      </div>
      <ModalLayout custom modal={registModal} setModal={setRegistModal}>
        <NoticeRegist regist={registNotice} close={handleReisgtModal} />
      </ModalLayout>
      <ModalLayout custom modal={modifyModal} setModal={setModifyModal}>
        <NoticeModify
          notice={notice}
          update={handleModify}
          close={handleModifyModal}
          delete={handleDeleteNotice}
        />
      </ModalLayout>
    </div>
  );
};

NoticePresenter.defaultProps = {
  communityList: [],
};

export default NoticePresenter;
