/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import Calendar from 'tui-calendar';
import './test.css';
import 'tui-calendar/dist/tui-calendar.css';
import moment from 'moment';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalLayout, Modal2 } from 'Components';
import { PlaceCalendarDetail } from 'Route/pages/Place/components';
import ScheduleRegist from './ScheduleRegist';
import { useDispatch } from 'react-redux';
import commonSlice from 'Store/reducers/CommonSlice';
import { TypeManager } from 'Utils';
import MessageAlert from 'Utils/MessageAlert';

/**
 * [Component] UserCalendar 유저달력
 * --
 */
const UserCalendar = (props) => {
  /* ===== Props ===== */
  const {
    attendanceList,
    vacationList,
    scheduleList,
    userInfo,
    placeList,
    getVacation,
    getAttendance,
    closePage,
  } = props;

  /* ===== Initial ===== */
  const calendarRef = useRef();
  const dispatch = useDispatch();

  /* ===== Router ===== */

  /* ===== State ===== */
  const [calendar, setCalendar] = useState(null);
  const [calendarDate, setCalendarDate] = useState();
  const [toggle, setToggle] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const initialState = {
    calendar_date: '',
  };
  const [scheduleInfo, setScheduleInfo] = useState(initialState);
  const [detailModal, setDetailModal] = useState(false);
  const detailState = {
    type: '',
    data: {},
    userInfo: userInfo,
  };
  const [scheduleDetail, setScheduleDetail] = useState(detailState);
  // 등록모달
  const [modalFlag, setModalFlag] = useState(null);
  // 수정모드
  const [editMode, setEditMode] = useState({
    id: null,
    mode: null,
  });

  /* ===== Functions ===== */
  /**
   * 상세보기 모달 토글
   * --
   */
  const handleDetailModal = () => {
    setDetailModal(!detailModal);
  };

  /**
   *
   * --
   */
  const openDetailModal = (obj) => {
    const { schedule } = obj;
    const { raw, start } = schedule;
    const { type } = raw;
    dispatch(commonSlice.actions.setDate(JSON.stringify(start._date)));
    setScheduleDetail({
      ...scheduleDetail,
      type: type,
      data: schedule,
      userInfo: userInfo,
    });
    handleDetailModal();
  };

  /**
   *
   * --
   */
  const handleCreateModal = () => {
    setCreateModal(!createModal);
  };

  /**
   *
   * --
   */
  const openCreateModal = () => {
    handleCreateModal();
  };

  /**
   *
   * --
   */
  const closeCreateModal = () => {
    setScheduleInfo(initialState);
    handleCreateModal();
    setEditMode({
      id: null,
      mode: null,
    });
  };

  /**
   *
   * --
   */
  const closeDetailModal = (
    edit = {
      id: null,
      mode: null,
    }
  ) => {
    setScheduleDetail(detailState);
    handleDetailModal();
    if (edit && (edit.mode === 'SCHEDULE' || edit.mode === 'VACATION')) {
      MessageAlert.info(`수정모드입니다. ${edit.id}`);
      setEditMode(edit);
      setCreateModal(true);
    }
  };

  /**
   *
   * --
   */
  const handleUpdateVacation = () => {};

  /**
   * 캘린더 상세 모달
   * --
   */
  const initCalendar = () => {
    if (calendar) {
      return;
    }

    const d = new Date();
    setCalendarDate(d);

    var cal = new Calendar(calendarRef.current, {
      defaultView: 'month',
      taskView: true,
      month: {
        daynames: ['일', '월', '화', '수', '목', '금', '토'],
        // isAlways6Week: false,
        startDayOfWeek: 0,
        narrowWeekend: true,
      },
      template: {
        allday: function (schedule) {
          return `<span style='line-height: 2;height: 100%;'>${schedule.title} ${schedule.body}</span>`;
        },
        alldayTitle: function () {
          return 'All Day';
        },
      },
    });

    cal.on({
      clickSchedule: function (e) {
        openDetailModal(e);
      },
      beforeCreateSchedule: function (e) {
        var triggerEventName = e.triggerEventName;

        if (triggerEventName === 'click' || triggerEventName === 'mouseup') {
          const d = JSON.stringify(moment(e.start._date));
          dispatch(commonSlice.actions.setDate(d));
          openCreateModal(e.start);
        }

        e.guide.clearGuideElement();
      },
    });
    setCalendar(cal);
  };

  /**
   *
   * --
   */
  const handlePrev = () => {
    if (!calendar) {
      return;
    }
    calendar.prev();
    setCalendarDate(moment(calendarDate).subtract(1, 'M'));
  };

  /**
   *
   * --
   */
  const handleNext = () => {
    if (!calendar) {
      return;
    }
    calendar.next();
    setCalendarDate(moment(calendarDate).add(1, 'M'));
  };

  /**
   *
   * --
   */
  const handleCreateSchedule = (schedules) => {
    calendar.createSchedules(schedules);
  };

  /**
   * 캘린더 - 근태기록 목록 생성
   * --
   */
  const initialAttedance = () => {
    const schedules = attendanceList.map((item) => {
      const {
        attendance_id,
        attendance_end,
        attendance_status,
        attendance_start,
        created_at,
        // attendance_start,
      } = item;

      const body = `${moment(attendance_start).format('HH:mm')}${
        attendance_end
          ? `~${moment(attendance_end).format('HH:mm')}`
          : '~[--:--]'
      }`;
      const { title, bgColor, borderColor } =
        TypeManager.getAttendance(attendance_status);
      return {
        id: attendance_id,
        type: 'attendance',
        calendarId: attendance_id,
        title: title,
        category: 'allday',
        body,
        // bgColor: '#FEDAE3',
        bgColor,
        borderColor,
        // start: created_at,
        // end: created_at,
        start: attendance_start,
        end: attendance_end,
        raw: { ...item, type: 'ATTENDANCE' },
      };
    });

    handleCreateSchedule(schedules);
  };

  /**
   * 캘린더 - 휴가 목록 생성
   * --
   */
  const initialVacation = () => {
    const { vacations, user_vacation, vacation_cnt } = vacationList;
    const schedules = vacations.map((item) => {
      const {
        vacation_id,
        vacation_start_date,
        vacation_start_time,
        vacation_type,
        vacation_status,
      } = item;
      const dd = `${moment(vacation_start_date).format(
        'YYYY-MM-DD'
      )}T${vacation_start_time}:00+09:00`;

      const {
        bgColor,
        borderColor,
        title: body,
      } = TypeManager.getVacationStatus(vacation_status);

      const { title } = TypeManager.getVacation(vacation_type);

      return {
        id: vacation_id,
        type: 'vacation',
        calendarId: vacation_id,
        title: title,
        body: body,
        category: 'allday',
        bgColor: bgColor,
        borderColor: borderColor,
        start: dd,
        end: dd,
        raw: { ...item, type: 'VACATION', user_vacation, vacation_cnt },
      };
    });
    handleCreateSchedule(schedules);
  };

  /**
   * 캘린더 - 일정 목록 생성
   * --
   */
  const initialSchedules = () => {
    const schedules = scheduleList
      .sort((a, b) =>
        a.schedule_start_time < b.schedule_start_time
          ? -1
          : a.schedule_start_time == b.schedule_start_time
          ? 0
          : 1
      )
      .map((item) => {
        const {
          schedule_id,
          schedule_type,
          schedule_start_date,
          schedule_start_time,
          schedule_end_time,
          schedule_title,
          schedule_content,
        } = item;

        const body = `일정 [${schedule_start_time}~${
          schedule_end_time ? schedule_end_time : `(--:--)`
        }]`;

        return {
          id: schedule_id,
          type: 'schedule',
          calendarId: schedule_id,
          title: body,
          category: 'allday',
          body: schedule_title,
          // bgColor: '#FEDAE3',
          bgColor: '#f1e6ff',
          borderColor: '#5a00cf',
          start: schedule_start_date,
          end: schedule_start_date,
          raw: { ...item, type: 'SCHEDULE' },
        };
      });
    handleCreateSchedule(schedules);
  };

  /* ===== Hooks ===== */
  /**
   *
   * --
   */
  useEffect(() => {
    initCalendar();
  });

  /**
   *
   * --
   */
  useEffect(() => {
    if (!calendar || !attendanceList || !vacationList) {
      return;
    }
    if (toggle) {
      return;
    }
    initialAttedance();
    initialVacation();

    setToggle(true);
  }, [calendar, attendanceList, vacationList]);

  /**
   *
   * --
   */
  useEffect(() => {
    scheduleList && scheduleList.length > 0 && initialSchedules();
  }, [scheduleList]);

  /* ===== Render ===== */
  return (
    <div className="calendar-container">
      <div className="calendar-top">
        <div className="calendar-top-title">
          <FontAwesomeIcon
            icon={faAngleLeft}
            className="user-calendar-faAngleLeft"
            onClick={handlePrev}
          />
          <h3 onClick={handleCreateSchedule}>
            {moment(calendarDate).format('YYYY.MM')}
          </h3>
          <FontAwesomeIcon
            icon={faAngleRight}
            className="user-calendar-faAngleRight"
            onClick={handleNext}
          />
        </div>
      </div>
      <div
        className="calendar-content"
        ref={calendarRef}
        style={{ height: '800px' }}
      ></div>

      {/* === 모달(상세보기) === */}
      <Modal2
        visible={detailModal}
        type="drawer"
        // closable
        width={550}
        style={{ padding: 0 }}
        onCancel={handleDetailModal}
      >
        <PlaceCalendarDetail
          scheduleDetail={scheduleDetail}
          placeList={placeList}
          closeModal={closeDetailModal}
          userInfo={userInfo}
          closePage={closePage}
          editMode={editMode}
        />
      </Modal2>

      {/* === 타입선택 모달 === */}
      <Modal2
        visible={createModal}
        type="drawer"
        // closable
        width={550}
        style={{ padding: 0 }}
        onCancel={handleCreateModal}
      >
        <ScheduleRegist
          userInfo={userInfo}
          scheduleInfo={scheduleInfo}
          calendarDate={calendarDate}
          closeModal={closeCreateModal}
          getAttendance={getAttendance}
          getVacation={getVacation}
          closePage={closePage}
        />
      </Modal2>

      {/* === 통합모달 === */}
      <Modal2
        visible={modalFlag !== null}
        type="drawer"
        // closable
        width={550}
        style={{ padding: 0 }}
        onCancel={() => setModalFlag(null)}
      >
        {modalFlag === 'ATTENDANCE' && 'ATTENDANCE'}
        {modalFlag === 'VACATION' && 'VACATION'}
        {modalFlag === 'SCHEDULE' && 'SCHEDULE'}
      </Modal2>
      {/* === 통합모달 === */}
    </div>
  );
};

/* DF */
UserCalendar.defaultProps = {
  attendanceList: undefined,
  vacationList: undefined,
  scheduleList: [],
};

export default UserCalendar;
