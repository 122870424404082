import ApiConstant from 'Api/ApiConstant';
import { ApiManager, parameterToPath } from 'Utils';
const $http = new ApiManager();

const DashboardApi = {
  /**
   * 대시보드 정보 조회
   * --
   * @returns
   */
  getDashboard: async () => {
    try {
      const url = ApiConstant.GET_DASHBOADR;
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },

  /**
   * 대시보드 정보 조회
   * --
   * @returns
   */
  getDashboardVacations: async (start_date = null, end_date = null) => {
    try {
      const url = ApiConstant.GET_DASHBOARD_VACATIONS;
      const { status, data, message } = await $http.get(url, {
        ...(start_date ? { start_date } : {}),
        ...(end_date ? { end_date } : {}),
      });
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },

  /**
   * 대시보드 정보 조회
   * --
   * @returns
   */
  getDashboardCalendar: async (start_date = null, end_date = null) => {
    try {
      const url = ApiConstant.GET_DASHBOARD_CALENDAR;
      const { status, data, message } = await $http.get(url, {
        ...(start_date ? { start_date } : {}),
        ...(end_date ? { end_date } : {}),
      });
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },

  /**
   * 대시보드 정보 조회
   * --
   * @returns
   */
  getDashboardVacationsDetail: async (current_date) => {
    try {
      const url = parameterToPath(ApiConstant.GET_DASHBOARD_VACATIONS_DETAIL, {
        current_date,
      });
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },

  /**
   * 대시보드 정보 조회
   * --
   * @returns
   */
  getDashboardCalendarDetail: async (current_date) => {
    try {
      const url = parameterToPath(ApiConstant.GET_DASHBOARD_CALENDAR_DETAIL, {
        current_date,
      });
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },

  /**
   * 근무지별 대시보드 정보 조회
   * --
   * @param {*} place_id
   * @returns
   */
  getPlaceDashboard: async (place_id) => {
    try {
      const url = ApiConstant.GET_PLACE_DASHBOARD.replace(
        ':place_id',
        place_id
      );
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
};

export default DashboardApi;
