import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  usersList: [],
  users: {},
  test: 0,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    incerement(state) {
      state.test++;
    },
    decrement(state) {
      state.test--;
    },
    incerementByAmouny(state, action) {
      state.test += action.payload;
    },
  },
});

export default usersSlice;
