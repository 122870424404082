import React from 'react';
import './PlaceCalendar.css';

// fontawesomeicon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const PlaceCalendar = () => {
  return (
    <div className="place-calendar-container">
      <div className="place-calendar-contents">
        <div className="place-calendar-contents-top">
          <div className="place-calendar-contents-top-title">
            <FontAwesomeIcon
              icon={faAngleLeft}
              className="place-calendar-faAngleLeft"
            />
            <h3>2022.01</h3>
            <FontAwesomeIcon
              icon={faAngleRight}
              className="place-calendar-faAngleRight"
            />
          </div>{' '}
          <div className="place-calendar-contents-top-text">
            <div>
              <p className="place-calendar-contents-top-text-name">
                • 김성훈 직급없음
              </p>
            </div>
            <div>
              <p>소정근로일 수</p>
              <p>
                10 <span>일</span>
              </p>
            </div>
            <div>
              <p>소정근로시간</p>
              <p>
                80 <span>시간</span>
              </p>
            </div>
            <div>
              <p>근무시간</p>
              <p>
                5 <span>시간</span>
              </p>
            </div>
            <div>
              <p>잔여 근무시간</p>
              <p>
                75 <span>시간</span>
              </p>
            </div>
          </div>
          {/* place-calendar-contents-top-text end */}
          {/* place-calendar-contents-top-title end */}
        </div>{' '}
        {/* place-calendar-contents-top end */}
        <div className="place-calendar-contents-calendar">
          <table class="calendar">
            <thead>
              <tr className="calendar-day">
                <td>일</td>
                <td>월</td>
                <td>화</td>
                <td>수</td>
                <td>목</td>
                <td>금</td>
                <td>토</td>
              </tr>
            </thead>
            <tbody>
              <tr className="calendar-1st">
                <td>26</td>
                <td>27</td>
                <td>28</td>
                <td>29</td>
                <td>30</td>
                <td>31</td>
                <td>1</td>
              </tr>
              <tr className="calendar-2nd">
                <td>2</td>
                <td>
                  3
                  <div>
                    <h3>04h 37m</h3>
                    <h4>14:23 ~ 19:00</h4>
                  </div>
                </td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
                <td>8</td>
              </tr>
              <tr className="calendar-3rd">
                <td>9</td>
                <td>10</td>
                <td>
                  11
                  <div>
                    <h3>04h 37m</h3>
                    <h4>14:23 ~ 19:00</h4>
                  </div>
                </td>
                <td>12</td>
                <td>13</td>
                <td>
                  14
                  <div>
                    <div>
                      <p>04h 37m</p>
                    </div>
                    <div>
                      <p>04h 37m</p>
                    </div>
                    <div>
                      <p>04h 37m</p>
                    </div>
                  </div>
                </td>

                <td>15</td>
              </tr>
              <tr className="calendar-4th">
                <td>16</td>
                <td>
                  17
                  <div>
                    <h3>04h 37m</h3>
                    <h4>14:23 ~ 19:00</h4>
                  </div>
                </td>
                <td>
                  18
                  <div>
                    <h3>04h 37m</h3>
                    <h4>14:23 ~ 19:00</h4>
                  </div>
                </td>
                <td>19</td>
                <td>20</td>
                <td>21</td>
                <td>22</td>
              </tr>
              <tr className="calendar-5th">
                <td>23</td>
                <td>24</td>
                <td>25</td>
                <td>26</td>
                <td>
                  27
                  <div>
                    <div>
                      <p>04h 37m</p>
                    </div>
                    <div>
                      <h3>04h 37m</h3>
                      <h4>14:23 ~ 19:00</h4>
                    </div>
                    <div>
                      <p>04h 37m</p>
                    </div>
                  </div>
                </td>
                <td>28</td>
                <td>29</td>
              </tr>
              <tr className="calendar-6th">
                <td>30</td>
                <td>31</td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
              </tr>
            </tbody>
          </table>
        </div>{' '}
        {/* place-calendar-contents-calendar end */}
      </div>
      {/* place-calendar-contents end */}
    </div>
  ); /* place-calendar-container end */
};

export default PlaceCalendar;
