/**
 *
 *
 */

// import {
//   LaptopOutlined,
//   NotificationOutlined,
//   UserOutlined,
// } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './SideMenu.style.css';

const { Sider } = Layout;

// const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
//   (icon, index) => {
//     const key = String(index + 1);
//     return {
//       key: `sub${key}`,
//       icon: React.createElement(icon),
//       label: `subnav ${key}`,
//       children: new Array(4).fill(null).map((_, j) => {
//         const subKey = index * 4 + j + 1;
//         return {
//           key: subKey,
//           label: `option${subKey}`,
//         };
//       }),
//     };
//   }
// );

/**
 *
 * --
 */
const App = ({ menus }) => {
  /* ===== ROUTER ===== */
  const location = useLocation();
  const { pathname } = location;

  /* ===== STATE ===== */
  const [selectedKeys, setSelectedKeys] = useState(['dashboard']);

  /* ===== RENDER ===== */
  useEffect(() => {
    const call = (path) => {
      let value = 'dashboard';
      if (path.indexOf('/dashboard') !== -1) {
        value = 'dashboard';
      } else if (path.indexOf('/place') !== -1) {
        value = 'place';
      } else if (path.indexOf('/users') !== -1) {
        value = 'users';
      } else if (path.indexOf('/notice') !== -1) {
        value = 'notice';
      } else if (path.indexOf('/community') !== -1) {
        value = 'community';
      }
      setSelectedKeys([value]);
    };
    pathname !== selectedKeys[0] && call(pathname);
  }, [pathname]);

  /* ===== RENDER ===== */
  return (
    <Layout>
      <Sider width={'100%'} className='site-layout-background'>
        <Menu
          selectedKeys={selectedKeys}
          mode='inline'
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          style={{
            height: '100%',
            borderRight: 0,
          }}
          items={menus}
        />
      </Sider>
    </Layout>
  );
};

App.defaultProps = {
  menus: [],
};
export default App;
