import React, { useCallback, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  Home,
  Dashboard,
  Community,
  Place,
  Users,
  Config,
  Notice,
} from './pages';
import { HomeLayout, Loading, MainLayout, NotFound } from '../Components';
import {
  PlaceDetail,
  PlaceRegist,
  PlaceCalendar,
  PlaceCalendarRegist,
  PlaceCalendarDetail,
} from './pages/Place/components';
import { CommunityRegist } from './pages/Community/components';
import { UsersDetail } from './pages/Users/components';
import { getCookie } from 'Utils';
import { useDispatch, useSelector } from 'react-redux';
import commonSlice from 'Store/reducers/CommonSlice';
import { AuthApi } from 'Api';

const AppRouter = () => {
  const dispatch = useDispatch();
  /* Router */
  const { pathname } = useLocation();
  const navigate = useNavigate();
  /* State */
  const { loading } = useSelector((state) => state.common);

  const s = getCookie('busad_token');

  /* Functions */
  const verifyToken = async () => {
    const result = await AuthApi.verify();
    if (result) {
      return result;
    }
    return false;
  };

  const checkLogin = useCallback(async () => {
    if (s) {
      await verifyToken();
      return;
    }

    if (pathname === '/config') {
      return;
    }

    navigate('/');
  }, [s, pathname, navigate]);

  const handleLoading = useCallback(() => {
    dispatch(commonSlice.actions.setLoading(true));
    setTimeout(() => {
      dispatch(commonSlice.actions.setLoading(false));
    }, 1000);
  }, [dispatch]);

  /* Hooks */
  useEffect(() => {
    checkLogin();
  }, [checkLogin]);

  useEffect(() => {
    handleLoading();
  }, [navigate, handleLoading]);

  /* Render */
  return (
    <>
      <Loading loading={loading} />
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/config">
          <Route index element={<Config />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/dashboard">
            <Route index element={<Dashboard />} />
          </Route>
          {/* <Route path="/admin">
            <Route index element={<Admin />} />
          </Route> */}
          <Route path="/place">
            <Route index element={<Place />} />
            <Route path="regist" element={<PlaceRegist />} />
            <Route path="detail/:place_id" element={<PlaceDetail />} />
            <Route path="calendar" element={<PlaceCalendar />} />
            <Route path="calendarregist" element={<PlaceCalendarRegist />} />
            <Route path="calendardetail" element={<PlaceCalendarDetail />} />
          </Route>
          <Route path="/users">
            <Route index element={<Users />} />
            <Route path="detail" element={<UsersDetail />} />
            {/* <Route path="regist" element={<UsersRegist />} /> */}
          </Route>
          <Route path="/community">
            <Route index element={<Community />} />
            <Route path="regist" element={<CommunityRegist />} />
          </Route>
          <Route path="/notice">
            <Route index element={<Notice />} />
            <Route path="regist" element={<CommunityRegist />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppRouter;
